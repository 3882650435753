import { Gantry, GantryAttr, TrackAggregationGroup, WarningState } from '@36node-fcp/core-sdk';
import { LeftOutlined, LinkOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Image,
  message,
  Popconfirm,
  Row,
  Space,
  Spin,
  Tabs,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import L, { latLngBounds } from 'leaflet';
import { isEmpty, orderBy, sumBy } from 'lodash';
import React, { Fragment, useEffect } from 'react';
import { MapContainer, Marker, Popup, TileLayer, ZoomControl } from 'react-leaflet';
import { Link, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { BigPlayButton, Player } from 'video-react';

import { ReactComponent as Car } from 'src/assets/car.svg';
import Line from 'src/components/line';
import {
  MAP_BOUND_NORTH_EAST,
  MAP_BOUND_SOUTH_WEST,
  MAP_CENTER,
  MAP_CURRENT_ZOOM,
  MAP_ENDPOINT,
  MAP_MAX_ZOOM,
  MAP_MIN_ZOOM,
} from 'src/config';
import { toGantryAttrText } from 'src/features/gantry';
import { toIllegalLevelText } from 'src/features/illegal-type';
import { WarningPopup } from 'src/features/map';
import { useSession } from 'src/features/session';
import { NamespaceProvider } from 'src/features/users';
import { useWarning } from 'src/features/warning';
import { ymdhms } from 'src/lib/lang/time';
import { checkPermission } from 'src/lib/privilege';
import { useApi } from 'src/lib/react-api';
import { fcp } from 'src/services';

const { Title, Text } = Typography;

const startIcon = '/icon/start.png';
const MapBounds = latLngBounds(MAP_BOUND_SOUTH_WEST, MAP_BOUND_NORTH_EAST);
const url = MAP_ENDPOINT + '/{z}/{x}/{y}.png';
const GANTRY_ICON_SIZE = 32;

const Split = styled.div`
  height: 30px;
  margin-top: -15px;
  margin-left: -25px;
  width: calc(100% + 50px);
  background: #f5f5f5;
`;

const Padding = styled.div`
  margin-top: 20px;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
`;

const TrackRecords = styled.div`
  display: flex;
  flex-direction: column;
`;

/**
 * 计算高频出口数据
 * @param plate
 * @returns
 */
function useHighFrequencyTracks(plate?: string): {
  count: number;
  gantry: Gantry;
  ratio: number;
}[] {
  const [{ result: aggs = [] }, aggregateTrackRecords] = useApi(fcp.aggregateTrackRecords);
  const [{ result: gantries = [] }, listGantries] = useApi(fcp.listGantries);

  useEffect(() => {
    if (plate) {
      aggregateTrackRecords({
        _group: [TrackAggregationGroup.GANTRY],
        plate,
        _limit: 1000,
        createAt_gt: dayjs().subtract(30, 'day').startOf('day').toISOString(),
      });

      listGantries({
        _limit: 1000,
        attr: [GantryAttr.EXPORTRAMP],
      });
    }
  }, [plate]);

  const gantriesMap = gantries.reduce((acc, cur) => {
    acc[cur.id] = cur;
    return acc;
  }, {});

  const aggWithGantry = aggs
    .map((agg) => {
      const gantry = gantriesMap[agg.gantry];
      return { ...agg, gantry };
    })
    .filter((agg) => Boolean(agg.gantry));

  const total = sumBy(aggWithGantry, 'count');

  // 排序并取前三个
  return orderBy(aggWithGantry, 'count', 'desc')
    .slice(0, 3)
    .map((agg) => {
      return {
        ...agg,
        ratio: agg.count / total,
      };
    });
}

/**
 * 车辆预警详情页面
 */
const BlackListVehicleDetaiPage: React.FC = () => {
  const session = useSession();
  const { warningId } = useParams();
  const navigate = useNavigate();

  const [{ result: warning }, getWarning] = useWarning({ warningId });
  const [{ result: tracks = [] }, listTrackRecords] = useApi(fcp.listTrackRecords);
  const [closeState, closeOption] = useApi(fcp.closeWarning, {
    onSuccess: () => {
      message.success('更新成功');
      getWarning({ warningId });
    },
    onFailure: (err) => {
      message.error(`更新失败: ${err.message}`);
    },
  });

  const highFrequencyTracks = useHighFrequencyTracks(warning?.plate);

  useEffect(() => {
    if (warning?.plate) {
      listTrackRecords({
        plate: warning.plate,
        plateType: warning.plateType,
        _sort: '-capAt',
        capAt_gte: dayjs(warning.capAt).subtract(4, 'hour').toISOString(),
      });
    }
  }, [warning]);

  if (!warning) {
    return <Spin />;
  }

  const renderStartMarker = () => {
    // 按时间倒序，最后一条过车记录为起点
    const start = tracks[tracks.length - 1];

    if (start) {
      const iconSize = GANTRY_ICON_SIZE * 2;
      const { lng, lat } = start?.gantry;
      const icon = L.icon({
        iconUrl: startIcon,
        iconAnchor: [iconSize / 2, iconSize / 2],
        iconSize: [iconSize, iconSize],
      });
      return (
        <Marker position={[lat, lng]} icon={icon}>
          <Popup closeButton={false} offset={[0, -10]}>
            <Text strong style={{ fontSize: 14 }}>
              {start?.gantry.name}
            </Text>
          </Popup>
        </Marker>
      );
    }

    return null;
  };

  return (
    <>
      <Container
        title={
          <Button
            type="link"
            style={{ padding: 0 }}
            onClick={() => {
              if (window.history.length > 1) {
                navigate(-1);
              } else {
                navigate('/vehicle/warning');
              }
            }}
          >
            <LeftOutlined />
            返回
          </Button>
        }
        bodyStyle={{
          paddingBottom: 0,
          paddingTop: 0,
        }}
      >
        <Header>
          <Title style={{ fontSize: 20 }}>
            <Car
              style={{
                width: 23,
                height: 23,
                marginRight: 8,
                fill: '#1890ff',
                transform: 'translateY(4px)',
              }}
            />
            车牌号: {warning.plate}
          </Title>
          {checkPermission(session, ['vehicle:carAlertAd']) && (
            <Popconfirm
              title="确认已处理该预警，对应的违法将会关闭。"
              onConfirm={() => closeOption({ warningId: warningId })}
            >
              <Button
                type="primary"
                loading={closeState.loading}
                disabled={warning.state !== WarningState.OPEN}
                style={{
                  marginLeft: 'auto',
                }}
              >
                标记为已处理
              </Button>
            </Popconfirm>
          )}
        </Header>
        <div style={{ paddingLeft: 32, display: 'flex' }}>
          <Line title="预警等级:" content={toIllegalLevelText(warning.level)} />
          <div style={{ paddingLeft: 64 }}>
            <Line title="违法名称:" content={warning.name} />
          </div>
        </div>
        <Tabs
          defaultActiveKey="1"
          items={[
            {
              label: '卡口过车',
              key: '1',
              children: (
                <>
                  <Split />
                  <Padding />
                  <Title style={{ fontSize: 16 }}>最新过车信息</Title>
                  <Divider />
                  <Title style={{ fontSize: 16 }}>卡口信息</Title>
                  <Line title="过车时间:" content={warning?.capAt} formatter={ymdhms} />
                  <Line title="预警卡口:" content={warning?.gantry?.name} />
                  <Line
                    title="所属部门:"
                    content={
                      <NamespaceProvider ns={warning.gantry.ns}>{(namespace) => namespace?.name}</NamespaceProvider>
                    }
                  />
                  <Line title="所属路段:" content={warning.gantry.section?.name || '--'} />
                  <Line title="卡口属性:" content={toGantryAttrText(warning?.gantry?.attr)} />
                  <Divider />
                  <Title style={{ fontSize: 16 }}>过车图片</Title>
                  {warning.images.length ? (
                    <Image.PreviewGroup>
                      <Row gutter={16}>
                        {warning.images?.map((url) => (
                          <Col span={8} key={url} style={{ marginTop: 16 }}>
                            <Image src={url} width="100%" key="image" alt="image" />
                          </Col>
                        ))}
                        {warning.plateImages?.map((url) => (
                          <Col span={8} key={url} style={{ marginTop: 16 }}>
                            <Image src={url} width="100%" key="image" alt="image" />
                          </Col>
                        ))}
                      </Row>
                    </Image.PreviewGroup>
                  ) : (
                    <Empty description="暂无图像" style={{ height: 200 }} />
                  )}
                  <Padding />
                </>
              ),
            },
            {
              label: '高速轨迹',
              key: '2',
              children: (
                <>
                  <Split />
                  <Padding />
                  <Title style={{ fontSize: 16 }}>高频出口匝道</Title>
                  {isEmpty(highFrequencyTracks) && <Empty description="暂无数据" />}
                  {!isEmpty(highFrequencyTracks) && (
                    <TrackRecords>
                      {highFrequencyTracks.map((t) => (
                        <Space key={t.gantry.id} style={{ marginTop: 12, fontSize: 16, paddingLeft: 32 }}>
                          <div>{t.gantry.name}</div>
                          <div>{(t.ratio * 100).toFixed(1)}% </div>
                        </Space>
                      ))}
                    </TrackRecords>
                  )}

                  <Split style={{ height: 12, marginTop: 16 }} />

                  <MapContainer
                    closePopupOnClick={false}
                    zoomControl={false}
                    center={MAP_CENTER}
                    zoom={MAP_CURRENT_ZOOM}
                    scrollWheelZoom={false}
                    style={{ height: '800px', width: '100%' }}
                    bounceAtZoomLimits={true}
                    maxBoundsViscosity={0.95}
                    maxBounds={MapBounds}
                  >
                    <TileLayer url={url} minZoom={MAP_MIN_ZOOM} maxZoom={MAP_MAX_ZOOM} />
                    {tracks.map((tracks) => (
                      <WarningPopup key={tracks?.gantry?.id} gantry={tracks?.gantry} active />
                    ))}
                    {renderStartMarker()}
                    <ZoomControl position="bottomleft" />
                  </MapContainer>
                  <Divider />
                  <Title style={{ fontSize: 16 }}>最近高速轨迹</Title>
                  {isEmpty(tracks) && <Empty description="暂无数据" />}
                  {!isEmpty(tracks) && (
                    <TrackRecords>
                      {tracks.map((t) => (
                        <Space key={t.id} style={{ marginTop: 12, fontSize: 16, paddingLeft: 32 }}>
                          <div>{ymdhms(t.capAt)}</div>
                          <div>{t.gantry.name}</div>
                        </Space>
                      ))}
                    </TrackRecords>
                  )}
                  <Padding />
                </>
              ),
            },
            {
              label: '预警来源',
              key: '3',
              children: (
                <>
                  {warning?.blacklist?.id && (
                    <>
                      <Split />
                      <Padding />
                      <Title style={{ fontSize: 16 }}>
                        黑名单 &nbsp;
                        <Link to={`/blacklist/${warning?.blacklist?.id}`}>
                          <small>
                            <LinkOutlined />
                          </small>
                        </Link>
                      </Title>
                      <Divider />
                      <Title style={{ fontSize: 16 }}>基本信息</Title>
                      <Line title="入库时间:" content={warning?.blacklist?.createAt} formatter={ymdhms} />
                      <Line title="描述:" content={warning?.blacklist?.content} />
                      <Divider />
                      <Title style={{ fontSize: 16 }}>图片信息</Title>
                      {warning?.blacklist?.images.length ? (
                        <Image.PreviewGroup>
                          <Row gutter={16}>
                            {warning.blacklist.images?.map((url) => (
                              <Col span={8} key={url} style={{ marginTop: 16 }}>
                                <Image src={url} width="100%" key="image" alt="image" />
                              </Col>
                            ))}
                          </Row>
                        </Image.PreviewGroup>
                      ) : (
                        <Empty description="暂无图像" style={{ height: 200 }} />
                      )}
                      <Padding />
                    </>
                  )}
                  {warning?.illegals?.length &&
                    (warning?.illegals || []).map((illegal, key: number) => (
                      <Fragment key={key}>
                        <Split />
                        <Padding />
                        <Title style={{ fontSize: 16 }}>
                          #{key + 1} 违法信息 &nbsp;
                          <Link to={`/vehicle/illegal/${illegal.id}`}>
                            <small>
                              <LinkOutlined />
                            </small>
                          </Link>
                        </Title>
                        <Divider />
                        <Title style={{ fontSize: 16 }}>基本信息</Title>
                        <Line title="违法时间:" content={illegal?.capAt} formatter={ymdhms} />
                        <Line title="违法名称:" content={illegal?.name} />
                        <Line title="违法地点:" content={illegal?.place} />
                        <Divider />
                        <Title style={{ fontSize: 16 }}>图片信息</Title>
                        {illegal?.images.length ? (
                          <Image.PreviewGroup>
                            <Row gutter={16}>
                              {illegal.images?.map((url) => (
                                <Col span={8} key={url} style={{ marginTop: 16 }}>
                                  <Image src={url} width="100%" key="image" alt="image" />
                                </Col>
                              ))}
                            </Row>
                          </Image.PreviewGroup>
                        ) : (
                          <Empty description="暂无图像" style={{ height: 200 }} />
                        )}
                        <Divider />
                        <Title style={{ fontSize: 16 }}>视频信息</Title>
                        {illegal?.videos.length ? (
                          <Image.PreviewGroup>
                            <Row gutter={16}>
                              {illegal?.videos?.map((url) => (
                                <Col span={8} key={url} style={{ marginTop: 16 }}>
                                  {/* <video src={url} width="100%" /> */}
                                  <Player src={url} startTime={2}>
                                    <BigPlayButton position="center" />
                                  </Player>
                                </Col>
                              ))}
                            </Row>
                          </Image.PreviewGroup>
                        ) : (
                          <Empty description="暂无视频" style={{ height: 200 }} />
                        )}
                        <Padding />
                      </Fragment>
                    ))}
                </>
              ),
            },
          ]}
        />
      </Container>
    </>
  );
};

const Container = styled(Card)`
  border: none !important;
  .ant-card-head {
    border: 0 !important;
    .ant-card-extra {
      padding: 8px 0;
    }
  }
  .ant-typography {
    color: rgba(0, 0, 0, 0.65);
  }
`;

export default BlackListVehicleDetaiPage;
