import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';

function isNumber(val) {
  return !isNaN(parseFloat(val)) && isFinite(val);
}

function isBoolean(val) {
  return val === 'false' || val === 'true';
}

function isDayjs(val) {
  return /^dayjs\(\d{13}\)$/.test(val);
}

function isDate(val) {
  return /^Date\(\d{13}\)$/.test(val);
}

function parseNumber(val) {
  return Number(val);
}

function parseBoolean(val) {
  return val === 'true';
}

function parseTimestamp(val: string) {
  const s = val.substring(6, 19);
  return dayjs(Number(s));
}

function parseDate(val: string) {
  const s = val.substring(5, 19);
  return new Date(Number(s));
}

function passSearchParams(params: URLSearchParams) {
  const result = {};
  params.forEach((value, key) => {
    let cur: any = value;

    if (isDayjs(value)) {
      cur = parseTimestamp(value);
    } else if (isDate(value)) {
      cur = parseDate(value);
    } else if (isNumber(value)) {
      cur = parseNumber(value);
    } else if (isBoolean(value)) {
      cur = parseBoolean(value);
    }

    if (Array.isArray(result[key])) {
      result[key].push(cur);
    } else if (result[key]) {
      result[key] = [result[key], cur];
    } else {
      result[key] = cur;
    }
  });
  return result;
}

const shit = function (from = {}) {
  const to = Array.isArray(from) ? [] : {};
  Object.entries(from).forEach(([k, v]) => {
    // 对 dayjs 类型做特殊处理
    if (v && v instanceof dayjs) {
      to[k] = `dayjs(${v.valueOf()})`;
      return;
    }

    // 对 Date 类型做特殊处理
    if (v && v instanceof Date) {
      to[k] = `Date(${v.getTime()})`;
      return;
    }

    to[k] = v;

    if (v && typeof v === 'object') to[k] = shit(v);

    if (
      (to[k] && typeof to[k] === 'object' && !Object.keys(to[k]).length) ||
      to[k] === null ||
      to[k] === undefined ||
      to[k]['length'] === 0
    ) {
      if (Array.isArray(from)) to[k] = from.slice(k as any, (k as any) + 1);
      else delete to[k];
    }
  });
  return to;
};

export function useSearch<T = Object>(defaultQuery?: T): [T, (values?: T) => void] {
  const [searchParams, setSearchParams] = useSearchParams(defaultQuery as any);
  const search = passSearchParams(searchParams) as T;
  const setSearch = (values?: T) => setSearchParams(shit(values));
  return [search, setSearch];
}
