import { HealthStatType, ListHealthStatsRequest } from '@36node-fcp/core-sdk';
import { Tag } from 'antd';

import { useQuery } from 'src/lib/react-api';
import { fcp } from 'src/services';

/**
 * 获取MQTT错误状态列表
 * @param req MQTT错误状态列表请求参数
 * @returns [state, listHealthStats]
 */
export const useHealthStatList = (req?: ListHealthStatsRequest) => {
  const [state, listHealthStats] = useQuery(fcp.listHealthStats, req || {});
  return [state, listHealthStats] as const;
};

type HealthStatUi = {
  text: string;
  color: string;
  value: HealthStatType;
};

export const HealthStatTypeUiList: HealthStatUi[] = [
  { text: '违法', color: '#D9001B', value: HealthStatType.ILLEGAL_RECORD },
  { text: '草稿', color: '#FF6732', value: HealthStatType.DRAFT_RECORD },
  { text: '过车', color: '#F5B223', value: HealthStatType.TRACK_RECORD },
];

export const HealthStatTextList = HealthStatTypeUiList.map((item) => item.text);
export const HealthStatFilter = HealthStatTypeUiList;

export const toHealthStatText = (type: HealthStatType) =>
  HealthStatTypeUiList.find((item) => item.value === type)?.text;

export const HealthStatBadge: React.FC<{ type: HealthStatType }> = ({ type }) => {
  const ui = HealthStatTypeUiList.find((item) => item.value === type);
  if (!ui) return <>--</>;
  return <Tag color={ui.color}>{ui.text}</Tag>;
};
