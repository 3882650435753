import {
  AggregateIllegalRecordsRequest,
  AggregateWarningsRequest,
  IllegalAggregationGroup,
  Subject,
  WarningAggregationGroup,
} from '@36node-fcp/core-sdk';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import type { RangePickerValue, TimeType } from 'src/components/card-range-picker';
import CardRangePicker, { getTimeDistance } from 'src/components/card-range-picker';
import { VEHICLE_ONLY } from 'src/config';
import { useGantryList } from 'src/features/gantry';
import { SubjectMotorSelect } from 'src/features/illegal-type';
import { useSubNamespaceList } from 'src/features/users';
import { useWarningsAggregation } from 'src/features/warning';
import { useApi } from 'src/lib/react-api';
import { fcp } from 'src/services';

import IllegalAnalyze from './illegal-analyze.page';
import WarningAnalyze from './warning-analyze.page';

type SearchValues = {
  createAt_gt?: string;
  createAt_lt?: string;
  subject?: Subject[];
};

const menuItems = [
  {
    key: 'illegal',
    label: <>违法数</>,
  },
  {
    key: 'warning',
    label: <>预警数</>,
  },
];

const CenterContent = styled.div`
  height: 400px;
  background-color: white;

  .menubar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background-color: red; */
    border-bottom: 1px #eee solid;

    .picker {
      width: 50%;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
  }

  .content {
    margin-top: 10px;
    /* background-color: red; */
    width: 100%;
    height: 100%;
    display: flex;

    .chart {
      width: 65%;
      /* background-color: blue; */
      /* height: 100%; */
      margin: 20px;
      margin-right: 40px;
    }

    .list {
      flex: 1;
      /* background-color: green; */
      margin: 10px;
    }
  }
`;

const IllegalWarningAnalyzePage = ({ onSubject }: { onSubject: (subject: Subject[]) => any }) => {
  const defaultQuery: SearchValues = {
    createAt_gt: getTimeDistance('yesterday')[0].toISOString(), //时间根据选择改变
    createAt_lt: getTimeDistance('yesterday')[1].toISOString(), //时间根据选择改变
    subject: [Subject.VEHICLE], //非机动车为 [Subject.NONMOTOR, Subject.PERSON]
  };

  const illegalByNs: AggregateIllegalRecordsRequest = {
    _group: [IllegalAggregationGroup.NS],
    _limit: 1000,
    _sort: '-count',
    ...defaultQuery,
  };

  const illegalByGantry: AggregateIllegalRecordsRequest = {
    _group: [IllegalAggregationGroup.GANTRY],
    _limit: 7,
    _sort: '-count',
    ...defaultQuery,
  };

  const warningByNs: AggregateWarningsRequest = {
    _group: [WarningAggregationGroup.NS],
    _limit: 1000,
    _sort: '-count',
    ...defaultQuery,
  };

  const warningByGantry: AggregateWarningsRequest = {
    _group: [WarningAggregationGroup.GANTRY],
    _limit: 7,
    _sort: '-count',
    ...defaultQuery,
  };

  const [{ result: gantries = [] }] = useGantryList({ _limit: 1000 });
  const namespaces = useSubNamespaceList();
  const [{ result: illegalsByNs = [] }, listIllegalRecordsByNs] = useApi(fcp.aggregateIllegalRecords);
  const [{ result: illegalsByGantry = [] }, listIllegalRecordsByGantry] = useApi(fcp.aggregateIllegalRecords);
  const [{ result: warningsByNs = [] }, listWaringByNs] = useWarningsAggregation(warningByNs);
  const [{ result: warningsByGantry = [] }, listWaringByGantry] = useWarningsAggregation(warningByGantry);

  const [query, setQuery] = useState<SearchValues>(defaultQuery);
  const [current, setCurrent] = useState('illegal');

  const onClick: MenuProps['onClick'] = (e) => {
    setCurrent(e.key);
  };

  const [rangePickerValue, setRangePickerValue] = useState<RangePickerValue>(getTimeDistance('yesterday'));

  const isActive = (type: TimeType) => {
    if (!rangePickerValue) {
      return '';
    }
    const value = getTimeDistance(type);
    if (!value) {
      return '';
    }
    if (!rangePickerValue[0] || !rangePickerValue[1]) {
      return '';
    }
    if (
      rangePickerValue[0].isSame(value[0] as dayjs.Dayjs, 'day') &&
      rangePickerValue[1].isSame(value[1] as dayjs.Dayjs, 'day')
    ) {
      return 'currentDate';
    }
    return '';
  };

  const selectDate = (type: TimeType) => {
    const dates = getTimeDistance(type);
    const tempQuery = {
      createAt_gt: dates[0].toISOString(),
      createAt_lt: dates[1].toISOString(),
    };
    setQuery(tempQuery);
    setRangePickerValue(dates);
  };

  const selectDateRange = (dates: RangePickerValue) => {
    const tempQuery = {
      createAt_gt: dates[0].startOf('day').toISOString(),
      createAt_lt: dates[1].add(1, 'day').startOf('day').toISOString(),
    };
    setQuery(tempQuery);
    setRangePickerValue(dates);
  };

  const handleNameFilter = (value) => {
    const tempQuery = {
      ...query,
      subject: value === Subject.VEHICLE ? [value] : [Subject.NONMOTOR, Subject.PERSON],
    };
    onSubject(value === Subject.VEHICLE ? [value] : [Subject.NONMOTOR, Subject.PERSON]);
    setQuery(tempQuery);
  };

  useEffect(() => {
    if (current === 'illegal') {
      listIllegalRecordsByNs({ ...illegalByNs, ...query });
      listIllegalRecordsByGantry({ ...illegalByGantry, ...query });
    } else {
      listWaringByNs({ ...warningByNs, ...query });
      listWaringByGantry({ ...warningByGantry, ...query });
    }
  }, [query, current]);

  return (
    <CenterContent>
      <div className="menubar">
        <Menu
          style={{ borderBottom: '0px', fontSize: '16px', fontWeight: 600, lineHeight: '55px', paddingLeft: '10px' }}
          theme="light"
          mode="horizontal"
          onClick={onClick}
          selectedKeys={[current]}
          items={menuItems}
        />
        {!VEHICLE_ONLY && (
          <SubjectMotorSelect
            placeholder="请选择违法主体"
            onChange={handleNameFilter}
            style={{ minWidth: 200, marginLeft: 'auto' }}
            defaultValue={[Subject.VEHICLE]}
          ></SubjectMotorSelect>
        )}
        <CardRangePicker
          rangePickerValue={rangePickerValue}
          isActive={isActive}
          handleRangePickerChange={selectDateRange}
          selectDate={selectDate}
        />
      </div>
      {current === 'illegal' ? (
        <IllegalAnalyze charts={illegalsByNs} list={illegalsByGantry} gantries={gantries} namespaces={namespaces} />
      ) : (
        <WarningAnalyze charts={warningsByNs} list={warningsByGantry} gantries={gantries} namespaces={namespaces} />
      )}
    </CenterContent>
  );
};

export default IllegalWarningAnalyzePage;
