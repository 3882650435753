import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { MQTT_URL } from './config';
import { MonitorProvider } from './containers/monitor/monitor.provider';
import { SessionProvider } from './features/session';
import { getRoutes } from './layouts/routes';
import { MqttProvider } from './lib/react-mqtt';

export const App: React.FC = () => {
  return (
    <SessionProvider>
      <MqttProvider url={MQTT_URL} options={{ username: 'fcp', password: '36node' }}>
        <MonitorProvider>
          <RouterProvider router={createBrowserRouter(getRoutes())} />
        </MonitorProvider>
      </MqttProvider>
    </SessionProvider>
  );
};
export default App;
