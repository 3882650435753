import { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { get } from 'lodash';

export type Response<T> = Promise<AxiosResponse<T>>;

export type Entity = {
  id: string;
  ns: string; // 命名空间
  source?: string; // 来源
  creatAt?: string; // 创建时间
  updateAt?: string; // 修改时间
};

export type ListParams = {
  _limit?: number;
  _offset?: number;
  _sort?: string;
  ns?: string;
  source?: string;
  ns_like?: string;
};

export const listAllPages =
  <K, T>(list: (params: K) => Response<T[]>) =>
  (args: K): Response<T[]> => {
    const _limit = 100;
    let res;
    const _listAllPages = (args: K): Promise<T[]> => {
      return list(args).then((response) => {
        res = response;
        const { data, headers } = response;
        const { 'x-total-count': totalCount } = headers;
        const _offset = get(args, '_offset', 0) as number;
        if (_limit + _offset < parseInt(totalCount)) {
          return _listAllPages({ ...args, _limit, _offset: _limit + _offset }).then((nextData) => {
            return [...data, ...nextData];
          });
        }
        return data;
      });
    };

    return _listAllPages({ ...args, _limit, _offset: 0 }).then((data) => ({ ...res, data }));
  };

export const injectToken = (getToken: () => string | undefined) => (config: InternalAxiosRequestConfig) => {
  const nc = { ...config };
  const token = getToken();
  if (token) {
    nc.headers['Authorization'] = `Bearer ${token}`;
  }
  return nc;
};
