import { UploadOutlined } from '@ant-design/icons';
import { Button, Modal, Progress, Upload, UploadProps } from 'antd';
import { styled } from 'styled-components';

import { ImportStatus, ImportValidateError, useImport, XlsxImportColumn } from 'src/lib/react-xlsx';
import { Response } from 'src/sdk';

type ModalImportProps<T extends Object = any> = {
  api: (obj: T) => Response<any> | void;
  beforeUpload?: (records: T[]) => Promise<ImportValidateError[] | void>;
  columns: XlsxImportColumn[];
  onClose: () => void;
  onFinish?: () => void;
  onSuccess?: (records: T[]) => void;
  onFailure?: (validateErrors: ImportValidateError[], error?: any) => void;
  title?: string;
  width?: number;
  template?: string;
  isSupportConcurrency?: boolean;
  concurrentNumber?: number;
};

function getTip(status: ImportStatus, error?: Error, total: number = 0) {
  switch (status) {
    case ImportStatus.INIT:
      return `准备导入`;
    case ImportStatus.READING:
      return '正在读取文件...';
    case ImportStatus.VALIDATING:
      return '正在校验文件...';
    case ImportStatus.INVALID:
      return '文件校验失败';
    case ImportStatus.VALID:
      return '文件读取成功';
    case ImportStatus.UPLOADING:
      return '正在上传...';
    case ImportStatus.SUCCESS:
      return `成功导入${total}条数据`;
    case ImportStatus.FAILURE:
      return error?.message || '导入失败';
  }
}

function getColor(status: ImportStatus) {
  switch (status) {
    case ImportStatus.INVALID:
    case ImportStatus.FAILURE:
      return 'red';
    default:
      return;
  }
}

function getLoading(status: ImportStatus) {
  switch (status) {
    case ImportStatus.UPLOADING:
      return true;
    default:
      return false;
  }
}

function getOkText(status: ImportStatus) {
  switch (status) {
    case ImportStatus.SUCCESS:
      return '完成';
    case ImportStatus.FAILURE:
    case ImportStatus.INVALID:
      return '关闭';
    default:
      return '导入';
  }
}

function getOkDisabled(status: ImportStatus) {
  switch (status) {
    case ImportStatus.INIT:
    case ImportStatus.READING:
    case ImportStatus.VALIDATING:
      return true;
    default:
      return false;
  }
}

const showUploadList = { showRemoveIcon: false, showPreviewIcon: false, showDownloadIcon: false };

export function ImportModal<T = any>(props: ModalImportProps) {
  const {
    api,
    beforeUpload,
    columns: coloumns,
    onClose,
    onFinish,
    onSuccess,
    onFailure,
    template,
    title,
    width,
    isSupportConcurrency = false,
    concurrentNumber = 5,
  } = props;
  const [{ current, total, status, error, validateErrors }, dispatch] = useImport<T>(api, coloumns, {
    onFileLoad: beforeUpload,
    onSuccess,
    onFailure,
    isSupportConcurrency,
    concurrentNumber,
  });

  const handleClose = () => onClose();
  const handleOk = () => {
    if (status === ImportStatus.VALID) {
      dispatch.callImport();
    }
    if (status === ImportStatus.INVALID) {
      onClose();
    }
    if (status === ImportStatus.SUCCESS || status === ImportStatus.FAILURE) {
      onFinish?.();
      onClose();
    }
  };

  const handleBeforeUpload: UploadProps['beforeUpload'] = (file) => {
    dispatch.read(file);
    return false;
  };

  const progress = total ? Math.round((current / total) * 100) : 0;

  return (
    <Modal
      title={title}
      open
      onOk={handleOk}
      onCancel={handleClose}
      okText={getOkText(status)}
      confirmLoading={getLoading(status)}
      okButtonProps={{ disabled: getOkDisabled(status) }}
      width={width}
      maskClosable={false}
    >
      <Container>
        <Upload maxCount={1} beforeUpload={handleBeforeUpload} accept=".xlsx, .xls" showUploadList={showUploadList}>
          <Button disabled={status !== ImportStatus.INIT}>
            <UploadOutlined />
            上传文件
          </Button>
        </Upload>
        {template && status === ImportStatus.INIT && (
          <div style={{ marginTop: 8 }}>
            &nbsp;
            <a href={`/templates/${template}`} target="_blank" rel="noreferrer">
              下载文件模板
            </a>
          </div>
        )}

        <p style={{ color: getColor(status) }}>{getTip(status, error, total)}</p>
        {validateErrors.slice(0, 20).map((err, index) => (
          <p key={index}>
            第{err.row}行: {err.message}
          </p>
        ))}
        <Progress
          percent={progress}
          status={status === ImportStatus.FAILURE ? 'exception' : 'active'}
          size={[450, 20]}
        />
      </Container>
    </Modal>
  );
}

const Container = styled.div`
  padding: 24px 0;
`;
