import { ColumnType } from 'antd/es/table';
import { get, set } from 'lodash';
import { useCallback } from 'react';

import { MAX_TABLE_RECORDS } from 'src/config';
import { useSlice } from 'src/lib/react-slice';
import { writeXlsx } from 'src/lib/xlsx';
import { Response } from 'src/sdk';

export enum ExportStatus {
  INIT = 'INIT',
  FETCHING = 'FETCHING',
  WRITING = 'WRITING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export interface XlsxExportColumn<T = any> extends ColumnType<T> {
  _width?: number;
  compute?: (val: any, record: any) => any;
  disabled?: boolean; // 这一列不导出
}

export type XlsxExportState = {
  total: number; // 总数
  progress: number; // 进度
  status: ExportStatus; // 导出状态
  error?: any; // 错误信息
};

const initialState: XlsxExportState = {
  progress: 0,
  total: 0,
  status: ExportStatus.INIT,
};

const reducers = {
  fetch: (state: XlsxExportState) => {
    state.status = ExportStatus.FETCHING;
  },
  progress: (state: XlsxExportState, { page, pageSize, total }: { page: number; pageSize: number; total: number }) => {
    state.progress = Math.floor((page * pageSize * 100) / total);
    state.total = total;
  },
  write: (state: XlsxExportState) => {
    state.status = ExportStatus.WRITING;
  },
  success: (state: XlsxExportState) => {
    state.status = ExportStatus.SUCCESS;
  },
  failure: (state: XlsxExportState, error: any) => {
    state.status = ExportStatus.FAILURE;
    state.error = error;
  },
};

const getDataKey = (column: XlsxExportColumn) => column.dataIndex || column.key;

export const useExport = <K = any, T extends Object = any>(
  columns: XlsxExportColumn<T>[],
  options?: { onSuccess?: () => void; onFailure?: (error: any) => void },
  list?: (params: K) => Response<T[]>,
  dataSource?: any[]
) => {
  const [state, actions] = useSlice(reducers, initialState);

  const call = useCallback(
    async (args: K, filename?: string) => {
      let page = 1;
      let result: T[] = [];
      const pageSize = 1000;

      async function _func(list: (params: K) => Response<T[]>, args: K) {
        const res = await list({ ...args, _limit: pageSize, _offset: (page - 1) * pageSize });
        const { data, headers } = res;
        const { 'x-total-count': totalCount } = headers;
        result = result.concat(data);

        const total = Math.min(MAX_TABLE_RECORDS, parseInt(totalCount));
        actions.progress({ page, pageSize, total });
        if (page * pageSize < total) {
          page++;
          await _func(list, args);
        }
      }

      try {
        actions.fetch();
        if (dataSource) {
          result = dataSource;
          actions.progress({ page: 1, pageSize: result.length, total: result.length });
        } else {
          await _func(list, args);
        }

        actions.write();

        // 根据 columns 转换
        const exportColumns = columns.filter(getDataKey).filter((col) => !col.disabled);
        const records = [];
        for (const row of result) {
          const record = { ...row };
          for (const col of exportColumns) {
            if (typeof col.compute === 'function') {
              const val = get(row, col.dataIndex!);
              const result = col.compute(val, row);
              set(record, String(getDataKey(col)), result);
            }
          }
          records.push(record);
        }

        writeXlsx({
          rows: records,
          columns: exportColumns,
          distFile: filename,
        });

        actions.success();
        options?.onSuccess?.();
      } catch (e) {
        actions.failure(e);
        options?.onFailure?.(e);
      }
    },
    [list, columns]
  );

  return [state, call] as const;
};
