import { ApiError, Blacklist, CreateBlacklistDto, ListBlacklistsRequest } from '@36node-fcp/core-sdk';
import { Button, Card, DatePicker, Divider, Form, Input, message, Popconfirm } from 'antd';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { isArray, isEmpty, min } from 'lodash';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { AdColumnsType, AdTable } from 'src/components/antd/ad-table';
import { ExportModal } from 'src/components/xlsx-export-modal';
import { ImportModal } from 'src/components/xlsx-import-modal';
import { MAX_BATCH_REMOVE_RECORDS } from 'src/config';
import {
  BlacklistApiErrorMap,
  toVehicleBlacklistStateText,
  toVehicleBlacklistStateValue,
  useVehicleBlacklist,
  VehicleBlacklistStateBadge,
  VehicleBlacklistStateFilter,
} from 'src/features/blacklist';
import { useGantryDeviceList } from 'src/features/gantry-device';
import { useIllegalTypeList } from 'src/features/illegal-type';
import { useSearch } from 'src/hook/search';
import { ymdhms } from 'src/lib/lang/time';
import { useApi } from 'src/lib/react-api';
import { useSlice } from 'src/lib/react-slice';
import { ImportValidateError } from 'src/lib/react-xlsx';
import { fcp, passApiErrors } from 'src/services';

import { VehicleEditor } from './vehicle.editor';

const Container = styled(Card)`
  border: none !important;
  .ant-card-head {
    border: 0 !important;
    .ant-card-extra {
      padding: 8px 0;
    }
  }
  .ant-card-body {
    margin-bottom: 24px;
  }
`;

type SearchValues = {
  createAt?: any;
} & ListBlacklistsRequest;

type SearchFormProps = {
  onSearch: (values: SearchValues) => void;
  initialValues?: SearchValues;
};

const defaultQuery: ListBlacklistsRequest = {
  _limit: 10,
  _offset: 0,
};

// 判断页面是否操作过查询
const isSearched = (search: SearchValues) => {
  // 切换页码不算查询
  // 默认查询条件不算查询
  const { _limit, _offset, ...rest } = search;
  return !isEmpty(rest);
};

const SearchForm: React.FC<SearchFormProps> = ({ onSearch, initialValues }) => {
  return (
    <Container>
      <Form onFinish={onSearch} initialValues={initialValues} layout={'inline'}>
        <Form.Item name="plate" label="车牌：">
          <Input placeholder="车牌查询" allowClear />
        </Form.Item>
        <Form.Item name="plateType" label="号牌种类：">
          <Input placeholder="号牌种类查询" allowClear />
        </Form.Item>
        <Form.Item label="入库时间：" name="createAt">
          <DatePicker.RangePicker
            showTime
            showSecond={false}
            placeholder={['开始时间', '结束时间']}
            format="YYYY-MM-DD HH:mm"
          />
        </Form.Item>
        <Form.Item shouldUpdate>
          <Button type="primary" htmlType="submit">
            查询
          </Button>
        </Form.Item>
      </Form>
    </Container>
  );
};

type State = {
  importVisible: boolean;
  exportVisible: boolean;
  editorVisible: boolean;
  editVehicle?: Blacklist;
};

const initState: State = {
  importVisible: false,
  exportVisible: false,
  editorVisible: false,
};

const reducers = {
  openExport(state: State) {
    state.exportVisible = true;
  },
  closeExport(state: State) {
    state.exportVisible = false;
  },
  openImport(state: State) {
    state.importVisible = true;
  },
  closeImport(state: State) {
    state.importVisible = false;
  },
  openEditor(state: State, Vehicle?: Blacklist) {
    state.editorVisible = true;
    state.editVehicle = Vehicle;
  },
  closeEditor(state: State) {
    state.editorVisible = false;
    state.editVehicle = undefined;
  },
};

const upload = async (doc: CreateBlacklistDto) =>
  fcp.upsertBlacklist({ body: doc }).catch((err: AxiosError<ApiError>) => {
    throw passApiErrors(err.response.data, BlacklistApiErrorMap);
  });

const beforeUpload = async (records: CreateBlacklistDto[]) => {
  const set = new Set();
  const errors: ImportValidateError[] = [];
  records.forEach(({ plate, plateType }, index) => {
    if (set.has(`${plate}-${plateType}`)) {
      errors.push({
        row: index + 2,
        message: '表格内有重复车辆',
      });
    }
    set.add(`${plate}-${plateType}`);
  });
  return errors;
};

const toQuery = (values: SearchValues): ListBlacklistsRequest => {
  const { createAt, ...rest } = values;
  if (createAt && isArray(createAt) && createAt[0] && createAt[1]) {
    rest.createAt_gt = dayjs(createAt[0].toISOString()).startOf('minute').toISOString();
    rest.createAt_lt = dayjs(createAt[1].toISOString()).endOf('minute').toISOString();
  } else if (values.createAt === null) {
    rest.createAt_gt = undefined;
    rest.createAt_lt = undefined;
  }
  return { ...defaultQuery, ...rest };
};

/**
 * 车辆黑名单页面
 */
const IllegalPage: React.FC = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useSearch<SearchValues>();
  const [{ result: gantryDevices = [] }] = useGantryDeviceList({ _limit: 1000 });
  const [{ result: illegalTypes = [] }] = useIllegalTypeList({ _limit: 1000 });
  const [{ result, loading, request = {}, total }, listBlacklists] = useVehicleBlacklist(toQuery(search));
  const { _limit: limit = 10, _offset: offset = 0 } = request;
  const [{ importVisible, exportVisible, editorVisible, editVehicle }, dispatch] = useSlice(reducers, initState);

  const deleteOptions = useMemo(
    () => ({
      onSuccess: () => {
        message.success('删除成功');
        listBlacklists(request);
      },
      onFailure: (err) => {
        message.error(`删除失败: ${err.message}`);
      },
    }),
    [request]
  );

  const batchDeleteOptions = useMemo(
    () => ({
      onSuccess: () => {
        message.success(`批量删除成功，本次共删除${min([total, MAX_BATCH_REMOVE_RECORDS])}条数据`);
        listBlacklists(request);
      },
      onFailure: (err) => {
        message.error(`批量删除失败: ${err.message}`);
      },
    }),
    [request]
  );

  const [deleteState, deleteVehicleBlacklist] = useApi(fcp.deleteBlacklist, deleteOptions);

  const [batchDeleteState, batchDeleteStateRecord] = useApi(fcp.batchDeleteBlacklists, batchDeleteOptions);

  // 搜索、分页、排序触发
  const handleSearch = (values: SearchValues) => {
    setSearch({
      ...search, // 上一次留存的查询条件
      _offset: 0, // 重置页码
      ...values, // 本次输入的查询条件，取消的条件用 undefined 覆盖上一次的查询条件
    });
  };

  const handleRefresh = () => listBlacklists(request);
  const handleBatchDelete = () => batchDeleteStateRecord(request as any);

  const columns = useMemo<AdColumnsType<Blacklist>>(
    () => [
      {
        title: '车牌',
        dataIndex: 'plate',
        rules: [{ required: true, message: '请填写车牌' }],
      },
      {
        title: '号牌种类',
        dataIndex: 'plateType',
        rules: [{ required: true, message: '请填写号牌种类' }],
      },
      {
        title: '描述',
        dataIndex: 'content',
        width: '39%',
        rules: [{ required: true, message: '请填写描述信息' }],
      },
      {
        title: '入库时间',
        dataIndex: 'createAt',
        compute: ymdhms,
        // importDisabled: true,
      },
      {
        title: '状态',
        dataIndex: 'state',
        render: (val) => <VehicleBlacklistStateBadge state={val} />,
        filters: VehicleBlacklistStateFilter,
        compute: toVehicleBlacklistStateText,
        import: toVehicleBlacklistStateValue,
        importDisabled: true,
        filterMultiple: false,
        defaultFilteredValue: search?.state && [search?.state],
      },
      {
        title: '操作',
        render: (node, record) => (
          <>
            <Button
              type="link"
              style={{ padding: 0 }}
              onClick={() => {
                navigate(record.id);
              }}
            >
              详情
            </Button>
            <Divider type="vertical" />
            <Popconfirm
              title="你确定要删除这行内容吗？"
              onConfirm={() => deleteVehicleBlacklist({ blacklistId: record.id })}
            >
              <Button type="link" danger style={{ padding: 0 }} loading={deleteState.loading}>
                删除
              </Button>
            </Popconfirm>
          </>
        ),
      },
    ],
    [dispatch, gantryDevices, illegalTypes, deleteVehicleBlacklist, search]
  );

  return (
    <>
      <SearchForm onSearch={handleSearch} initialValues={search} />
      <AdTable
        columns={columns}
        extraTools={
          <Popconfirm
            title={
              <>
                是否将查询到的内容全部删除？
                <br />
                将删除{min([total, MAX_BATCH_REMOVE_RECORDS])}条数据
              </>
            }
            onConfirm={handleBatchDelete}
          >
            <Button
              disabled={!isSearched(search) || !total || loading}
              loading={batchDeleteState.loading}
              type="primary"
            >
              按查询结果删除
            </Button>
          </Popconfirm>
        }
        title={'车辆黑名单'}
        rowKey="id"
        loading={loading}
        scroll={{ x: 'max-content' }}
        dataSource={result}
        onAddNew={() => dispatch.openEditor()}
        onChange={handleSearch}
        onRefresh={handleRefresh}
        onExport={dispatch.openExport}
        onUpload={dispatch.openImport}
        pagination={{
          total,
          current: offset / limit + 1,
          pageSize: limit,
        }}
      />
      {exportVisible && (
        <ExportModal
          api={fcp.listBlacklists}
          args={request}
          columns={columns}
          filename="车辆黑名单.xlsx"
          onClose={dispatch.closeExport}
          title="车辆黑名单导出"
          total={total}
        />
      )}
      {importVisible && (
        <ImportModal
          api={upload}
          isSupportConcurrency
          concurrentNumber={5}
          beforeUpload={beforeUpload}
          columns={columns}
          template="车辆黑名单导入模板.xlsx"
          onClose={dispatch.closeImport}
          onFinish={handleRefresh}
          title="车辆黑名单导入"
        />
      )}
      {editorVisible && <VehicleEditor onClose={dispatch.closeEditor} onFinish={handleRefresh} Vehicle={editVehicle} />}
    </>
  );
};

export default IllegalPage;
