import * as mqtt from 'mqtt/dist/mqtt.min';
import { useEffect, useState } from 'react';

import { MqttContext } from './context';

export interface MqttProviderProps {
  url: string;
  options?: mqtt.IClientOptions;
  children?: React.ReactNode;
}

export function MqttProvider(props: MqttProviderProps) {
  const [client, setClient] = useState<mqtt.MqttClient | null>(null);

  useEffect(() => {
    const client = mqtt.connect(props.url, { ...props.options });

    client.on('connect', () => {
      console.info('MQTT 连接成功');
      setClient(client);
    });

    client.on('error', (err) => {
      console.error('MQTT 错误: ', err);
      client.end();
    });
    client.on('reconnect', () => {
      console.info('MQTT 重连中...');
    });
    client.on('disconnect', () => {
      console.info('MQTT 断开连接');
    });

    return () => {
      if (process.env.NODE_ENV !== 'development') {
        client.end();
        setClient(null);
      }
    };
  }, [props.url]);

  return <MqttContext.Provider value={client}>{props.children}</MqttContext.Provider>;
}
