import { Avatar } from 'antd';
import styled from 'styled-components';

type AvatarProps = {
  index: number;
};

interface CustomAvatarProps {
  index: number;
}

const StyledAvatar = styled(Avatar)<CustomAvatarProps>`
  width: 20px !important;
  height: 20px !important;
  line-height: 19px !important;
  text-align: center !important;
  font-size: 12px !important;
  margin-right: 10px !important;
  background-color: ${({ index }) => (index < 3 ? '#333 !important' : '#eee !important')};
  color: ${({ index }) => (index < 3 ? '#eee !important' : '#333 !important')};
`;

const UserAvatar: React.FC<AvatarProps> = ({ index }) => {
  return <StyledAvatar index={index}>{index + 1}</StyledAvatar>;
};

export default UserAvatar;
