import { Spin } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { styled } from 'styled-components';

import { getSession, getSessionId, saveSession, stargate } from 'src/services';

export const useSession = () => {
  const session = getSession();
  return session;
};

interface SessionProviderProp {
  children: JSX.Element;
}

interface RequireAuthProp {
  children: JSX.Element;
  privileges?: string[];
  roles?: string[];
  redirect?: string;
}

const TOKEN_THRESHOLD = 10; // 在 token 过期前多少分钟

const Container = styled.div`
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  line-height: 80vh;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
`;

/**
 * session 上下文提供者
 *
 * - 自动登录
 * - 自动刷新 token
 */
export const SessionProvider = ({ children }: SessionProviderProp) => {
  const sessionId = getSessionId();
  const [loading, setLoading] = useState(!!sessionId);

  useEffect(() => {
    // 存在 sessionId 自动登录
    if (sessionId) {
      stargate
        .refresh({ session: sessionId })
        .then((res) => saveSession(res.data))
        .catch(console.error)
        .finally(() => {
          setLoading(false);
        });
    }

    // 定期刷新 token
    const interval = setInterval(
      () => {
        // 已经有 session，并且 token 还有效
        const session = getSession();
        if (session && dayjs(session.tokenExpireAt) < dayjs().add(TOKEN_THRESHOLD, 'minutes')) {
          stargate.refresh({ session: session.id }).then((res) => saveSession(res.data));
        }
      },
      2 * 60 * 1000
    );

    return () => clearInterval(interval);
  }, []);

  if (loading) {
    return (
      <Container>
        <Spin />
      </Container>
    );
  }

  return children;
};

export const RequireAuth = ({ children, redirect = '/login' }: RequireAuthProp) => {
  const session = useSession();
  const location = useLocation();

  if (!session) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={redirect} state={{ from: location }} replace />;
  }

  return children;
};
