/**
 * 内置角色 不可删除和调整
 */
export const BuiltInSuperManager = 'USERMANAGER';
export const BuiltInRoles = [BuiltInSuperManager, 'USER'];

export const MAX_TABLE_RECORDS = 50000;

export const MAX_BATCH_REMOVE_RECORDS = 2000;

export const IllegalTypeLevelColor = {
  1: '#D9001B',
  2: '#FF6732',
  3: '#F5B223',
};

export const GantryAttrText = {
  MAINLINE: '主线', // 主线
  INTERCITY: '市际卡口', // 市际卡口
  SVCRAMP: '服务区匝道', // 服务区匝道
  EXPORTRAMP: '出口匝道', // 出口匝道
  HUBRAMP: '枢纽卡口', // 枢纽卡口
};
