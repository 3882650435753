import { ApiError } from '@36node-fcp/core-sdk';
import { Alert, Form, Input, message, Modal } from 'antd';
import styled from 'styled-components';

import { NamespaceApiErrorMap, NamespaceCascaderSelect } from 'src/features/users';
import { useApi } from 'src/lib/react-api';
import { CreateNamespaceRequest, Namespace } from 'src/sdk';
import { passApiErrors, stargate } from 'src/services';

type EditorValues = CreateNamespaceRequest & {};

interface NamespaceEditorProps {
  namespace?: Namespace;
  onClose: () => void;
  onFinish?: () => void;
  defaultParent?: string;
}

export const NamespacesEditor: React.FC<NamespaceEditorProps> = ({
  namespace,
  onClose: handleClose,
  onFinish: handleFinish,
  defaultParent,
}) => {
  const isEdit = !!namespace;
  const onSuccess = () => {
    message.success(isEdit ? '编辑成功' : '创建成功');
    handleClose();
    handleFinish?.();
  };
  const onFailure = (err: ApiError) => {
    const ne = passApiErrors(err, NamespaceApiErrorMap);
    if (ne.keys) {
      const errors = ne.keys.map((key) => ({ name: key, errors: [ne.message] }));
      form.setFields(errors);
    } else {
      message.error(ne.message);
    }
  };

  const [form] = Form.useForm<EditorValues>();
  const [updateState, update] = useApi(stargate.updateNamespace, { onSuccess, onFailure });
  const [createState, create] = useApi(stargate.createNamespace, { onSuccess, onFailure });

  const handleOk = () => form.submit();
  const handleSubmit = async (values: any) => {
    if (isEdit) {
      update({ namespace: namespace.id, body: values });
    } else {
      create({ body: { ...values, id: [values.parent, values.id].join('.') } });
    }
  };

  const isLoading = updateState.loading || createState.loading;
  const title = isEdit ? '编辑分组' : '新增分组';
  const error = updateState.error || createState.error;

  return (
    <Modal title={title} open onOk={handleOk} onCancel={handleClose} confirmLoading={isLoading} width="650px">
      <Container>
        {error && !Object.values(ApiError.Code).includes(error.code) && <Alert message={error.message} type="error" />}
        <Form
          name="namespace-editor"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          initialValues={namespace ? namespace : { parent: defaultParent, id: '', name: '' }}
          autoComplete="off"
          onFinish={handleSubmit}
          form={form}
        >
          <Form.Item
            label="分组ID"
            name="id"
            rules={[
              { required: true, message: '请填写分组ID' },
              {
                pattern: /^[A-Za-z][A-Za-z\d_]+$/,
                message: '必须英文字母开头，可以包含数字和下划线',
              },
            ]}
          >
            <Input placeholder="请输入英文" autoComplete="off" />
          </Form.Item>
          <Form.Item label="分组名" name="name" rules={[{ required: true, message: '请填分组名' }]}>
            <Input placeholder="填写分组名" autoComplete="off" />
          </Form.Item>
          <Form.Item label="父分组" name="parent" rules={[{ required: true, message: '请选择父分组' }]}>
            <NamespaceCascaderSelect
              placeholder="选择父分组"
              onChange={(value) => form.setFieldValue('parent', value)}
            />
          </Form.Item>
        </Form>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  margin: 24px;
`;
