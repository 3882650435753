/* eslint-disable import/order */
import { InfoCircleFilled } from '@ant-design/icons';
import { Typography } from 'antd';
import type { LatLngExpression } from 'leaflet';
import L, { IconOptions, latLngBounds, Popup as PopupRef } from 'leaflet';
import React, { useEffect, useMemo } from 'react';
import { Marker, Popup, useMapEvents } from 'react-leaflet';

import GantryControlIcon from 'src/assets/gantry-control.svg';
import GantryNormalIcon from 'src/assets/gantry-normal.svg';
import {
  MAP_BOUND_NORTH_EAST,
  MAP_BOUND_SOUTH_WEST,
  MAP_CURRENT_ZOOM,
  MAP_ENDPOINT,
  MAP_GANTRY_MIN_ZOOM,
} from 'src/config';

import 'leaflet/dist/leaflet.css';

import { Gantry } from '@36node-fcp/core-sdk';

type GantryMarkerProps = {
  gantry: Gantry;
  active?: boolean;
  watched?: boolean;
  onClick?: (gantry: Gantry) => void;
};

export const MapBounds = latLngBounds(MAP_BOUND_SOUTH_WEST, MAP_BOUND_NORTH_EAST);
export const MapUrl = MAP_ENDPOINT + '/{z}/{x}/{y}.png';

const IconSize = 24;
const GANTRY_ICON_SIZE = 32;
const gantryControlIcon = '/icon/gantry-control.png';

export function GantryMarker({ gantry, active, watched, onClick }: GantryMarkerProps) {
  const popRef = React.useRef<PopupRef>();
  const [hovered, setHovered] = React.useState<boolean>(false);
  const [visible, setVisible] = React.useState(MAP_CURRENT_ZOOM >= MAP_GANTRY_MIN_ZOOM);
  const isActive = active || hovered;

  const map = useMapEvents({
    zoom: (e) => setVisible(e.target._zoom >= MAP_GANTRY_MIN_ZOOM),
  });

  const icon = React.useMemo(() => {
    const iconSize = isActive ? IconSize * 1.5 : IconSize;

    const iconOption: IconOptions = {
      iconUrl: watched ? GantryControlIcon : GantryNormalIcon,
      iconAnchor: [iconSize / 2, iconSize / 2],
      iconSize: [iconSize, iconSize],
    };

    return L.icon(iconOption);
  }, [hovered, active, watched]);

  useEffect(() => {
    if (!popRef.current || !map) return;
    if (isActive) {
      popRef.current?.openOn(map);
    } else {
      popRef.current?.removeFrom(map);
    }
  }, [active, hovered]);

  if (!gantry?.gcj02) return null;

  const { lng, lat } = gantry.gcj02;

  return (
    <Marker
      opacity={visible ? 1 : 0}
      position={[lat, lng]}
      icon={icon}
      eventHandlers={{
        click: () => {
          onClick?.(gantry);
        },
        mouseover: () => {
          setHovered(true);
        },
        mouseout: () => {
          setHovered(false);
        },
      }}
    >
      <Popup
        closeButton={false}
        autoClose={false}
        autoPan={false}
        ref={(ref) => {
          popRef.current = ref;
        }}
        offset={[0, -10]}
      >
        <InfoCircleFilled style={{ color: '#1890ff', fontSize: 16, margin: '0 6px' }} />
        <Typography.Text strong style={{ fontSize: 16 }}>
          {gantry.name}
        </Typography.Text>
      </Popup>
    </Marker>
  );
}

type WarningMarkerProps = {
  active?: boolean;
  gantry: Gantry;
  watched?: string;
};

export function WarningPopup({ gantry, active, watched }: WarningMarkerProps) {
  const icon = useMemo(() => {
    const iconSize = GANTRY_ICON_SIZE * 2;

    const iconOption: IconOptions = {
      iconUrl: gantryControlIcon,
      iconAnchor: [iconSize / 2, iconSize / 2],
      iconSize: [iconSize, iconSize],
    };

    if (watched) {
      iconOption.iconUrl = gantryControlIcon;
    }

    return L.icon(iconOption as IconOptions);
  }, [active, watched]);

  const { lng, lat } = gantry || {};
  if (!lng || !lat) return null;

  const position: LatLngExpression = [lat, lng];

  return (
    <Marker position={position} icon={icon}>
      <Popup closeButton={false} offset={[0, -10]}>
        <Typography.Text strong style={{ fontSize: 14 }}>
          {gantry.name}
        </Typography.Text>
      </Popup>
    </Marker>
  );
}
