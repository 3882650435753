import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Space, TimePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

import { TimeInterval } from 'src/sdk/robot-sdk';

interface TimeIntervalProps<T = TimeInterval> {
  value?: T[];
  onChange?: (list: T[]) => void;
}

export const TimeIntervalCell: React.FC<TimeIntervalProps> = ({ value, onChange }) => {
  return (
    <Space direction="vertical">
      {value &&
        value.length > 0 &&
        value.map((r, i) => (
          <Space key={i}>
            <TimePicker.RangePicker
              format={'HH:mm'}
              allowClear={false}
              value={[dayjs(r.start, 'HH:mm'), dayjs(r.end, 'HH:mm')]}
              onChange={(range: [Dayjs, Dayjs]) => {
                const cur = { ...value[i] };
                const newArr = [...value];
                cur.start = range[0].format('HH:mm');
                cur.end = range[1].format('HH:mm');
                newArr[i] = cur;
                onChange(newArr);
              }}
            />
            <MinusCircleOutlined
              onClick={() => {
                const newValue = [...value.slice(0, i), ...value.slice(i + 1)];
                onChange(newValue);
              }}
            />
          </Space>
        ))}
      <PlusCircleOutlined
        onClick={() => {
          let newValue = [];
          if (value) {
            newValue = [...value];
            newValue.push({ start: '00:00', end: '23:59' });
          } else newValue = [{ start: '00:00', end: '23:59' }];
          onChange(newValue);
        }}
      />
    </Space>
  );
};
