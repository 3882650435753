import { ListTrackRecordsRequest, TrackRecord } from '@36node-fcp/core-sdk';
import { Button, Card, DatePicker, Form, Input, Spin } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { intersection, isArray } from 'lodash';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { AdColumnsType, AdTable } from 'src/components/antd/ad-table';
import { ROOT_NS } from 'src/config';
import { useGantryList } from 'src/features/gantry';
import { useLawEnforcePointList } from 'src/features/law-enforce-point';
import { useTrackRecordList } from 'src/features/track-record';
import { useNamespaceList } from 'src/features/users';
import { useSearch } from 'src/hook/search';
import { ymdhms } from 'src/lib/lang/time';
import { Namespace } from 'src/sdk';

const { RangePicker } = DatePicker;

type SearchValues = {
  capAt?: Dayjs[];
  lawEnforcePoint?: string[];
} & ListTrackRecordsRequest;
type SearchFormProps = {
  onSearch: (values: SearchValues) => void;
  initialValues?: SearchValues;
};

const defaultQuery: ListTrackRecordsRequest = { _limit: 10, _offset: 0, _sort: '-createAt' };

const SearchForm: React.FC<SearchFormProps> = ({ onSearch, initialValues }) => {
  return (
    <Form onFinish={onSearch} initialValues={initialValues} layout={'inline'}>
      <Form.Item label="车牌" name="plate">
        <Input placeholder="请输入" allowClear />
      </Form.Item>
      <Form.Item label="过车时间" name="capAt">
        <RangePicker
          showTime
          showSecond={false}
          format="YYYY-MM-DD HH:mm"
          placeholder={['开始时间', '结束时间']}
          allowClear
        />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          查询
        </Button>
      </Form.Item>
    </Form>
  );
};

/**
 * 过车管理页面
 */
const TrackRecordPage: React.FC = () => {
  const [search, setSearch] = useSearch<SearchValues>();
  const [{ result: gantries = [] }] = useGantryList({ _limit: 1000 });
  const [{ result: allLawEnforcePoints = [] }] = useLawEnforcePointList({ _limit: 1000 });

  const toQuery = (values: SearchValues): ListTrackRecordsRequest => {
    const { capAt, lawEnforcePoint = [], gantry = [], ...rest } = values;
    //只处理有capAt的情况
    if (capAt === null) {
      rest.capAt_gte = undefined;
      rest.capAt_lte = undefined;
    }

    if (capAt && capAt.length === 2) {
      rest.capAt_gte = dayjs(values.capAt[0].toISOString()).startOf('minute').toISOString();
      rest.capAt_lte = dayjs(values.capAt[1].toISOString()).endOf('minute').toISOString();
    }

    const l2g = []; //执法点对应的gantry
    if (lawEnforcePoint.length > 0) {
      let lawEnforcePointArray;
      if (!isArray(lawEnforcePoint)) {
        lawEnforcePointArray = [lawEnforcePoint];
      } else {
        lawEnforcePointArray = lawEnforcePoint;
      }
      for (let i = 0; i < lawEnforcePointArray.length; i++) {
        const selectGantries = gantries
          ?.filter((gantry) => gantry.lawEnforcePoint?.id === lawEnforcePointArray[i])
          .map((item) => item.id);
        if (selectGantries && selectGantries.length > 0) {
          l2g.push(...selectGantries);
        }
      }
    }
    let gantryIds = [];
    // 同时存在取交集
    if (gantry.length > 0 && l2g.length > 0) {
      if (isArray(gantry)) {
        gantryIds = intersection(l2g, gantry);
      } else {
        gantryIds = intersection(l2g, [gantry]);
      }
      if (gantryIds.length === 0) {
        gantryIds = ['-1']; // 同时存在但是交集为空，返回空
      }
    } else {
      gantryIds = gantry && gantry.length > 0 ? gantry : l2g;
    }

    return { ...defaultQuery, ...rest, gantry: [].concat(gantryIds) };
  };

  const [{ result: namespaces = [] }] = useNamespaceList({ id_like: ROOT_NS, _limit: 1000 });
  const [{ result, loading, request = {}, total }, listTrackRecords] = useTrackRecordList(toQuery(search));
  const { _limit: limit = 10, _offset: offset = 0 } = request;
  const navigate = useNavigate();

  // 搜索触发
  const handleSearch = (values: SearchValues) => {
    setSearch({
      ...search, // 上一次留存的查询条件
      _offset: 0, // 重置分页
      ...values, // 本次输入的查询条件，取消的条件用 undefined 覆盖上一次的查询条件
    });
  };

  const handleRefresh = () => listTrackRecords(request);

  const columns = useMemo<AdColumnsType<TrackRecord>>(
    () => [
      {
        title: '车牌',
        dataIndex: 'plate',
      },
      {
        title: '过车时间',
        dataIndex: 'capAt',
        compute: ymdhms,
      },
      {
        title: '入库时间',
        dataIndex: 'createAt',
        compute: ymdhms,
        defaultHidden: true,
      },
      {
        title: '经过卡口',
        dataIndex: 'gantry',
        compute: (gantry) => gantry.name,
        filters: gantries?.map((gantry) => ({ text: gantry.name, value: gantry.id })),
        filterSearch: true,
        defaultFilteredValue: search.gantry && [].concat(search.gantry),
      },
      {
        title: '所属部门',
        key: 'ns_like',
        dataIndex: ['gantry', 'ns'],
        filters: namespaces?.map((item: Namespace) => ({ text: item.name, value: item.id })).reverse(),
        filterMultiple: false,
        compute: (val) => namespaces?.find((item) => item.id === val)?.name || '--',
        import: false,
        defaultFilteredValue: search?.ns_like && [search.ns_like],
      },
      {
        title: '来源',
        dataIndex: 'source',
      },
      {
        title: '所属执法点',
        key: 'lawEnforcePoint',
        dataIndex: ['gantry', 'lawEnforcePoint', 'name'],
        filters: allLawEnforcePoints?.map((item) => ({ text: item.name, value: item.id })),
        filterSearch: true,
        defaultFilteredValue: search.lawEnforcePoint && [].concat(search.lawEnforcePoint),
      },
      {
        title: '操作',
        valueType: 'option',
        render: (_, record) => (
          <Button
            type="link"
            style={{ padding: 0 }}
            onClick={() => {
              navigate(`${record.id}`);
            }}
          >
            详情
          </Button>
        ),
      },
    ],
    [gantries, namespaces, search, allLawEnforcePoints]
  );

  if (!namespaces || !gantries || !allLawEnforcePoints) {
    return <Spin />;
  }

  return (
    <>
      <Container
        bodyStyle={{
          padding: '12px 24px',
          marginBottom: '20px',
        }}
      >
        <SearchForm onSearch={handleSearch} initialValues={search}></SearchForm>
      </Container>
      <AdTable
        columns={columns}
        title="过车列表"
        rowKey="id"
        loading={loading}
        scroll={{ x: 'max-content' }}
        dataSource={result}
        onChange={handleSearch}
        onRefresh={handleRefresh}
        pagination={{
          total,
          current: offset / limit + 1,
          pageSize: limit,
        }}
      />
    </>
  );
};

const Container = styled(Card)`
  border: none !important;
  .ant-card-head {
    border: 0 !important;
    .ant-card-extra {
      padding: 8px 0;
    }
  }
`;

export default TrackRecordPage;
