import { Warning } from '@36node-fcp/core-sdk';
import { Space, Tag, Typography } from 'antd';
import dayjs from 'dayjs';
import L from 'leaflet';
import { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { useMap } from 'react-leaflet';

import { toIllegalLevelColor, toIllegalLevelText } from 'src/features/illegal-type';

import { useMonitorWarnings } from './monitor.provider';

type WarningContentProps = {
  warning: Warning;
};

const PopupTimeout = 1000 * 6;

const IllegalWarningContent = ({ warning }: WarningContentProps) => {
  return (
    <Space>
      <Tag color={toIllegalLevelColor(warning?.level)}>{toIllegalLevelText(warning.level)}</Tag>
      <Typography.Text strong style={{ fontSize: 14 }}>
        {warning?.plate || '人员违法'}
      </Typography.Text>
      <Typography.Text style={{ fontSize: 14 }}>{warning?.illegals[0].name}</Typography.Text>

      <Typography.Text style={{ fontSize: 14 }}>{dayjs(warning?.capAt).format('HH:mm')}</Typography.Text>
    </Space>
  );
};

const BlacklistWarningContent = ({ warning }: WarningContentProps) => {
  return (
    <Space>
      <Tag color="#727272">黑名单</Tag>
      <Typography.Text strong style={{ fontSize: 14 }}>
        {warning?.plate}
      </Typography.Text>
      <Typography.Text style={{ fontSize: 14 }}>{warning?.name}</Typography.Text>

      <Typography.Text style={{ fontSize: 14 }}>{dayjs(warning?.capAt).format('HH:mm')}</Typography.Text>
    </Space>
  );
};

const WarningPopupCard = ({ warning }: WarningContentProps) => {
  return warning.blacklist ? (
    <BlacklistWarningContent warning={warning} />
  ) : (
    <IllegalWarningContent warning={warning} />
  );
};

export function WarningLayer() {
  const map = useMap();
  const [warnings] = useMonitorWarnings();

  useEffect(() => {
    const warning = warnings.all[0];
    if (warning && map) {
      const { lat, lng } = warning.gantry?.gcj02 || {};
      if (lat && lng) {
        const div = document.createElement('div');
        ReactDOM.createRoot(div).render(<WarningPopupCard warning={warning} />);
        const popup = L.popup({
          autoClose: false,
          autoPan: false,
          closeButton: false,
          minWidth: 300,
          maxWidth: 700,
          className: 'animate__animated animate__fadeOut animate__delay-5s',
        })
          .setLatLng([lat, lng])
          .setContent(div)
          .openOn(map);
        setTimeout(() => {
          map.closePopup(popup);
        }, PopupTimeout);
      }
    }
  }, [warnings]);

  return null;
}
