import { Subject, Warning } from '@36node-fcp/core-sdk';
import { includes } from 'lodash';
import React, { createContext, useContext, useEffect } from 'react';

import { MQTT_TOPIC_NOTIFY_WARNING } from 'src/config';
import { useMqtt } from 'src/lib/react-mqtt';
import { CaseReducerActions, useSlice } from 'src/lib/react-slice';
import { readFromLocalStorage } from 'src/lib/storage';

export type GantryMapEntity = {
  gantryDevices: string[];
  codes: string[];
};

export type MonitorEntiry = {
  codes: string[];
  gantries: string[]; // 存放批量布控 gantry id
  gantryDevices: string[]; // 存放批量布控 gantry device id
  gantryMap: Record<string, GantryMapEntity>; // 单个卡口布控
};

export type MonitorState = {
  settings: {
    motor: MonitorEntiry;
    nonMotor: MonitorEntiry;
  };
  warnings: {
    all: Warning[];
    motor: Warning[];
    nonMotor: Warning[];
  };
};

/**
 * watch warning
 * 监听预警信息的 hook
 */
const MAX_WARNING_COUNT = 30;
const MONITOR_SETTTINGS_KEY = 'monitor_settings';

const initialSettings: MonitorState['settings'] = {
  motor: { codes: [], gantries: [], gantryDevices: [], gantryMap: {} },
  nonMotor: { codes: [], gantries: [], gantryDevices: [], gantryMap: {} },
};

const initMonitor = (): MonitorState => ({
  settings: readFromLocalStorage(MONITOR_SETTTINGS_KEY, initialSettings),
  warnings: {
    all: [],
    motor: [],
    nonMotor: [],
  },
});

function addToWarnings(warnings: Warning[], warning: Warning) {
  warnings.unshift(warning);
  if (warnings.length > MAX_WARNING_COUNT) {
    warnings.pop();
  }
}

function checkAndAddWarning(entiry: MonitorEntiry, typeWarnings: Warning[], warning: Warning, allWarnings: Warning[]) {
  // 批量布控命中
  if (
    entiry.gantryDevices?.includes(warning.gantryDevice) &&
    (entiry.codes?.includes(warning.code) ||
      warning.blacklist.state === 'OPEN' ||
      entiry.codes?.some((code) => warning.illegals?.map((item) => item.code).includes(code)))
  ) {
    addToWarnings(allWarnings, warning);
    return addToWarnings(typeWarnings, warning);
  }

  // 单个卡口布控命中
  const gantryMapEntities = entiry.gantryMap[warning.gantry.id];
  if (
    gantryMapEntities &&
    gantryMapEntities.gantryDevices?.includes(warning.gantryDevice) &&
    (gantryMapEntities.codes?.includes(warning.code) ||
      warning.blacklist.state === 'OPEN' ||
      gantryMapEntities.codes?.some((code) => warning.illegals?.map((item) => item.code).includes(code)))
  ) {
    addToWarnings(allWarnings, warning);
    return addToWarnings(typeWarnings, warning);
  }
}

function batchInput(entiry: MonitorEntiry) {
  if (Object.keys(entiry.gantryMap).length > 0) {
    Object.keys(entiry.gantryMap).forEach((key) => {
      if (includes(entiry.gantries, key)) {
        delete entiry.gantryMap[key];
      }
    });
  }
}

const monitorReducers = {
  set: (state: MonitorState, settings: Partial<MonitorState['settings']>) => {
    state.settings = { ...state.settings, ...settings };
  },
  reset: (state: MonitorState) => {
    state.settings = initialSettings;
  },
  setBatchGantries: (
    state: MonitorState,
    payload: {
      motor: {
        codes: string[];
        gantries: string[];
        gantryDevices: string[];
      };
      nonMotor: {
        codes: string[];
        gantries: string[];
        gantryDevices: string[];
      };
    }
  ) => {
    const { motor, nonMotor } = payload;
    state.settings.motor = { ...state.settings.motor, ...motor };
    batchInput(state.settings.motor);
    state.settings.nonMotor = { ...state.settings.nonMotor, ...nonMotor };
    batchInput(state.settings.nonMotor);
  },
  setMotorGantryMapById: (
    state: MonitorState,
    payload: { gantryId: string; codes: string[]; gantryDevices: string[]; allDevices: string[] }
  ) => {
    const { gantryId, codes = [], gantryDevices = [], allDevices = [] } = payload;
    state.settings.motor.gantryMap[gantryId] = { gantryDevices, codes };
    state.settings.motor.gantries = state.settings.motor.gantries.filter((id) => id !== gantryId);
    state.settings.motor.gantryDevices = state.settings.motor.gantryDevices.filter((id) => !allDevices.includes(id));
  },
  deleteMotorGantryMapById: (
    {
      settings: {
        motor: { gantryMap },
      },
    }: MonitorState,
    gantryId: string
  ) => {
    delete gantryMap[gantryId];
  },
  setNonMotorGantryMapById: (
    state: MonitorState,
    payload: { gantryId: string; codes: string[]; gantryDevices: string[]; allDevices: string[] }
  ) => {
    const { gantryId, codes = [], gantryDevices = [], allDevices = [] } = payload;
    state.settings.nonMotor.gantryMap[gantryId] = { gantryDevices, codes };
    state.settings.nonMotor.gantries = state.settings.nonMotor.gantries.filter((id) => id !== gantryId);
    state.settings.nonMotor.gantryDevices = state.settings.nonMotor.gantryDevices.filter(
      (id) => !allDevices.includes(id)
    );
  },
  deleteNonMotorGantryMapById: (
    {
      settings: {
        nonMotor: { gantryMap },
      },
    }: MonitorState,
    gantryId: string
  ) => {
    delete gantryMap[gantryId];
  },
  add: ({ settings: { motor, nonMotor }, warnings }: MonitorState, warning: Warning) => {
    // 机动车
    if (warning.subject === Subject.VEHICLE) {
      checkAndAddWarning(motor, warnings.motor, warning, warnings.all);
    }

    // 非机动车
    if (warning.subject === Subject.NONMOTOR || warning.subject === Subject.PERSON) {
      // 批量布控
      checkAndAddWarning(nonMotor, warnings.nonMotor, warning, warnings.all);
    }
  },
};

const MonitorSettingsContext = createContext<MonitorState['settings']>(null);
const MonitorWarningsContext = createContext<MonitorState['warnings']>(null);
const MonitorDispatchContext = createContext<CaseReducerActions<typeof monitorReducers>>(null);

export function MonitorProvider({ children }) {
  const [{ settings, warnings }, dispatch] = useSlice(monitorReducers, initMonitor());
  useMqtt(MQTT_TOPIC_NOTIFY_WARNING, { onMessage: dispatch.add });
  useEffect(() => {
    localStorage.setItem(MONITOR_SETTTINGS_KEY, JSON.stringify(settings));
  }, [settings]);

  return (
    <MonitorDispatchContext.Provider value={dispatch}>
      <MonitorSettingsContext.Provider value={settings}>
        <MonitorWarningsContext.Provider value={warnings}>{children}</MonitorWarningsContext.Provider>
      </MonitorSettingsContext.Provider>
    </MonitorDispatchContext.Provider>
  );
}

export const useMonitorSettings = () => {
  const dispatch = useContext(MonitorDispatchContext);
  const settings = useContext(MonitorSettingsContext);
  return [settings, dispatch] as const;
};

export const useMonitorWarnings = () => {
  const dispatch = useContext(MonitorDispatchContext);
  const warnings = useContext(MonitorWarningsContext);
  return [warnings, dispatch] as const;
};

export const useMonitorDispatch = () => {
  return useContext(MonitorDispatchContext);
};

export const useMonitor = () => {
  const settings = useContext(MonitorSettingsContext);
  const warnings = useContext(MonitorWarningsContext);
  const dispatch = useContext(MonitorDispatchContext);
  return [{ settings, warnings }, dispatch] as const;
};
