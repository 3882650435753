import { createContext, useContext } from 'react';

import { getTimeDistance, RangePickerValue } from 'src/components/card-range-picker';
import { ReportRequest } from 'src/features/report';
import { CaseReducerActions, useSlice } from 'src/lib/react-slice';

type VehicleState = {
  search: ReportRequest & {
    rangePickerValue: RangePickerValue;
  };
};

const initVehicleState: VehicleState = {
  search: {
    rangePickerValue: getTimeDistance('yesterday'),
    createAt_gt: getTimeDistance('yesterday')[0].toISOString(), //时间根据选择改变
    createAt_lt: getTimeDistance('yesterday')[1].toISOString(), //时间根据选择改变
  },
};

const reportReducers = {
  setDateRange: (state: VehicleState, dateRange: RangePickerValue) => {
    state.search.rangePickerValue = dateRange;
    state.search.createAt_gt = dateRange[0].startOf('day').toISOString();
    state.search.createAt_lt = dateRange[1].add(1, 'day').startOf('day').toISOString();
  },
};
const VehicleSearchContext = createContext<VehicleState['search']>(null);
const VehicleDispatchContext = createContext<CaseReducerActions<typeof reportReducers>>(null);

export const VehicleProvider = ({ children }) => {
  const [{ search }, dispatch] = useSlice(reportReducers, initVehicleState);
  return (
    <VehicleDispatchContext.Provider value={dispatch}>
      <VehicleSearchContext.Provider value={search}>{children}</VehicleSearchContext.Provider>
    </VehicleDispatchContext.Provider>
  );
};

export const useVehicleSearch = () => {
  const dispatch = useContext(VehicleDispatchContext);
  const search = useContext(VehicleSearchContext);
  return [search, dispatch] as const;
};
