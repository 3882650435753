import {
  ApiError,
  GantryDevice,
  GantryDeviceProtocolType,
  GantryDeviceState,
  GantryDeviceType,
  GetGantryDeviceRequest,
  ListGantryDevicesRequest,
} from '@36node-fcp/core-sdk';
import { Badge, Select, SelectProps } from 'antd';
import React from 'react';

import { useQuery } from 'src/lib/react-api';
import { fcp } from 'src/services';

/**
 * Api Error
 */
export const GantryDeviceApiErrorMap: { [key: string]: { [key: string]: string } } = {
  [ApiError.Code.DUPLICATE]: {
    name: '设备名称已存在',
    code: '设备编码已存在',
  },
};

/**
 * 设备状态
 */
type GantryDeviceStateUi = {
  text: string;
  color: string;
  value: string;
};

export const GantryDeviceStateUiList: GantryDeviceStateUi[] = [
  { text: '在线', color: '#25D2A0', value: GantryDeviceState.ONLINE },
  { text: '离线', color: '#B9BEBC', value: GantryDeviceState.OFFLINE },
];

export const toGantryDeviceStateText = (value: string) => {
  const item = GantryDeviceStateUiList.find((item) => item.value === value);
  return item ? item.text : '--';
};

export const toGantryDeviceStateValue = (text: string) => {
  const item = GantryDeviceStateUiList.find((item) => item.text === text);
  return item ? item.value : '--';
};

export const GantryDeviceStateBadge: React.FC<{ state: string }> = ({ state }) => {
  const ui = GantryDeviceStateUiList.find((item) => item.value === state);
  if (!ui) return null;
  return <Badge color={ui.color} text={ui.text} />;
};

export function GantryDeviceStateSelect(props: SelectProps) {
  return (
    <Select {...props}>
      {GantryDeviceStateUiList.map((item) => (
        <Select.Option key={item.value} value={item.value}>
          <Badge color={item.color} text={item.text} />
        </Select.Option>
      ))}
    </Select>
  );
}

/**
 * 获取单个设备
 * @param gantryDeviceId 设备id
 * @returns [apiState, getGantryDevice]
 */
export const useGantryDevice = (gantryDeviceId: string) => {
  const [state, getGantryDevice] = useQuery(fcp.getGantryDevice, { gantryDeviceId });
  return [state, getGantryDevice] as const;
};

/**
 * Device Provider
 * 方便直接使用设备
 */
export const GantryProvider: React.FC<{
  id: string;
  children: (gantry: GantryDevice | undefined) => any;
}> = ({ id, children }) => {
  const [{ result }] = useGantryDevice(id);
  return children(result);
};

/**
 * 获取设备列表
 * @param req 设备列表请求参数
 * @returns [state, listGantryDevices]
 */
export const useGantryDeviceList = (req?: ListGantryDevicesRequest) => {
  const [state, listGantryDevices] = useQuery(fcp.listGantryDevices, req || {});
  return [state, listGantryDevices] as const;
};

export const useGetGantryDevice = (req?: GetGantryDeviceRequest) => {
  const [state, getGantryDevice] = useQuery(fcp.getGantryDevice, req || {});
  return [state, getGantryDevice] as const;
};
/**
 * 协议类型
 */
export const ProtocolTypeUiList = [
  { text: '大华', value: GantryDeviceProtocolType.DAHUA },
  { text: '海康', value: GantryDeviceProtocolType.HAIKANG },
  { text: '直连', value: GantryDeviceProtocolType.DIRECT },
];

export const ProtocolTypeTextList = ProtocolTypeUiList.map((item) => item.text);
export const toProtocolTypeText = (value: string) => ProtocolTypeUiList.find((item) => item.value === value)?.text;
export const toProtocolTypeValue = (text: string) => ProtocolTypeUiList.find((item) => item.text === text)?.value;

export function ProtocolTypeSelect(props: SelectProps) {
  return (
    <Select {...props}>
      {ProtocolTypeUiList.map((item) => (
        <Select.Option key={item.value} value={item.value}>
          {item.text}
        </Select.Option>
      ))}
    </Select>
  );
}

/**
 * 厂商
 */
export const ProductUiList = [
  { text: '大华', value: 'DAHUA' },
  { text: '海康', value: 'HAIKANG' },
];

export const ProductTextList = ProductUiList.map((item) => item.text);
export const toProductText = (value: string) => ProductUiList.find((item) => item.value === value)?.text;
export const toProductValue = (text: string) => ProductUiList.find((item) => item.text === text)?.value;

export function ProductSelect(props: SelectProps) {
  return (
    <Select {...props}>
      {ProductUiList.map((item) => (
        <Select.Option key={item.value} value={item.value}>
          {item.text}
        </Select.Option>
      ))}
    </Select>
  );
}

/**
 * 设备大类
 */
export const DeviceTypeUiList = [{ text: '卡口相机', value: GantryDeviceType.CAMERA }];
export const DeviceTypeTextList = DeviceTypeUiList.map((item) => item.text);
export const toDeviceTypeText = (value: string) => DeviceTypeUiList.find((item) => item.value === value)?.text;
export const toDeviceTypeValue = (text: string) => DeviceTypeUiList.find((item) => item.text === text)?.value;

export function DeviceTypeSelect(props: SelectProps) {
  return (
    <Select {...props}>
      {DeviceTypeUiList.map((item) => (
        <Select.Option key={item.value} value={item.value}>
          {item.text}
        </Select.Option>
      ))}
    </Select>
  );
}

/**
 * 抓拍相机下拉筛选组件
 * @param props [SelectProps] 组件参数
 * @returns 组件
 */
export function GantryDeviceSelect(props: SelectProps) {
  const [{ result: gantryDevices = [] }] = useGantryDeviceList({ _limit: 1000 });
  return (
    <Select
      placeholder="卡口设备"
      {...props}
      filterOption={(input, option) => option?.item.name.toLowerCase().includes(input.toLowerCase())}
      popupMatchSelectWidth={false}
    >
      {gantryDevices.map((item) => (
        <Select.Option key={item.id} value={item.id} item={item}>
          {item.name}
        </Select.Option>
      ))}
    </Select>
  );
}
