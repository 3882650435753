import { ApiError, BlacklistState, GetBlacklistRequest, ListBlacklistsRequest } from '@36node-fcp/core-sdk';
import { Badge, Tag } from 'antd';

import { useQuery } from 'src/lib/react-api';
import { fcp } from 'src/services';

/**
 * Api Error
 */

export const BlacklistApiErrorMap: { [key: string]: { [key: string]: string } } = {
  [ApiError.Code.DUPLICATE]: {
    'plate,plateType': '车牌和号牌种类组合已存在',
  },
};

/**
 * 车辆黑名单状态
 */
type VehicleBlacklistStateUi = {
  text: string;
  color: string;
  value: string;
};

export const VehicleBlacklistStateUiList: VehicleBlacklistStateUi[] = [
  { text: '生效中', color: '#25D2A0', value: BlacklistState.OPEN },
  { text: '失效', color: '#FAAD14', value: BlacklistState.CLOSED },
];

export const VehicleBlacklistStateFilter = VehicleBlacklistStateUiList;
export const toVehicleBlacklistStateText = (value: string) =>
  VehicleBlacklistStateUiList.find((item) => item.value === value)?.text;
export const toVehicleBlacklistStateValue = (text: string) =>
  VehicleBlacklistStateUiList.find((item) => item.text === text)?.value;

export const VehicleBlacklistStateBadge: React.FC<{ state: string }> = ({ state }) => {
  const ui = VehicleBlacklistStateUiList.find((item) => item.value === state);
  if (!ui) return null;
  return <Badge color={ui.color} text={ui.text} />;
};

/**
 * 获取车辆黑名单列表
 * @param req 车辆黑名单列表请求参数
 * @returns [apiState, actions]
 */
export const useVehicleBlacklist = (req?: ListBlacklistsRequest) => {
  const [state, listBlacklists] = useQuery(fcp.listBlacklists, req || {});
  return [state, listBlacklists] as const;
};

export const useBlacklistRecord = (req?: GetBlacklistRequest) => {
  const [state, getBlacklistRecord] = useQuery(fcp.getBlacklist, req);
  return [state, getBlacklistRecord] as const;
};

export const toBlacklistVehicleState = (state: BlacklistState) =>
  state === BlacklistState.OPEN ? <Tag color="success">生效中</Tag> : <Tag color="default">已失效</Tag>;
