import { AggregateTrackRecordsRequest, GetTrackRecordRequest, ListTrackRecordsRequest } from '@36node-fcp/core-sdk';

import { useQuery } from 'src/lib/react-api';
import { fcp } from 'src/services';

/**
 * 获取路段管理列表
 * @param req 路段管理列表请求参数
 * @returns [apiState, actions]
 */
export const useTrackRecordList = (req?: ListTrackRecordsRequest) => {
  const [state, listTrackRecords] = useQuery(fcp.listTrackRecords, req || {});
  return [state, listTrackRecords] as const;
};

export const useTrackRecord = (req?: GetTrackRecordRequest) => {
  const [state, getTrackRecord] = useQuery(fcp.getTrackRecord, req);
  return [state, getTrackRecord] as const;
};

/**
 * 获取过车记录统计
 */
export const useTrackRecordAggregation = (req: AggregateTrackRecordsRequest) => {
  const [state, aggregateTrackRecords] = useQuery(fcp.aggregateTrackRecords, req || {});
  return [state, aggregateTrackRecords] as const;
};
