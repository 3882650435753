import { ListIllegalDraftsRequest } from '@36node-fcp/core-sdk';
import { Select, SelectProps } from 'antd';

import { useQuery } from 'src/lib/react-api';
import { fcp } from 'src/services';

/**
 * 获取违法草稿列表
 * @param req 违法草稿列表请求参数
 * @returns [state, listIllegalDrafts]
 */
export const useIllegalDraftList = (req: ListIllegalDraftsRequest) => {
  const [state, listIllegalDrafts] = useQuery(fcp.listIllegalDrafts, req || {});
  return [state, listIllegalDrafts] as const;
};

export const genSelectItems = [
  { text: '是', value: true },
  { text: '否', value: false },
];

export function GenDraftSelect({ ...props }: SelectProps) {
  return (
    <Select {...props}>
      {genSelectItems.map((item) => (
        <Select.Option key={item.text} value={item.value}>
          {item.text}
        </Select.Option>
      ))}
    </Select>
  );
}
