import { LawEnforcePoint, Subject } from '@36node-fcp/core-sdk';
import { concat } from 'lodash';
import { useMemo } from 'react';

import { AdColumnsType, AdTable } from 'src/components/antd/ad-table';
import { ExportModal } from 'src/components/xlsx-export-modal';
import { buildListForLawEnforcePointReport, LawEnforcePointReportItem, useSummaryReport } from 'src/features/report';
import { useSlice } from 'src/lib/react-slice';

import { useReportSearch } from './report.context';
import SearchForm from './search.form';

type State = {
  exportVisible: boolean;
};

const initState: State = {
  exportVisible: false,
};
const reducers = {
  openExport(state: State) {
    state.exportVisible = true;
  },
  closeExport(state: State) {
    state.exportVisible = false;
  },
};

/**
 * 执法点报表
 */
const LawEnforcePointReport: React.FC = () => {
  const [{ exportVisible }, dispatch] = useSlice(reducers, initState);
  const [search] = useReportSearch();
  const [{ loading, result }] = useSummaryReport(search);
  const list = useMemo(() => buildListForLawEnforcePointReport(result), [result]);

  const columns = useMemo<AdColumnsType<LawEnforcePointReportItem>>(() => {
    const columnsPart1 = [
      {
        title: '执法点名',
        dataIndex: 'lawEnforcePoint',
        compute: (val: LawEnforcePoint) => val.name,
      },
    ];
    const columnsPart2 = [
      {
        title: '违法数',
        dataIndex: 'illegalCount',
      },
      {
        title: '预警数',
        dataIndex: 'warningCount',
      },
      {
        title: '查处数',
        dataIndex: 'warningCloseCount',
      },
    ];
    return search.subject.includes(Subject.VEHICLE)
      ? concat(
          columnsPart1,
          [
            {
              title: '过车数',
              dataIndex: 'trackCount',
            },
          ],
          columnsPart2
        )
      : concat(columnsPart1, columnsPart2);
  }, [search]);

  return (
    <>
      <AdTable
        bordered
        columns={columns}
        title={<div>按执法点（{list.length}）</div>}
        rowKey="uniqKey"
        loading={loading}
        scroll={{ x: 'max-content' }}
        dataSource={list}
        showColumnsFilter={false}
        pagination={false}
        onExport={dispatch.openExport}
        extraTools={<SearchForm />}
      />
      {exportVisible && (
        <ExportModal
          dataSource={list}
          columns={columns}
          filename="执法点报表.xlsx"
          onClose={dispatch.closeExport}
          title="执法点报表"
          total={list.length}
        />
      )}
    </>
  );
};

export default LawEnforcePointReport;
