import { Button, Divider, Form, Input, message, Popconfirm } from 'antd';
import { max, slice } from 'lodash';
import { useEffect, useMemo } from 'react';

import { AdColumnsType, AdTable } from 'src/components/antd/ad-table';
import { ROOT_NS } from 'src/config';
import { useRoleList } from 'src/features/roles';
import { useSearch } from 'src/hook/search';
import { ymdhms } from 'src/lib/lang/time';
import { useApi } from 'src/lib/react-api';
import { useSlice } from 'src/lib/react-slice';
import { ListScopeRolesRequest, ScopeRole } from 'src/sdk';
import { stargate } from 'src/services';

import { RoleEditor } from './roles.editor';

type SearchValues = { name_like?: string } & ListScopeRolesRequest;
type SearchFormProps = {
  onSearch: (values: SearchValues) => void;
  initialValues?: SearchValues;
};
const defaultLimit = 10;
const defaultQuery: SearchValues = { _limit: defaultLimit, _offset: 0 };
const defaultFetchQuery: ListScopeRolesRequest = { _limit: 1000, _offset: 0, scopeId: ROOT_NS };

const SearchForm: React.FC<SearchFormProps> = ({ onSearch, initialValues }) => {
  return (
    <Form onFinish={onSearch} initialValues={initialValues} layout={'inline'}>
      <Form.Item name="name_like">
        <Input placeholder="角色名查询" allowClear />
      </Form.Item>
    </Form>
  );
};

type State = {
  editorVisible: boolean;
  editRole?: ScopeRole;
  data: ScopeRole[];
  total: number;
};

const initState: State = {
  editorVisible: false,
  data: [],
  total: 0,
};

const reducers = {
  openEditor(state: State, role?: ScopeRole) {
    state.editorVisible = true;
    state.editRole = role;
  },
  closeEditor(state: State) {
    state.editorVisible = false;
    state.editRole = undefined;
  },
  setData(state: State, roles: ScopeRole[]) {
    state.data = roles;
  },
  setTotal(state: State, total: number) {
    state.total = total;
  },
};

/**
 * 角色管理页面
 */
const RolePage: React.FC = () => {
  const [query, setQuery] = useSearch(defaultQuery);
  const [{ result, loading }, listRoles] = useRoleList(defaultFetchQuery);
  const validResult = useMemo(() => {
    if (!result) return [];
    return result.filter((item) => !['USERMANAGER', 'USER'].includes(item.type));
  }, [result]);
  const [{ editorVisible, editRole, data, total }, dispatch] = useSlice(reducers, initState);

  const deleteOptions = useMemo(
    () => ({
      onSuccess: () => {
        message.success('删除成功');
        if (!data.length) {
          setQuery({ ...query, _offset: max([0, query._offset - query._limit]) });
        }
        listRoles(defaultFetchQuery);
      },
      onFailure: (err) => {
        message.error(`删除失败: ${err.message}`);
      },
    }),
    [query, data]
  );
  const [deleteState, deleteScopeRole] = useApi(stargate.deleteScopeRole, deleteOptions);

  // 搜索、分页、排序触发
  const handleSearch = (values?: SearchValues) => {
    const _query = {
      ...query,
      _offset: 0, // 重置页码
      ...values, // 本次输入的查询条件，取消的条件用 undefined 覆盖上一次的查询条件
    };
    setQuery(_query);
    const _data = _query.name_like ? validResult.filter((item) => item.name.includes(_query.name_like)) : validResult;
    dispatch.setData(slice(_data, _query._offset, _query._offset + _query._limit));
    dispatch.setTotal(_data.length);
  };

  const handleRefresh = () => listRoles(defaultFetchQuery);

  const columns = useMemo<AdColumnsType<ScopeRole>>(
    () => [
      {
        title: '角色名',
        dataIndex: 'name',
        rules: [{ required: true, message: '请填写违法名称' }],
      },
      {
        title: '创建时间',
        dataIndex: 'createAt',
        compute: ymdhms,
      },
      {
        title: '操作',
        render: (node, record) => (
          <>
            <Button type="link" style={{ padding: 0 }} onClick={() => dispatch.openEditor(record)}>
              编辑
            </Button>
            <Divider type="vertical" />
            <Popconfirm
              title="你确定要删除这行内容吗？"
              onConfirm={() => deleteScopeRole({ roleId: record.id, scopeId: ROOT_NS })}
            >
              <Button type="link" danger style={{ padding: 0 }} loading={deleteState.loading}>
                删除
              </Button>
            </Popconfirm>
          </>
        ),
      },
    ],
    [dispatch, deleteScopeRole]
  );

  useEffect(() => {
    const _data = query.name_like ? validResult.filter((item) => item.name.includes(query.name_like)) : validResult;
    dispatch.setData(slice(_data, query._offset ?? 0, (query._offset ?? 0) + (query._limit ?? defaultLimit)));
    dispatch.setTotal(_data.length);
  }, [validResult, dispatch]);

  return (
    <>
      <AdTable
        columns={columns}
        title="角色管理"
        rowKey="id"
        loading={loading}
        scroll={{ x: 'max-content' }}
        dataSource={data}
        onAddNew={() => dispatch.openEditor()}
        onChange={handleSearch}
        onRefresh={handleRefresh}
        pagination={{
          total,
          current: (query._offset ?? 0) / (query._limit ?? defaultLimit) + 1,
          pageSize: query._limit ?? defaultLimit,
        }}
        extraTools={<SearchForm onSearch={handleSearch} initialValues={query} />}
      />
      {editorVisible && <RoleEditor onClose={dispatch.closeEditor} onFinish={handleRefresh} role={editRole} />}
    </>
  );
};

export default RolePage;
