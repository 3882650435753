import { Gantry } from '@36node-fcp/core-sdk';
import { useEffect } from 'react';
import { useMap } from 'react-leaflet';

import { MAP_MAX_ZOOM } from 'src/config';
import { useGantryList } from 'src/features/gantry';
import { GantryMarker } from 'src/features/map';

import { useMonitorSettings } from './monitor.provider';

type MarkerLayerProps = {
  searchGantryId: string;
  onClick?: (gantry: Gantry) => void;
};
export function MarkerLayer({ searchGantryId, onClick }: MarkerLayerProps) {
  const map = useMap();
  const [settings] = useMonitorSettings();
  const [{ result: gantries = [] }] = useGantryList({ _limit: 1000 });
  const motorKeys = Object.keys(settings.motor.gantryMap);
  const nonMotorKeys = Object.keys(settings.nonMotor.gantryMap);
  const watchedGantries = motorKeys.concat(nonMotorKeys, settings.motor.gantries, settings.nonMotor.gantries);

  useEffect(() => {
    if (searchGantryId && gantries.length && map) {
      const searchGantry = gantries.find((item) => item.id === searchGantryId);
      const { lat, lng } = searchGantry;
      if (lat && lng) map.flyTo([lat, lng], MAP_MAX_ZOOM);
    }
  }, [searchGantryId, gantries]);

  return (
    <>
      {gantries.map((gantry, index) => (
        <GantryMarker
          watched={watchedGantries.includes(gantry.id)}
          key={gantry.id}
          gantry={gantry}
          active={gantry.id === searchGantryId}
          onClick={onClick}
        />
      ))}
    </>
  );
}
