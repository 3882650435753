/**
 * init dotenv
 *
 * .env: Default.
 * .env.local: Local overrides. This file is loaded for all environments except test.
 * .env.development, .env.test, .env.production: Environment-specific settings.
 * .env.development.local, .env.test.local, .env.production.local: Local overrides of environment-specific settings.
 *
 * Available settings from baseimage
 *
 * REACT_APP_TITLE='React App'
 */

/**
 *
 * @param {string} name envrionment name
 * @param {string} init default value
 * @returns {string} value
 */
function env(name: string, init: string): string {
  const key = `REACT_APP_${name.toUpperCase()}`;
  const buildtimeValue = process.env?.[key];

  //@ts-ignore
  const runtimeValue = window._runtime_?.[key];
  const value = runtimeValue || buildtimeValue || init;

  if (value === undefined) {
    throw new Error(`environment ${name} is missing`);
  }

  return value.replace('%host%', window.location.hostname);
}

/**
 * chore
 */
export const VERSION = env('VERSION', '3.0.0');
export const BUILD = env('BUILD', '20231017-000000');
export const COPYRIGHT = env('COPYRIGHT', '36node 出品');

/**
 * app
 */
export const TITLE = env('TITLE', '违法智管系统');
export const VEHICLE_ONLY = env('VEHICLE_ONLY', 'off') === 'on';

/**
 * api
 */
export const ROOT_NS = env('ROOT_NS', 'fcp_uat');
export const AUTH_ENDPOINT = env('AUTH_ENDPOINT', 'https://fcp-uat.36node.com/auth/v1');
export const AUTH_PROVIDER = env('AUTH_PROVIDER', '6214dd09a1896600127d9541');
export const FCP_ENDPOINT = env('FCP_ENDPOINT', 'https://fcp-uat.36node.com/api');
export const ILLEGAL_EXPIRED_DAYS = parseInt(env('ILLEGAL_EXPIRED_DAYS', '3'), 10); // 违法过期天数
export const DRAFT_EXPIRED_DAYS = parseInt(env('DRAFT_EXPIRED_DAYS', '2'), 10); // 违法过期天数

/**
 * login
 */
// 登录失败后锁定的时间，单位为秒
export const LOGIN_FAIL_LOCK_SECONDS = parseInt(env('LOGIN_FAIL_LOCK_SECONDS', `${5 * 60}`), 10);

/**
 * oss
 */
//minio
//OSS_FORCE_PATH_STYLE false会将bucket放到endpoint前拼接，然后发起请求，目前阿里云支持拼接，minio禁止拼接，天翼云均支持
export const OSS_FORCE_PATH_STYLE = env('OSS_FORCE_PATH_STYLE', 'on') === 'on';
export const OSS_DOMAIN = env('OSS_DOMAIN', 'https://minio-api.z-ctyun-stage.36node.com/fcp-uat');
export const OSS_ENDPOINT = env('OSS_ENDPOINT', 'https://minio-api.z-ctyun-stage.36node.com');
export const ACCESS_KEY_ID = env('ACCESS_KEY_ID', 'ITBU0AKY4PXNRYLX737Z');
export const ACCESS_KEY_SECRET = env('ACCESS_KEY_SECRET', 'VshvRgdEAylGSL6f3CHyXUyz1LfC656KYRuqLKzI');
export const FILE_BUCKET = env('FILE_BUCKET', 'fcp-uat');
export const OSS_REGION = env('OSS_REGION', 'cn');

//aliyun
// export const OSS_DOMAIN = env('OSS_DOMAIN', 'https://fcp-uat.oss-cn-hangzhou.aliyuncs.com');
// export const OSS_ENDPOINT = env('OSS_ENDPOINT', 'https://oss-cn-hangzhou.aliyuncs.com');
// export const ACCESS_KEY_ID = env('ACCESS_KEY_ID', 'LTAI5tQUtwXdm6mKz9iyZiAP');
// export const ACCESS_KEY_SECRET = env('ACCESS_KEY_SECRET', 'ctg5BHAAKltbYf666q0z4HS2SFxXx0');
// export const FILE_BUCKET = env('FILE_BUCKET', 'fcp-uat');
// export const IMAGE_DIR = env('IMAGE_DIR', 'rd');

/**
 * mqtt
 */
export const MQTT_URL = env('MQTT_URL', 'ws://emqx.z-ctyun-stage.36node.com:8083/mqtt');
export const MQTT_TOPIC_NOTIFY_WARNING = env('MQTT_TOPIC_NOTIFY_WARNING', 'fcp/notify/warning/#');

/**
 * 地图监控
 */
export const MAP_BOUND_SOUTH_WEST = env('MAP_BOUND_SOUTH_WEST', '28.92927024,122.31013782')
  .split(',')
  .map(parseFloat) as [number, number];
export const MAP_BOUND_NORTH_EAST = env('MAP_BOUND_NORTH_EAST', '30.7184163,120.47437365')
  .split(',')
  .map(parseFloat) as [number, number];
export const MAP_CENTER = env('MAP_CENTER', '29.85645453,121.51695313').split(',').map(parseFloat) as [number, number];
export const MAP_CURRENT_ZOOM = parseInt(env('MAP_CURRENT_ZOOM', '11'), 10); // 当前缩放
export const MAP_MIN_ZOOM = parseInt(env('MAP_MIN_ZOOM', '11'), 10); // 最小缩放
export const MAP_MAX_ZOOM = parseInt(env('MAP_MAX_ZOOM', '17'), 10); // 最大缩放
export const MAP_GANTRY_MIN_ZOOM = parseInt(env('MAP_GANTRY_MIN_ZOOM', '10'), 10); // 大于等于该zoom值之后，才会显示普通卡口
export const MAP_ENDPOINT = env('MAP_ENDPOINT', 'http://fcp-uat.36node.com/map/local/tiles');

// 客户端下载地址
export const APP_DOWNLOAD_URL = env('APP_DOWNLOAD_URL', 'https://minio-api.z-ctyun-stage.36node.com/test1/xftp.rar');

/** eslint-disabled */
console.log('TITLE', TITLE);
console.log('ROOT_NS', ROOT_NS);
console.log('AUTH_ENDPOINT', AUTH_ENDPOINT);
console.log('AUTH_PROVIDER', AUTH_PROVIDER);
console.log('ROOT_NS', ROOT_NS);
console.log('FCP_ENDPOINT', FCP_ENDPOINT);
/** eslint-disabled */
