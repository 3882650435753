import { PlusOutlined } from '@ant-design/icons';
import { message, Modal, Upload } from 'antd';
import type { RcFile, UploadProps } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';
import React, { useEffect, useState } from 'react';

import { getFileNameUUID, getFileSuffix, mutiload } from 'src/lib/s3';

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const beforeUpload = (file: RcFile) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('请上传JPG或者PNG格式的图片！');
  }
  const isLt20M = file.size / 1024 / 1024 < 20;
  if (!isLt20M) {
    message.error('图片大小不能超过20MB！');
  }
  return isJpgOrPng && isLt20M ? true : Upload.LIST_IGNORE; // 文件不符合要求时，不需要在文件列表中展示
};

interface UploaderProp {
  setImages: (urls: string[]) => void;
  initImages?: string[];
}

export function Uploader({ setImages, initImages }: UploaderProp) {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState<UploadFile[]>(() => {
    let imageList: UploadFile[] = [];
    if (initImages && initImages.length > 0) {
      initImages.forEach((img, i) => {
        const image: UploadFile = {
          uid: i.toString(),
          name: '处置图片' + i,
          status: 'done',
          url: img,
        };
        imageList.push(image);
      });
    }
    return imageList;
  });

  useEffect(() => {
    setImages(
      fileList.map((f) => {
        if (f.url !== undefined) {
          return f.url;
        }
        return '';
      })
    );
  }, [fileList]);

  const customRequest = async (options: any) => {
    const { file, onSuccess, onProgress, onError } = options;
    // 生成的文件名，保留文件后缀名，进行拼接
    const objName = getFileNameUUID() + getFileSuffix(file.name);

    // // 调用上传文件方法,flieName是存放的文件夹名称，可自己定义
    const res = await mutiload(`${objName}`, file, onProgress);
    if (!res.errCode) {
      file.url = res.url;
      onSuccess(res, file);
    } else {
      onError(res.error);
    }
  };

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewVisible(true);
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
  };

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>添加</div>
    </div>
  );
  return (
    <>
      <Upload
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        beforeUpload={beforeUpload}
        customRequest={customRequest}
        accept=".png, .jpg, .jpeg"
      >
        {fileList.length >= 8 ? null : uploadButton}
      </Upload>
      <Modal open={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  );
}
