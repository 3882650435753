import { Modal } from 'antd';
import { slice } from 'lodash';
import { useEffect } from 'react';
import { styled } from 'styled-components';

import { useSlice } from 'src/lib/react-slice';
import { PreviewStatus, usePreview } from 'src/lib/react-xlsx';

import { AdTable } from '../antd/ad-table';

type ModalPreviewProps = {
  url: string;
  onClose: () => void;
  title?: string;
  width?: number;
  columns?: any[];
};

function getLoading(status: PreviewStatus) {
  switch (status) {
    case PreviewStatus.FETCHING:
    case PreviewStatus.CONVERTING:
      return true;
    default:
      return false;
  }
}

type State = {
  data: any[];
  total: number;
  query: any;
};

const initState: State = {
  data: [],
  total: 0,
  query: {},
};

const reducers = {
  setData(state: State, rows: any[]) {
    state.data = rows;
  },
  setTotal(state: State, total: number) {
    state.total = total;
  },
  setQuery(state: State, query: any) {
    state.query = query;
  },
};

const defaultLimit = 20;

export function XLSXPreviewModal(props: ModalPreviewProps) {
  const { url, onClose, title, width, columns } = props;
  const [{ status, error, result }] = usePreview(url, columns);
  const [{ data, total, query }, dispatch] = useSlice(reducers, initState);

  const handleSearch = (values?: any) => {
    const _query = {
      ...query,
      _offset: 0,
      ...values,
    };
    dispatch.setQuery(_query);
    dispatch.setData(slice(result, _query._offset, _query._offset + _query._limit));
  };

  useEffect(() => {
    if (result) {
      dispatch.setData(slice(result, query._offset ?? 0, (query._offset ?? 0) + (query._limit ?? defaultLimit)));
      dispatch.setTotal(result.length);
    }
  }, [result, dispatch]);

  return (
    <Modal
      open
      onCancel={onClose}
      confirmLoading={getLoading(status)}
      width={width}
      maskClosable={false}
      footer={false}
    >
      <Container>
        {error && <p style={{ color: 'red' }}>{error?.message || '预览失败'}</p>}
        <AdTable
          title={title}
          columns={columns}
          loading={getLoading(status)}
          scroll={{ x: 'max-content', y: 400 }}
          dataSource={data}
          onChange={handleSearch}
          rowKey="key"
          pagination={{
            total,
            current: (query._offset ?? 0) / (query._limit ?? defaultLimit) + 1,
            pageSize: query._limit ?? defaultLimit,
          }}
          showColumnsFilter={false}
          size="small"
        />
      </Container>
    </Modal>
  );
}

const Container = styled.div`
  padding: 24px 0;
`;
