import { AbortMultipartUploadCommandOutput, CompleteMultipartUploadCommandOutput, S3Client } from '@aws-sdk/client-s3';
import { Upload as s3Upload } from '@aws-sdk/lib-storage';

import * as config from 'src/config';

/** 阿里云配置
 *  [accessKeyId] {String}：通过阿里云控制台创建的AccessKey。
 *  [accessKeySecret] {String}：通过阿里云控制台创建的AccessSecret。
 *  [bucket] {String}：通过控制台或PutBucket创建的bucket。
 *  [region] {String}：bucket所在的区域， 默认oss-cn-hangzhou。
 */

// const client = new S3Client({
//   endpoint: `${config.OSS_ENDPOINT}`,
//   credentials: {
//     accessKeyId: `${config.ASSCESS_KEY_ID}`,
//     secretAccessKey: `${config.ASSCESS_KEY_SECRET}`,
//   },
//   region: 'oss-cn-shanghai',
// });

/** minio配置
 * [forcePathStyle] {Boolean}：是否强制使用路径模式， 必须设置成true。
 *  [accessKeyId] {String}：通过minio控制台创建的AccessKey。
 *  [accessKeySecret] {String}：通过minio控制台创建的AccessSecret。
 *  [bucket] {String}：通过控制台或PutBucket创建的bucket。
 *  [region] {String}：bucket所在的区域。
 */

const client = new S3Client({
  forcePathStyle: `${config.OSS_FORCE_PATH_STYLE}` === 'true' ? true : false,

  endpoint: `${config.OSS_ENDPOINT}`,
  credentials: {
    accessKeyId: `${config.ACCESS_KEY_ID}`,
    secretAccessKey: `${config.ACCESS_KEY_SECRET}`,
  },
  region: `${config.OSS_REGION}`,
});

const setContentType = (type: string) => {
  if (type.indexOf('.bmp') !== -1) {
    return 'image/bmp';
  } else if (type.indexOf('.gif') !== -1) {
    return 'image/gif';
  } else if (type.indexOf('.jpeg') !== -1 || type.indexOf('.jpg') !== -1 || type.indexOf('.png') !== -1) {
    return 'image/jpg';
  }
  return 'application/octet-stream';
};
/**
 *  上传文件，大小不能超过10M
 * @param {string} ObjName OSS的储存路径和文件名字
 * @param {string} fileUrl 本地文件
 * @retruns Promise
 */

export interface MutiResult {
  errCode: number;
  s3Result?: AbortMultipartUploadCommandOutput | CompleteMultipartUploadCommandOutput;
  url?: string;
  error?: any;
}

export const mutiload = async (ObjName: string, file: any, onProgress: any): Promise<MutiResult> => {
  try {
    const parallelUploads3 = new s3Upload({
      client: client,
      queueSize: 4,
      partSize: 1024 * 1024 * 5,
      leavePartsOnError: false,
      params: {
        Bucket: `${config.FILE_BUCKET}`,
        Key: ObjName,
        Body: file,
        ContentType: setContentType(ObjName),
      },
    });

    parallelUploads3.on('httpUploadProgress', (progress: any) => {
      // 统计上传进度
      onProgress({ percent: Math.round((progress.loaded / progress.total) * 100).toFixed(2) });
    });

    const res = await parallelUploads3.done();
    return {
      ...res,
      url: `${config.OSS_DOMAIN}/${ObjName}`,
      errCode: 0,
    };
  } catch (error) {
    return {
      errCode: 1,
      error,
    };
  }
};

/**
 * 生成随机文件名称
 * 规则八位随机字符，加下划线连接时间戳
 */
export const getFileNameUUID = () => {
  function rx() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
  return `${+new Date()}_${rx()}${rx()}`;
};

/**
 * 获取文件的后缀名
 */

export const getFileSuffix = (fileName: string) => {
  var obj = fileName;
  var index = obj.lastIndexOf('.');
  return obj.substring(index, obj.length);
};
