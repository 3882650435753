import { Dispatch } from 'react';

import { kindOf } from 'src/lib/lang/tsHelpers';

import { PayloadAction, PayloadActionCreator } from './action';

/**
 * Object whose values are action creator functions.
 */
export interface ActionCreatorsMapObject {
  [key: string]: PayloadActionCreator<any>;
}

export function bindActionCreator<P = void>(
  actionCreator: PayloadActionCreator<P>,
  dispatch: Dispatch<PayloadAction<P>>
): PayloadActionCreator<P> {
  const ac = function (payload: P) {
    const a = actionCreator(payload);
    dispatch(a);
    return a;
  };
  ac.type = actionCreator.type;
  ac.toString = actionCreator.toString;
  ac.match = actionCreator.match;
  return ac as any;
}

export default function bindActionCreators<M extends ActionCreatorsMapObject>(
  actionCreators: M,
  dispatch: Dispatch<PayloadAction>
): ActionCreatorsMapObject {
  if (typeof actionCreators !== 'object' || actionCreators === null) {
    throw new Error(
      `bindActionCreators expected an object or a function, but instead received: '${kindOf(actionCreators)}'. ` +
        `Did you write "import ActionCreators from" instead of "import * as ActionCreators from"?`
    );
  }

  const boundActionCreators: ActionCreatorsMapObject = {};
  for (const key in actionCreators) {
    const actionCreator = actionCreators[key];
    if (typeof actionCreator === 'function') {
      boundActionCreators[key] = bindActionCreator(actionCreator, dispatch);
    }
  }
  return boundActionCreators;
}
