import { ApiError, ListLawEnforcePointsRequest } from '@36node-fcp/core-sdk';
import { Select, SelectProps } from 'antd';

import { useQuery } from 'src/lib/react-api';
import { fcp } from 'src/services';

/**
 * Api Error
 */
export const LawEnforcePointApiErrorMap: { [key: string]: { [key: string]: string } } = {
  [ApiError.Code.DUPLICATE]: {
    code: '执法点编号已存在',
    name: '执法点名已存在',
  },
};

/**
 * 获取违法记录列表
 * @param req 违法记录列表请求参数
 * @returns [apiState, actions]
 */
export const useLawEnforcePointList = (req?: ListLawEnforcePointsRequest) => {
  const [state, listLawEnforcePoints] = useQuery(fcp.listLawEnforcePoints, req || {});
  return [state, listLawEnforcePoints] as const;
};

export function LawEnforcePointSelect(props: SelectProps) {
  const [{ result: lawEnforcePoints = [] }] = useLawEnforcePointList({ _limit: 1000 });

  return (
    <Select {...props} placeholder="请选择所属执法点">
      {lawEnforcePoints?.map((item) => (
        <Select.Option key={item.id} value={item.id}>
          {item.name}
        </Select.Option>
      ))}
    </Select>
  );
}
