import { WarningOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, Row, Space, Spin, Statistic } from 'antd';
import { groupBy } from 'lodash';
import { useState } from 'react';
import CountUp from 'react-countup';
import { styled } from 'styled-components';

import { HealthStat, HealthStatEntity, HealthStatType, HealthStatus } from 'src/sdk/robot-sdk';

type RobotHealthPageProps = {
  healthStat: HealthStat;
};

const formatter = (value: number) => <CountUp end={value} separator="," />;

export const RobotHealthPage: React.FC<RobotHealthPageProps> = (props) => {
  const { healthStat } = props;
  const healthByCategory = groupBy(healthStat?.warnings, (item) => item?.type);
  const [illegalShowCount, setIllegalShowCount] = useState(10);
  const [isIllegalShowMore, setIsIllegalShowMore] = useState(false);
  const [trackShowCount, setTrackShowCount] = useState(10);
  const [isTrackShowMore, setIsTrackShowMore] = useState(false);
  const [warningShowCount, setWarningShowCount] = useState(10);
  const [isWarningShowMore, setIsWarningShowMore] = useState(false);
  const [errorShowCount, setErrorShowCount] = useState(10);
  const [isErrorShowMore, setIsErrorShowMore] = useState(false);

  if (!healthStat) return <Spin />;

  const handleIllegalShow = (count) => {
    setIsIllegalShowMore(!isIllegalShowMore);
    setIllegalShowCount(count);
  };

  const handleTrackShow = (count) => {
    setIsTrackShowMore(!isTrackShowMore);
    setTrackShowCount(count);
  };

  const handleWarningShow = (count) => {
    setIsWarningShowMore(!isWarningShowMore);
    setWarningShowCount(count);
  };

  const handleErrorShow = (count) => {
    setIsErrorShowMore(!isErrorShowMore);
    setErrorShowCount(count);
  };

  const categoryCard = (
    title: string,
    data: HealthStatEntity[],
    isShowMore: boolean,
    showCount: number,
    handleShow: (count) => void
  ) => (
    <Card
      title={title}
      style={{ marginTop: 24 }}
      extra={
        data.length <= 10 ? null : isShowMore ? (
          <Button type="text" onClick={() => handleShow(10)} style={{ color: '#3e93fa' }}>
            收起
          </Button>
        ) : (
          <Button type="text" onClick={() => handleShow(data.length)} style={{ color: '#3e93fa' }}>
            展开
          </Button>
        )
      }
    >
      {data.map((item, index) => {
        if (index < showCount) return <Row key={index}>{item.message}</Row>;
        else if (index === showCount + 1) return '......';
        else return null;
      })}
    </Card>
  );

  return (
    <Container>
      <Row gutter={24}>
        {healthStat.stats?.recent_illegal_count_1h >= 0 && (
          <Col span={6}>
            <Statistic
              title="1小时内违法数据"
              value={healthStat.stats?.recent_illegal_count_1h}
              formatter={formatter}
            />
          </Col>
        )}
        {healthStat.stats?.recent_track_count_1h >= 0 && (
          <Col span={6}>
            <Statistic title="1小时内过车数据" value={healthStat.stats?.recent_track_count_1h} formatter={formatter} />
          </Col>
        )}
        {healthStat.stats?.recent_warning_count_1h >= 0 && (
          <Col span={6}>
            <Statistic title="1小时内预警" value={healthStat.stats?.recent_warning_count_1h} formatter={formatter} />
          </Col>
        )}
      </Row>
      {healthStat.status === HealthStatus.WARNING && (
        <Warning>
          <Divider orientation="center">
            <Space>
              <WarningOutlined />
              警告详情
            </Space>
          </Divider>
          {healthByCategory[HealthStatType.ILLEGAL_RECORD] &&
            categoryCard(
              '违法记录',
              healthByCategory[HealthStatType.ILLEGAL_RECORD],
              isIllegalShowMore,
              illegalShowCount,
              handleIllegalShow
            )}
          {healthByCategory[HealthStatType.TRACK_RECORD] &&
            categoryCard(
              '过车记录',
              healthByCategory[HealthStatType.TRACK_RECORD],
              isTrackShowMore,
              trackShowCount,
              handleTrackShow
            )}
          {healthByCategory[HealthStatType.WARNING] &&
            categoryCard(
              '预警记录',
              healthByCategory[HealthStatType.WARNING],
              isWarningShowMore,
              warningShowCount,
              handleWarningShow
            )}
        </Warning>
      )}
      {healthStat.status === HealthStatus.ERROR && (
        <Error>
          <Divider orientation="center">
            <Space>
              <WarningOutlined />
              错误详情
            </Space>
          </Divider>
          {healthStat.errors &&
            categoryCard('错误记录', healthStat.errors, isErrorShowMore, errorShowCount, handleErrorShow)}
        </Error>
      )}
    </Container>
  );
};
export default RobotHealthPage;

const Container = styled.div`
  width: 1000px;
  max-width: 1200px;
`;

const Warning = styled.div`
  margin-top: 24px;
  .title {
  }
`;

const Error = styled.div`
  margin-top: 24px;
  .title {
  }
`;
