import { Subject } from '@36node-fcp/core-sdk';
import { Col, Row } from 'antd';
import { useState } from 'react';
import { styled } from 'styled-components';

import DeviceOverviewPage from './device-overview.page';
import EffectiveIllegalCodePage from './effective-illegal-code.page';
import IllegalOverviewPage from './illegal-overview.page';
import IllegalWarningAnalyzePage from './illegal-warning-analyze.page';
import TrackOverviewPage from './track-overview.page';
import TrackWarningRatePage from './track-warning-rate.page';
import WarningOverviewPage from './warning-overview';

const HomePage: React.FC = () => {
  const [subject, setSubject] = useState<Subject[]>([Subject.VEHICLE]);

  return (
    <PageContainer>
      <TotalContent>
        <Row gutter={24}>
          <Col span={6}>
            <IllegalOverviewPage />
          </Col>
          <Col span={6}>
            <TrackOverviewPage />
          </Col>
          <Col span={6}>
            <WarningOverviewPage />
          </Col>
          <Col span={6}>
            <DeviceOverviewPage />
          </Col>
        </Row>
      </TotalContent>
      <AnalyzeContent>
        <IllegalWarningAnalyzePage onSubject={setSubject} />
      </AnalyzeContent>
      <ExtraContent>
        <Row gutter={24}>
          <Col span={12}>
            <TrackWarningRatePage subject={subject} />
          </Col>
          <Col span={12}>
            <EffectiveIllegalCodePage subject={subject} />
          </Col>
        </Row>
      </ExtraContent>
    </PageContainer>
  );
};

export default HomePage;

const PageContainer = styled.div``;

const TotalContent = styled.div``;

const AnalyzeContent = styled.div`
  margin-top: 24px;
`;

const ExtraContent = styled.div`
  margin-top: 24px;
`;
