import { ApiError, ListIllegalTypesRequest, RuleType, Subject } from '@36node-fcp/core-sdk';
import { PercentageOutlined } from '@ant-design/icons';
import { Badge, Col, Form, InputNumber, Row, Select, SelectProps, Tag } from 'antd';
import React from 'react';

import { useQuery } from 'src/lib/react-api';
import { fcp } from 'src/services';

/**
 * Api Error
 */
export const IllegalTypeApiErrorMap: { [key: string]: { [key: string]: string } } = {
  [ApiError.Code.DUPLICATE]: {
    code: '违法代码已存在',
    name: '违法名称已存在',
  },
};

/**
 * 违法等级
 */

type IllegalLevelUi = {
  text: string;
  color: string;
  value: number;
};

export const IllegalLevelUiList: IllegalLevelUi[] = [
  { text: '高', color: '#D9001B', value: 1 },
  { text: '中', color: '#FF6732', value: 2 },
  { text: '低', color: '#F5B223', value: 3 },
];

export const IllegalLevelTextList = IllegalLevelUiList.map((item) => item.text);
export const IllegalLevelFilter = IllegalLevelUiList;

export const toIllegalLevelText = (level: number) => IllegalLevelUiList.find((item) => item.value === level)?.text;
export const toIllegalLevelValue = (label: string) => IllegalLevelUiList.find((item) => item.text === label)?.value;
export const toIllegalLevelColor = (level: number) => IllegalLevelUiList.find((item) => item.value === level)?.color;

export const IllegalLevelBadge: React.FC<{ level: number }> = ({ level }) => {
  const ui = IllegalLevelUiList.find((item) => item.value === level);
  if (!ui) return <>--</>;
  return <Badge color={ui.color} text={ui.text} />;
};

export const IllegalLevelTag: React.FC<{ level: number }> = ({ level }) => {
  const ui = IllegalLevelUiList.find((item) => item.value === level);
  if (!ui) return <>--</>;
  return <Tag color={ui.color}>{ui.text}</Tag>;
};

/**
 * 违法等级筛选
 */
export function IllegalLevelSelect(props: SelectProps) {
  return (
    <Select {...props}>
      {IllegalLevelUiList.map((item) => (
        <Select.Option key={item.value} value={item.value}>
          <IllegalLevelBadge level={item.value} />
        </Select.Option>
      ))}
    </Select>
  );
}

/**
 * 违法类型
 */

/**
 * 违法类型下拉筛选组件
 * @param props [SelectProps] 组件参数
 * @returns 组件
 */
type IllegalTypeSelectProps = SelectProps & {
  subject?: Subject[];
};
export function IllegalTypeSelect({ subject, ...props }: IllegalTypeSelectProps) {
  const [{ result: types = [] }] = useIllegalTypeList({ _limit: 100 });
  let items = types;
  if (subject) {
    items = types.filter((item) => subject.includes(item.subject));
  }
  return (
    <Select {...props}>
      {items.map((item) => (
        <Select.Option key={item.code} value={item.code} item={item}>
          {item.name}
        </Select.Option>
      ))}
    </Select>
  );
}

export function IllegalNameSelect({ subject, ...props }: IllegalTypeSelectProps) {
  const [{ result: types = [] }] = useIllegalTypeList({ _limit: 100 });
  let items = types;
  if (subject) {
    items = types.filter((item) => subject.includes(item.subject));
  }
  return (
    <Select {...props}>
      {items.map((item) => (
        <Select.Option key={item.code} value={item.name} item={item}>
          {item.name}
        </Select.Option>
      ))}
    </Select>
  );
}

/**
 * 获取违法类型列表
 * @param req 违法类型列表请求参数
 * @returns [state, listIllegalTypes]
 */
export const useIllegalTypeList = (req?: ListIllegalTypesRequest) => {
  const [state, listIllegalTypes] = useQuery(fcp.listIllegalTypes, req || {});
  return [state, listIllegalTypes] as const;
};

/**
 * illegal category
 */

export const IllegalCategoryList = [
  '违反禁止标线',
  '违法停车',
  '驶入应急车道',
  '违法逆行/倒车',
  '超速行驶',
  '欠速行驶',
  '不系安全带',
  '接打电话',
  '疲劳驾驶',
  '违反禁令标志',
  '大客车',
  '专班布控',
  '其他',
];

export function IllegalCategorySelect(props: SelectProps) {
  return (
    <Select {...props}>
      {IllegalCategoryList.map((item) => (
        <Select.Option key={item} value={item}>
          {item}
        </Select.Option>
      ))}
    </Select>
  );
}

/**
 * 违法主体
 */
type SubjectUi = {
  text: string;
  value: string;
};

export const SubjectUiList: SubjectUi[] = [
  { text: '机动车', value: Subject.VEHICLE },
  { text: '非机动车', value: Subject.NONMOTOR },
  { text: '行人', value: Subject.PERSON },
];

export const SubjectMotorList = [
  { text: '机动车', value: Subject.VEHICLE },
  { text: '非机动车及行人', value: Subject.NONMOTOR },
];

export const toSubjectLabel = (value: Subject) => SubjectUiList.find((item) => item.value === value)?.text;
export const toSubjectValue = (label: string) => SubjectUiList.find((item) => item.text === label)?.value;

export const SubjectTextList = SubjectUiList.map((item) => item.text);
export const SubjectFilter = SubjectUiList;

export function SubjectSelect(props: SelectProps) {
  return (
    <Select {...props}>
      {SubjectUiList.map((item) => (
        <Select.Option key={item.value} value={item.value}>
          {item.text}
        </Select.Option>
      ))}
    </Select>
  );
}

export function SubjectMotorSelect(props: SelectProps) {
  return (
    <Select {...props}>
      {SubjectMotorList.map((item) => (
        <Select.Option key={item.value} value={item.value}>
          {item.text}
        </Select.Option>
      ))}
    </Select>
  );
}

export const useIllegalTypeTree = (req?: ListIllegalTypesRequest) => {
  const [{ result: types = [] }] = useIllegalTypeList({ _limit: 1000, ...req });

  const tree = React.useMemo(() => {
    const levels = IllegalLevelUiList.map((l) => ({
      title: l.text,
      key: `l-${l.value}`,
      children: [],
    }));

    const root = [
      {
        title: '所有违法',
        key: 'l-all',
        children: levels,
      },
    ];

    types.forEach((type) => {
      const level = levels[type.level - 1];
      level.children.push({
        title: type.name,
        key: type.code,
        isLeaf: true,
      });
    });
    return root;
  }, [types]);

  return tree;
};

export type CustomIllegalTypeTotalProps = {
  value?: any;
  onChange?: (value: any) => void;
};

export function CustomIllegalTypeTotal(props: CustomIllegalTypeTotalProps) {
  return (
    <Row gutter={24}>
      <Col span={12}>
        <Form.Item
          style={{ margin: 0 }}
          name={['params', 'expire']}
          rules={[{ required: true, message: '请输入累计有效期!' }]}
        >
          <InputNumber min={1} addonAfter="天内" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          style={{ margin: 0 }}
          name={['params', 'count']}
          rules={[{ required: true, message: '请输入累计次数!' }]}
        >
          <InputNumber min={1} addonAfter="次" />
        </Form.Item>
      </Col>
    </Row>
  );
}

export type CustomIllegalTypeMaxMinRateProps = {
  ruleType: RuleType;
  value?: any;
  onChange?: (value: any) => void;
};

export function CustomIllegalTypeMaxMinRate(props: CustomIllegalTypeMaxMinRateProps) {
  const handleMinChange = (min: any) => props.onChange?.({ ...props.value, min });
  const handleMaxChange = (max: any) => props.onChange?.({ ...props.value, max });

  return (
    <Row gutter={24}>
      <Col span={10}>
        <Form.Item
          style={{ margin: 0 }}
          name={['params', 'min']}
          rules={[
            props.ruleType !== RuleType.DROP ? { required: true, message: '请输入下限!' } : { required: false },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (getFieldValue(['params', 'max']) && value && value > getFieldValue(['params', 'max'])) {
                  return Promise.reject(new Error('下限不能大于上限'));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <InputNumber
            value={props.value?.min}
            min={1}
            onChange={handleMinChange}
            addonAfter={<PercentageOutlined />}
          />
        </Form.Item>
      </Col>
      <Col span={2}>-</Col>
      <Col span={10}>
        <Form.Item
          style={{ margin: 0 }}
          name={['params', 'max']}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (getFieldValue(['params', 'min']) && value && value < getFieldValue(['params', 'min'])) {
                  return Promise.reject(new Error('上限不能小于下限'));
                }
                if (!parseInt(getFieldValue(['params', 'min'])) && value)
                  return Promise.reject(new Error('请输入下限'));
                return Promise.resolve();
              },
            }),
          ]}
        >
          <InputNumber
            value={props.value?.max}
            min={1}
            onChange={handleMaxChange}
            addonAfter={<PercentageOutlined />}
          />
        </Form.Item>
      </Col>
    </Row>
  );
}
