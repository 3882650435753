import { IllegalType } from '@36node-fcp/core-sdk';
import { useMemo } from 'react';

import { AdColumnsType, AdTable } from 'src/components/antd/ad-table';
import { ExportModal } from 'src/components/xlsx-export-modal';
import { IllegalTypeReportItem, useIllegalTypeReport } from 'src/features/report';
import { useSlice } from 'src/lib/react-slice';

import { useReportSearch } from './report.context';
import SearchForm from './search.form';

type State = {
  exportVisible: boolean;
};

const initState: State = {
  exportVisible: false,
};
const reducers = {
  openExport(state: State) {
    state.exportVisible = true;
  },
  closeExport(state: State) {
    state.exportVisible = false;
  },
};

/**
 * 违法类型报表
 */
const IllegalTypeReport: React.FC = () => {
  const [{ exportVisible }, dispatch] = useSlice(reducers, initState);
  const [search] = useReportSearch();
  const [{ loading, result, total }] = useIllegalTypeReport(search);

  const columns = useMemo<AdColumnsType<IllegalTypeReportItem>>(() => {
    return [
      {
        title: '违法名',
        dataIndex: 'illegalType',
        compute: (val: IllegalType) => val.name,
      },
      {
        title: '违法数',
        dataIndex: 'illegalCount',
      },
      {
        title: '预警数',
        dataIndex: 'warningCount',
      },
      {
        title: '查处数',
        dataIndex: 'warningCloseCount',
      },
    ];
  }, []);

  return (
    <>
      <AdTable
        bordered
        columns={columns}
        title={<div>按违法类型（{total}）</div>}
        rowKey="uniqKey"
        loading={loading}
        scroll={{ x: 'max-content' }}
        dataSource={result}
        showColumnsFilter={false}
        pagination={false}
        onExport={dispatch.openExport}
        extraTools={<SearchForm />}
      />
      {exportVisible && (
        <ExportModal
          dataSource={result}
          columns={columns}
          filename="违法类型报表.xlsx"
          onClose={dispatch.closeExport}
          title="违法类型报表"
          total={total}
        />
      )}
    </>
  );
};

export default IllegalTypeReport;
