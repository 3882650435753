import { AxiosInstance } from 'axios';
import { redirect } from 'react-router-dom';

import { ROOT_NS } from 'src/config';
import { removeSession, removeSessionId } from 'src/services/stargate';

import { Entity, ListParams, Response } from './helpers';

/**
 * session
 */

export type Session = {
  id: string;
  expireAt: string;
  active: boolean;
  ns: string;
  roles: string[];
  privileges: string[];
  token: string;
  tokenExpireAt: string;
  user: User;
  provider: string;
};

export type CreateSessionRequest = {
  body: {
    provider: string;
    username: string;
    password: string;
    ns: string;
  };
};

export type DeleteSessionRequest = {
  session: string;
};

export type GetSessionRequest = {
  session: string;
};

/**
 * namesapce
 */

export type Namespace = {
  id: string;
  parent: string;
  name: string;
  desc?: string;
  labels?: string[];
  data?: string;
};

export type GetNamespaceRequest = {
  namespace: string;
};

export type ListNamespaceRequest = {
  id_like?: string;
  parent_like?: string;
  name_like?: string;
  labels?: string;
} & ListParams;

export type CreateNamespaceRequest = {
  body: {
    id: string;
    data?: string;
    desc: string;
    labels?: string[];
    name: string;
    parent?: string;
  };
};

export type UpdateNamespaceRequest = {
  namespace: string;
  body: {
    data?: string;
    desc?: string;
    labels?: string[];
    name?: string;
    parent?: string;
  };
};

export type DeleteNamespaceRequest = {
  namespace: string;
};

/**
 * user
 */

export type User = {
  active: boolean;
  nickname?: string;
  phone?: string;
  roles?: string[];
  username: string;
  type: string;
  labels: string[];
} & Entity;

export type GetUserRequest = {
  user: string;
};

export type ListUserRequest = {
  name_like?: string;
  phone?: string;
  roles?: string;
  type?: string | string[];
  type_ne?: string;
} & ListParams;

export type CreateUserRequest = {
  body: {
    active?: boolean;
    nickname?: string;
    ns: string; // 命名空间
    password: string;
    phone?: string;
    roles?: string[];
    source?: string; // 来源
    type?: string; // 用户类型
    username: string;
    createBy?: string;
  };
};

export type UpdateUserRequest = {
  user: string;
  body: {
    active?: boolean;
    nickname?: string;
    ns?: string; // 命名空间
    password?: string;
    phone?: string;
    roles?: string[];
    source?: string; // 来源
  };
};

export type UpdateUserPasswordRequest = {
  user: string;
  body: {
    // 原密码
    oldPassword: string;
    // 新密码
    password: string;
  };
};

export type DeleteUserRequest = {
  user: string;
};

//scope roles

export type ScopeRole = {
  name?: string;
  type?: string;
  desc?: string;
  privileges?: string[];
} & Entity;

export type CreateScopeRoleRequest = {
  scopeId: string;
  body: {
    name?: string;
    desc?: string;
    privileges?: string[];
  } & {
    name: string;
    type: string;
  };
};

export type ListScopeRolesRequest = {
  _limit?: number;
  _offset?: number;
  scopeId?: string;
};

export type GetScopeRoleRequest = {
  scopeId: string;
  roleId: string;
};

export type UpdateScopeRoleRequest = {
  scopeId: string;
  roleId: string;
  /**
   * 角色配置
   */
  body: {
    /**
     * 角色名称
     */
    name?: string;
    /**
     * 角色描述
     */
    desc?: string;
    privileges?: string[];
  };
};

export type DeleteScopeRoleRequest = {
  scopeId: string;
  roleId: string;
};

export class Stargate {
  public client: AxiosInstance;
  constructor(client: AxiosInstance) {
    this.client = client;
  }

  login = (req: CreateSessionRequest): Response<Session> => {
    return this.client
      .request<Session>({
        url: `/sessions`,
        method: 'post',
        data: req.body,
      })
      .then((res) => {
        const session = res.data;
        if (!ROOT_NS.includes(session.ns) && !session.ns.includes(ROOT_NS)) {
          throw new Error(`Root ns:${ROOT_NS} not match with session ns:${session.ns}`);
        }
        return res;
      });
  };

  refresh = (req: GetSessionRequest): Response<Session> => {
    return this.client
      .request<Session>({
        url: `/sessions/${req.session}`,
        method: 'get',
      })
      .then((res) => {
        const session = res.data;
        if (!ROOT_NS.includes(session.ns) && !session.ns.includes(ROOT_NS)) {
          throw new Error(`Root ns:${ROOT_NS} not match with session ns:${session.ns}`);
        }
        return res;
      })
      .catch((e) => {
        const status = e.response?.status;
        if (status !== 200) {
          removeSession();
          removeSessionId();
          redirect('/login');
        }
        throw e;
      });
  };

  logout = (req: DeleteSessionRequest): Response<void> => {
    return this.client.request<void>({
      url: `/sessions/${req.session}`,
      method: 'delete',
    });
  };

  listNamespaces = (req: ListNamespaceRequest): Response<Namespace[]> => {
    return this.client.request<Namespace[]>({
      url: `/namespaces`,
      method: 'get',
      params: req,
    });
  };

  getNamespace = (req: GetNamespaceRequest): Response<Namespace> => {
    return this.client.request<Namespace>({
      url: `/namespaces/${req.namespace}`,
      method: 'get',
    });
  };

  createNamespace = (req: CreateNamespaceRequest): Response<Namespace> => {
    return this.client.request<Namespace>({
      url: `/namespaces`,
      method: 'post',
      data: req.body,
    });
  };

  updateNamespace = (req: UpdateNamespaceRequest): Response<Namespace> => {
    return this.client.request<Namespace>({
      url: `/namespaces/${req.namespace}`,
      method: 'put',
      data: req.body,
    });
  };

  deleteNamespace = (req: DeleteNamespaceRequest): Response<void> => {
    return this.client.request<void>({
      url: `/namespaces/${req.namespace}`,
      method: 'delete',
    });
  };

  listUsers = (req: ListUserRequest): Response<User[]> => {
    return this.client.request<User[]>({
      url: `/users`,
      method: 'get',
      params: req,
    });
  };

  getUser = (req: GetUserRequest): Response<User> => {
    return this.client.request<User>({
      url: `/users/${req.user}`,
      method: 'get',
    });
  };

  createUser = (req: CreateUserRequest): Response<User> => {
    return this.client.request<User>({
      url: `/users`,
      method: 'post',
      data: req.body,
    });
  };

  updateUser = (req: UpdateUserRequest): Response<User> => {
    return this.client.request<User>({
      url: `/users/${req.user}`,
      method: 'put',
      data: req.body,
    });
  };

  updateUserPassword = (req: UpdateUserPasswordRequest): Response<User> => {
    return this.client.request<User>({
      url: `/users/${req.user}/password`,
      method: 'put',
      data: req.body,
    });
  };

  deleteUser = (req: DeleteUserRequest): Response<void> => {
    return this.client.request({
      url: `/users/${req.user}`,
      method: 'delete',
    });
  };

  createScopeRole = (req: CreateScopeRoleRequest): Response<ScopeRole> => {
    return this.client.request<ScopeRole>({
      url: `/scopes/${req.scopeId}/roles`,
      method: 'post',
      data: req.body,
    });
  };

  listScopeRoles = (req: ListScopeRolesRequest): Response<ScopeRole[]> => {
    return this.client.request<ScopeRole[]>({
      url: `/scopes/${req.scopeId}/roles`,
      method: 'get',
      params: req,
    });
  };

  getScopeRole = (req: GetScopeRoleRequest): Response<ScopeRole> => {
    return this.client.request<ScopeRole>({
      url: `/scopes/${req.scopeId}/roles/${req.roleId}`,
      method: 'get',
    });
  };

  updateScopeRole = (req: UpdateScopeRoleRequest): Response<ScopeRole> => {
    return this.client.request<ScopeRole>({
      url: `/scopes/${req.scopeId}/roles/${req.roleId}`,
      method: 'put',
      data: req.body,
    });
  };

  deleteScopeRole = (req: DeleteScopeRoleRequest): Response<void> => {
    return this.client.request({
      url: `/scopes/${req.scopeId}/roles/${req.roleId}`,
      method: 'delete',
    });
  };
}
