import { RobotFormItem, RobotFormItemType, RobotXLSXColumn, Subject } from '@36node-fcp/core-sdk';
import { Checkbox, Input, Radio, Select, Switch } from 'antd';
import TextArea from 'antd/es/input/TextArea';

import { XLSXUploader } from 'src/components/uploader/XLSXUploader';
import { GantryAttrSelect, GantrySelect } from 'src/features/gantry';
import { GantryDeviceSelect } from 'src/features/gantry-device';
import { VehiclePropertySelect } from 'src/features/illegal';
import { IllegalTypeSelect } from 'src/features/illegal-type';
import { LawEnforcePointSelect } from 'src/features/law-enforce-point';
import { RoadSectionSelect } from 'src/features/road-section';

import { RobotGenerateTable } from './robot-generate.table';
import { RobotTransformTable } from './robot-transform.table';

type MultiLineInputProps = {
  value?: string[];
  onChange?: (value: string[]) => void;
};
const MultiLineInput: React.FC<MultiLineInputProps> = ({ value = [], onChange }) => {
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    const lines = value.split('\n');
    onChange?.(lines);
  };
  return <Input.TextArea rows={4} onChange={handleChange} value={value.join('\n')} />;
};

export const getFormItem = (item: RobotFormItem, subject: Subject[]) => {
  switch (item.type) {
    case RobotFormItemType.INPUT:
      return <Input style={{ width: 300 }} />;
    case RobotFormItemType.SELECT:
      return (
        <Select mode={item.mode}>
          {item.options?.map((option) => (
            <Select.Option key={option.value} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
      );
    case RobotFormItemType.RADIO:
      return (
        <Radio.Group>
          {item.options?.map((option) => (
            <Radio key={option.value} value={option.value}>
              {option.label}
            </Radio>
          ))}
        </Radio.Group>
      );
    case RobotFormItemType.CHECKBOX:
      return (
        <Checkbox.Group>
          {item.options?.map((option) => (
            <Checkbox key={option.value} value={option.value}>
              {option.label}
            </Checkbox>
          ))}
        </Checkbox.Group>
      );
    case RobotFormItemType.SWITCH:
      return <Switch />;
    case RobotFormItemType.MULTILINE_INPUT:
      return <MultiLineInput />;
    case RobotFormItemType.TEXTAREA:
      return <TextArea rows={4} />;
    case RobotFormItemType.ROAD_SECTION_SELECT:
      return <RoadSectionSelect mode={item.mode} />;
    case RobotFormItemType.GANTRY_ATTR_SELECT:
      return <GantryAttrSelect mode={item.mode} />;
    case RobotFormItemType.LAW_ENFORCE_POINT_SELECT:
      return <LawEnforcePointSelect mode={item.mode} />;
    case RobotFormItemType.ILLEGAL_TYPE_SELECT:
      return <IllegalTypeSelect mode={item.mode} subject={subject} />;
    case RobotFormItemType.GANTRY_SELECT:
      return <GantrySelect mode={item.mode} />;
    case RobotFormItemType.GANTRY_DEVICE_SELECT:
      return <GantryDeviceSelect mode={item.mode} />;
    case RobotFormItemType.VEHICLE_PROPERTY_SELECT:
      return <VehiclePropertySelect mode={item.mode} />;
    case RobotFormItemType.ROBOT_TRANSFORM_TABLE:
      return <RobotTransformTable subject={subject} />;
    case RobotFormItemType.ROBOT_GENERATE_TABLE:
      return <RobotGenerateTable subject={subject} />;
    case RobotFormItemType.ROBOT_XLSX_UPLOAD:
      return (
        <XLSXUploader
          columns={item.columns.map((it) => ({ ...it }) as RobotXLSXColumn)}
          template="robot白名单导入模板.xlsx"
          mode="string"
        />
      );
    default:
      return <Input />;
  }
};
