import { HealthStat } from '@36node-fcp/core-sdk';
import { List, Modal, Typography } from 'antd';

import { toHealthStatText } from 'src/features/health-stat';

interface HealthStatDetailPageProps {
  record: HealthStat;
  onClose: () => void;
}

const HealthStatDetailPage: React.FC<HealthStatDetailPageProps> = ({ record, onClose: handleClose }) => {
  const { type, source, recordKey, errors = [], originalRecord } = record;

  let errorList: { key: string; value: string }[] = [];
  for (let i = 0; i < errors.length; i++) {
    errorList = errorList.concat(
      errors[i].messages.map((msg) => ({
        key: '错误字段',
        value: `${errors[i].property}:${msg}`,
      }))
    );
  }

  const data = [
    { key: '关键字', value: recordKey },
    ...errorList,
    { key: '源数据', value: JSON.stringify(originalRecord) },
  ];

  return (
    <Modal title="错误详情" open onCancel={handleClose} onOk={handleClose} width={1000}>
      <List
        header={`类型：${toHealthStatText(type)}`}
        footer={`来源：${source}`}
        bordered
        dataSource={data}
        renderItem={(item) => (
          <List.Item>
            <Typography.Text mark>[{item.key}]</Typography.Text> {item.value}
          </List.Item>
        )}
      />
    </Modal>
  );
};

export default HealthStatDetailPage;
