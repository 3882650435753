import { LeftOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, Empty, Image, Row, Spin, Typography } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as Car } from 'src/assets/car.svg';
import { useTrackRecord } from 'src/features/track-record';
import { NamespaceProvider } from 'src/features/users';
import { ymdhms } from 'src/lib/lang/time';

const { Title, Text } = Typography;

const Padding = styled.div`
  margin-top: 12px;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
`;

type LineProps = {
  title: string;
  content?: number | string | React.ReactNode;
  formatter?: (value: any) => string;
};

const Line: React.FC<LineProps> = ({ title, content, formatter }) => {
  let text = content;
  if (formatter) {
    text = formatter(content);
  }

  return (
    <div
      style={{
        paddingTop: 8,
        paddingBottom: 8,
      }}
    >
      <Text strong style={{ marginRight: 8 }}>
        {title}
      </Text>
      <Text>{text || '--'}</Text>
    </div>
  );
};

/**
 * 过车详情页面
 */
const TrackRecordDetailPage: React.FC = () => {
  const { trackRecordId } = useParams();
  const [{ result: trackRecord }] = useTrackRecord({ trackRecordId });

  const navigate = useNavigate();

  if (!trackRecord) {
    return <Spin />;
  }

  return (
    <>
      <Container
        title={
          <Button
            type="link"
            style={{ padding: 0 }}
            onClick={() => {
              if (window.history.length > 1) {
                navigate(-1);
              } else {
                navigate('/vehicle/track');
              }
            }}
          >
            <LeftOutlined />
            返回
          </Button>
        }
        bodyStyle={{
          paddingBottom: 0,
          paddingTop: 0,
        }}
      >
        <Header>
          <Title style={{ fontSize: 20 }}>
            <Car
              style={{
                width: 23,
                height: 23,
                marginRight: 8,
                fill: '#1890ff',
                transform: 'translateY(4px)',
              }}
            />
            车牌号: {trackRecord.plate}
          </Title>
        </Header>
        <div style={{ paddingLeft: 32 }}>
          <Line title="过车时间:" content={trackRecord.capAt} formatter={ymdhms} />
        </div>
      </Container>

      <Padding />

      <Container>
        <Title style={{ fontSize: 16 }}>卡口信息</Title>
        <Line title="经过卡口:" content={trackRecord.gantry.name} />
        <Line
          title="所属部门:"
          content={<NamespaceProvider ns={trackRecord.gantry.ns}>{(namespace) => namespace?.name}</NamespaceProvider>}
        />
        <Line title="所属路段:" content={trackRecord.gantry.section?.name || '--'} />
        {/* <Line title="卡口属性:" value={GantryAttr[gantry.attr] || '--'} /> */}
        <Divider />
        <Title style={{ fontSize: 16 }}>过车图片</Title>
        {trackRecord.images.length ? (
          <Image.PreviewGroup>
            <Row gutter={16}>
              {trackRecord.images?.map((url) => (
                <Col span={8} key={url} style={{ marginTop: 16 }}>
                  <Image src={url} width="100%" key="image" alt="image" />
                </Col>
              ))}
              {trackRecord.plateImages?.map((url) => (
                <Col span={8} key={url} style={{ marginTop: 16 }}>
                  <Image src={url} width="100%" key="image" alt="image" />
                </Col>
              ))}
            </Row>
          </Image.PreviewGroup>
        ) : (
          <Empty description="暂无图像" style={{ height: 200 }} />
        )}
      </Container>
    </>
  );
};

const Container = styled(Card)`
  border: none !important;
  .ant-card-head {
    border: 0 !important;
    .ant-card-extra {
      padding: 8px 0;
    }
  }
  .ant-typography {
    color: rgba(0, 0, 0, 0.65);
  }
`;

export default TrackRecordDetailPage;
