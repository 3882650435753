import { VehicleAggregationGroup, VehicleAggregationResult } from '@36node-fcp/core-sdk';
import { reduce, sortBy } from 'lodash';
import { useMemo } from 'react';

import { AdColumnsType, AdTable } from 'src/components/antd/ad-table';
import { ExportModal } from 'src/components/xlsx-export-modal';
import { toVehiclePropertyText } from 'src/features/illegal';
import { useVehiclesRecordAggregation } from 'src/features/vehicle';
import { useSlice } from 'src/lib/react-slice';

import { useVehicleSearch } from './vehicle.context';
import SearchForm from './vehicle.search.form';

type State = {
  exportVisible: boolean;
};

const initState: State = {
  exportVisible: false,
};
const reducers = {
  openExport(state: State) {
    state.exportVisible = true;
  },
  closeExport(state: State) {
    state.exportVisible = false;
  },
};
/**
 * 车辆使用性质统计页面
 */
const VehiclePropertyStorePage: React.FC = () => {
  const [{ exportVisible }, dispatch] = useSlice(reducers, initState);
  const [search] = useVehicleSearch();
  const [{ loading, result }] = useVehiclesRecordAggregation({
    ...search,
    _group: [VehicleAggregationGroup.PROPERTY],
  });

  const total = reduce(
    result,
    function (sum, vehicle: VehicleAggregationResult) {
      return sum + vehicle.count;
    },
    0
  );

  const columns = useMemo<AdColumnsType<VehicleAggregationResult>>(() => {
    return [
      {
        title: '使用性质',
        dataIndex: 'property',
        compute: toVehiclePropertyText,
      },
      {
        title: '车辆数',
        dataIndex: 'count',
      },
      {
        title: '车辆占比',
        dataIndex: 'count',
        compute: (_) => {
          return ((_ / (total || 0)) * 100).toFixed(1) + '%';
        },
      },
    ];
  }, [total]);

  return (
    <>
      <AdTable
        bordered
        columns={columns}
        title={
          <div>
            车辆使用性质统计页面（
            {total}）
          </div>
        }
        rowKey="uniqKey"
        loading={loading}
        scroll={{ x: 'max-content' }}
        dataSource={sortBy(result, [
          function (o: any) {
            return o.property == null;
          },
          'property',
        ])}
        showColumnsFilter={false}
        pagination={false}
        onExport={dispatch.openExport}
        extraTools={<SearchForm />}
      />
      {exportVisible && (
        <ExportModal
          dataSource={result}
          columns={columns}
          filename="车辆使用性质报表.xlsx"
          onClose={dispatch.closeExport}
          title="车辆使用性质报表"
          total={total}
        />
      )}
    </>
  );
};

export default VehiclePropertyStorePage;
