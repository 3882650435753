import { ApiError, CreateIllegalRecordDto, Subject } from '@36node-fcp/core-sdk';
import { Alert, DatePicker, Form, Input, InputNumber, message, Modal } from 'antd';
import { DatePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { Uploader } from 'src/components/uploader';
import { GantryDeviceSelect } from 'src/features/gantry-device';
import { IllegalRecordApiErrorMap } from 'src/features/illegal';
import { IllegalTypeSelect } from 'src/features/illegal-type';
import { useSession } from 'src/features/session';
import { useApi } from 'src/lib/react-api';
import { fcp, passApiErrors } from 'src/services';

type EditorValues = CreateIllegalRecordDto & { expireDay: number; gantryDevice: string };

interface PersonIllegalEditorProps {
  onClose: () => void;
  onFinish?: () => void;
}

export const PersonIllegalEditor: React.FC<PersonIllegalEditorProps> = ({
  onClose: handleClose,
  onFinish: handleFinish,
}) => {
  const onSuccess = () => {
    message.success('创建成功');
    handleClose();
    handleFinish?.();
  };
  const onFailure = (err: ApiError) => {
    const ne = passApiErrors(err, IllegalRecordApiErrorMap);
    if (ne.keys) {
      ne.keys.forEach((key) => form.setFields([{ name: key, errors: [ne.message] }]));
    } else {
      message.error(ne.message);
    }
  };

  const [form] = Form.useForm<EditorValues>();
  const expireDay = Form.useWatch('expireDay', form);
  const capAt = Form.useWatch('capAt', form);
  const [createState, create] = useApi(fcp.createIllegalRecord, { onSuccess, onFailure });
  const session = useSession();

  const handleOk = () => form.submit();
  const handleSubmit = async (values: EditorValues) => {
    if (values.expireDay) {
      values.expiredAt = dayjs(values.capAt).add(values.expireDay, 'day').toISOString();
    }
    const data = {
      subject: Subject.PERSON,
      source: '手动添加',
      capAt: values.capAt || dayjs().toISOString(),
      ...values,
    };
    delete data.expireDay;

    create({ body: { ...data, ns: session.ns } });
  };

  const error = createState.error;

  const disabledDate: DatePickerProps['disabledDate'] = (current) => {
    return current && current > dayjs();
  };

  return (
    <Modal
      title="录入人员违法信息"
      open
      onOk={handleOk}
      onCancel={handleClose}
      confirmLoading={createState.loading}
      width="650px"
    >
      <Container>
        {error && <Alert message={error.message} type="error" />}
        <Form
          name="gantry-device-editor"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          initialValues={{}}
          autoComplete="off"
          onFinish={() => handleSubmit(form.getFieldsValue(true))}
          form={form}
        >
          <div style={{ marginLeft: '-24px', marginBottom: '24px', fontSize: '16px' }}>基础信息</div>
          <Form.Item label="违法名称" name="code" rules={[{ required: true, message: '请选择违法名称' }]}>
            <IllegalTypeSelect
              placeholder="选择违法名称"
              subject={[Subject.PERSON, Subject.NONMOTOR]}
              onChange={(value, option: any) =>
                form.setFieldsValue({
                  code: value,
                  level: option.item.level,
                  name: option.item.name,
                  expireDay: option.item.expireDay,
                })
              }
            />
          </Form.Item>
          <Form.Item label="违法时间" name="capAt">
            <DatePicker
              format="YYYY-MM-DD HH:mm:ss"
              placeholder="选择违法时间"
              showTime
              style={{ width: '100%' }}
              disabledDate={disabledDate}
            />
          </Form.Item>
          <Form.Item label="抓拍相机" name="gantryDevice" rules={[{ message: '请选择抓拍相机' }]}>
            <GantryDeviceSelect
              placeholder="选择抓拍相机"
              onChange={(value, option: any) =>
                form.setFieldsValue({ gantryDevice: value, place: option.item.gantry.name })
              }
            />
          </Form.Item>
          <Form.Item label="违法地点" name="place">
            <Input placeholder="填写违法地点" />
          </Form.Item>
          <Form.Item
            label="有效天数"
            name="expireDay"
            extra={
              expireDay && (
                <span style={{ color: 'red' }}>{`${dayjs(capAt)
                  .add(expireDay, 'days')
                  .format('YYYY-MM-DD HH:mm:ss')} 后, 该违法信息失效`}</span>
              )
            }
            rules={[{ type: 'integer', min: 0, message: '有效天数无效' }]}
          >
            <InputNumber placeholder="填写有效天数" style={{ width: '100%' }} />
          </Form.Item>
          <div style={{ marginLeft: '-24px', marginTop: '24px', marginBottom: '24px', fontSize: '16px' }}>图片信息</div>
          <Form.Item label="违法照片" name="images">
            <Uploader setImages={(urls: string[]) => form.setFieldValue('images', urls)} />
          </Form.Item>
        </Form>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  margin: 24px;
`;
