import { ApiError } from '@36node-fcp/core-sdk';
import { Form, Input, message, Modal } from 'antd';
import { difference } from 'lodash';
import styled from 'styled-components';

import { ROOT_NS } from 'src/config';
import { PrivilegeTreeSelect, RoleApiErrorMap } from 'src/features/roles';
import { useApi } from 'src/lib/react-api';
import { CreateScopeRoleRequest, ScopeRole } from 'src/sdk';
import { passApiErrors, stargate } from 'src/services';

type ExtractBody<T> = T extends { body: infer U } ? U : never;
type EditorValues = ExtractBody<CreateScopeRoleRequest> & {};

interface RoleEditorProps {
  role?: ScopeRole;
  onClose: () => void;
  onFinish?: () => void;
}

export const RoleEditor: React.FC<RoleEditorProps> = ({ role, onClose: handleClose, onFinish: handleFinish }) => {
  const isEdit = !!role;
  const [form] = Form.useForm<EditorValues>();

  const onSuccess = () => {
    message.success(isEdit ? '编辑成功' : '创建成功');
    handleClose();
    handleFinish?.();
  };
  const onFailure = (err: ApiError) => {
    const ne = passApiErrors(err, RoleApiErrorMap);
    if (ne.keys) {
      ne.keys.forEach((key) => form.setFields([{ name: key, errors: [ne.message] }]));
    } else {
      message.error(ne.message);
    }
  };

  const [updateState, update] = useApi(stargate.updateScopeRole, { onSuccess, onFailure });
  const [createState, create] = useApi(stargate.createScopeRole, { onSuccess, onFailure });

  const handleOk = () => form.submit();
  const handleSubmit = async (values: EditorValues) => {
    if (isEdit) {
      update({ roleId: role.id, scopeId: ROOT_NS, body: values });
    } else {
      create({ scopeId: ROOT_NS, body: { type: values.name, ...values } });
    }
  };

  const isLoading = updateState.loading || createState.loading;
  const title = isEdit ? '编辑角色' : '新建角色';
  const initialValues = isEdit ? role : { genWarning: true };

  return (
    <Modal title={title} open onOk={handleOk} onCancel={handleClose} confirmLoading={isLoading} width="650px">
      <Container>
        <Form
          name="role-editor"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          initialValues={initialValues}
          autoComplete="off"
          onFinish={handleSubmit}
          form={form}
        >
          <Form.Item label="角色名称" name="name" rules={[{ required: true, message: '请填写角色名称' }]}>
            <Input placeholder="填写角色名称" />
          </Form.Item>
          <Form.Item label="角色权限" name="privileges">
            <PrivilegeTreeSelect
              height={400}
              defaultCheckedKeys={role && role.privileges}
              onCheck={(checkedKeysValue: React.Key[]) =>
                form.setFieldValue(
                  'privileges',
                  difference(checkedKeysValue, ['system', 'vehicle', 'person', 'blacklist', 'home'])
                )
              }
            />
          </Form.Item>
        </Form>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  margin: 24px;
`;
