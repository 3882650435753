import { IllegalRecordState, Subject, Vehicle, WarningState } from '@36node-fcp/core-sdk';
import { EditOutlined, LeftOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, Empty, Image, message, Popconfirm, Row, Space, Spin, Typography } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { BigPlayButton, Player } from 'video-react';

import { ReactComponent as Car } from 'src/assets/car.svg';
import Line from 'src/components/line';
import { toVehiclePropertyText, useIllegalRecord } from 'src/features/illegal';
import { useIllegalTypeList } from 'src/features/illegal-type';
import { useSession } from 'src/features/session';
import { ymdhms } from 'src/lib/lang/time';
import { checkPermission } from 'src/lib/privilege';
import { useApi } from 'src/lib/react-api';
import { useSlice } from 'src/lib/react-slice';
import { fcp } from 'src/services';

import { VehicleStoreEditor } from './store.editor';

const { Title } = Typography;

const Padding = styled.div`
  margin-top: 12px;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
`;

type State = {
  editorVisible: boolean;
  vehicle: Vehicle;
};

const initState: State = {
  editorVisible: false,
  vehicle: null,
};

const reducers = {
  openEditor(state: State, vehicle: Vehicle = null) {
    state.editorVisible = true;
    state.vehicle = vehicle;
  },
  closeEditor(state: State) {
    state.editorVisible = false;
    state.vehicle = null;
  },
};

/**
 * 违法详情页面
 */
const IllegalRecordDetailPage: React.FC = () => {
  const session = useSession();
  const [{ editorVisible, vehicle }, dispatch] = useSlice(reducers, initState);
  const [closeState, closeIllegal] = useApi(fcp.closeIllegal, {
    onSuccess: () => {
      message.success('关闭成功');
      getIllegalRecord({ illegalRecordId });
    },
    onFailure: (err) => {
      message.error(`删除失败: ${err.message}`);
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [updateState, update] = useApi(fcp.updateIllegalRecord, {
    onSuccess: () => {
      dispatch.closeEditor();
      message.success('编辑成功');
      getIllegalRecord({ illegalRecordId });
    },
    onFailure: (err) => {
      message.error(`更新违法信息失败: ${err.message}`);
    },
  });
  const [createWarningState, createWarning] = useApi(fcp.createWarning, {
    onSuccess: () => {
      message.success('生成预警成功');
    },
    onFailure: (err) => {
      message.error(`生成预警失败: ${err.message}`);
    },
  });

  const { illegalRecordId } = useParams();
  const [{ result: illegalRecord }, getIllegalRecord] = useIllegalRecord({ illegalRecordId });
  const [{ result: illegalTypes }] = useIllegalTypeList({ _limit: 1000, _offset: 0 });

  const navigate = useNavigate();

  const handleWarning = () => {
    const illegalType = illegalTypes.find((v) => v.code === illegalRecord.code);
    if (illegalType && illegalType.level) {
      createWarning({
        body: {
          ...illegalRecord,
          illegalAt: illegalRecord.capAt,
          illegals: [illegalRecord.id],
          subject: Subject.VEHICLE,
          state: WarningState.OPEN,
          gantryDevice: illegalRecord.gantryDevice,
          level: illegalType.level,
          capAt: dayjs().toISOString(),
        },
      });
    } else {
      message.error('违法类型未知');
    }
  };

  const handleRefresh = (values: Vehicle) => {
    update({
      illegalRecordId: illegalRecordId,
      body: {
        plate: values.plate,
        plateType: values.plateType,
        vehicleProperty: values.property,
        vehicleCompany: values.company,
      },
    });
  };

  const [vehicleUnique, setVehicleUnique] = useState<Vehicle | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (illegalRecord) {
      fcp
        .getVehicleByPlate({
          plate: illegalRecord.plate,
          plateType: illegalRecord.plateType,
        })
        .then((res: any) => {
          res.data && setVehicleUnique(res.data);
          setLoading(false);
        });
    }
  }, [illegalRecord]);

  if (!illegalRecord || loading) {
    return <Spin />;
  }

  return (
    <>
      <Container
        title={
          <Button
            type="link"
            style={{ padding: 0 }}
            onClick={() => {
              if (window.history.length > 1) {
                navigate(-1);
              } else {
                navigate('/vehicle/illegal');
              }
            }}
          >
            <LeftOutlined />
            返回
          </Button>
        }
        bodyStyle={{
          paddingBottom: 0,
          paddingTop: 0,
        }}
      >
        <Header className="header">
          <Title style={{ fontSize: 20 }}>
            <Car
              style={{
                width: 23,
                height: 23,
                marginRight: 8,
                fill: '#1890ff',
                transform: 'translateY(4px)',
              }}
            />
            车牌号: {(vehicleUnique && vehicleUnique?.plate) || illegalRecord.plate}
          </Title>
          <Space>
            <Button
              loading={closeState.loading || createWarningState.loading}
              disabled={illegalRecord.state === IllegalRecordState.CLOSED}
              onClick={handleWarning}
            >
              生成预警
            </Button>
            {checkPermission(session, ['vehicle:carIllegalAd']) && (
              <Popconfirm
                title="你确定要关闭此违法吗？"
                onConfirm={() => closeIllegal({ illegalRecordId: illegalRecordId })}
              >
                <Button
                  type="primary"
                  loading={closeState.loading || createWarningState.loading}
                  disabled={illegalRecord.state === IllegalRecordState.CLOSED}
                  style={{
                    marginLeft: 'auto',
                  }}
                >
                  关闭
                </Button>
              </Popconfirm>
            )}
          </Space>
        </Header>
        <div style={{ paddingLeft: 0, display: 'flex' }}>
          <EditOutlined
            onClick={() =>
              dispatch.openEditor(
                vehicleUnique || {
                  property: illegalRecord.vehicleProperty,
                  plate: illegalRecord.plate,
                  plateType: illegalRecord.plateType,
                  id: '',
                }
              )
            }
            style={{ marginRight: 16, cursor: 'pointer' }}
          />
          <Line title="号牌种类:" content={(vehicleUnique && vehicleUnique?.plateType) || illegalRecord.plateType} />
          <div style={{ paddingLeft: 64 }}>
            <Line
              title="使用性质:"
              content={toVehiclePropertyText(
                (vehicleUnique && vehicleUnique?.property) || illegalRecord.vehicleProperty
              )}
            />
          </div>
          <div style={{ paddingLeft: 64 }}>
            <Line title="车主姓名:" content={(vehicleUnique && vehicleUnique?.ownerName) || '--'} />
          </div>
          <div style={{ paddingLeft: 64 }}>
            <Line title="车主手机:" content={(vehicleUnique && vehicleUnique?.ownerPhone) || '--'} />
          </div>
        </div>
      </Container>

      <Padding />

      <Container>
        <Title style={{ fontSize: 16 }}>违法信息</Title>
        <Divider />
        <Title style={{ fontSize: 16 }}>基本信息</Title>
        <Line title="违法时间:" content={illegalRecord?.capAt} formatter={ymdhms} />
        <Line title="违法名称:" content={illegalRecord?.name} />
        <Line title="违法地点:" content={illegalRecord?.place} />
        <Divider />
        <Title style={{ fontSize: 16 }}>图片信息</Title>
        {illegalRecord?.images?.length ? (
          <Image.PreviewGroup>
            <Row gutter={16}>
              {illegalRecord?.images?.map((url) => (
                <Col span={8} key={url} style={{ marginTop: 16 }}>
                  <Image src={url} width="100%" key="image" alt="image" />
                </Col>
              ))}
              {illegalRecord?.plateImages?.map((url) => (
                <Col span={8} key={url} style={{ marginTop: 16 }}>
                  <Image src={url} width="100%" key="image" alt="image" />
                </Col>
              ))}
            </Row>
          </Image.PreviewGroup>
        ) : (
          <Empty description="暂无图像" style={{ height: 200 }} />
        )}
        <Divider />
        <Title style={{ fontSize: 16 }}>视频信息</Title>
        {illegalRecord?.videos?.length ? (
          <Image.PreviewGroup>
            <Row gutter={16}>
              {illegalRecord?.videos?.map((url) => (
                <Col span={8} key={url} style={{ marginTop: 16 }}>
                  {/* <video src={url} width="100%" /> */}
                  <Player src={url} startTime={2}>
                    <BigPlayButton position="center" />
                  </Player>
                </Col>
              ))}
            </Row>
          </Image.PreviewGroup>
        ) : (
          <Empty description="暂无视频" style={{ height: 200 }} />
        )}
      </Container>

      {editorVisible && (
        <VehicleStoreEditor onClose={dispatch.closeEditor} onFinish={handleRefresh} vehicle={vehicle} />
      )}
    </>
  );
};

const Container = styled(Card)`
  border: none !important;
  .ant-card-head {
    border: 0 !important;
    .ant-card-extra {
      padding: 8px 0;
    }
  }
  .ant-typography {
    color: rgba(0, 0, 0, 0.65);
  }

  .header {
    display: flex;
    justify-content: space-between;
  }
`;

export default IllegalRecordDetailPage;
