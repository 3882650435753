import { WarningState } from '@36node-fcp/core-sdk';
import { LeftOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, Empty, Image, message, Popconfirm, Row, Spin, Typography } from 'antd';
import { Fragment } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import Line from 'src/components/line';
import { toGantryAttrText } from 'src/features/gantry';
import { useSession } from 'src/features/session';
import { NamespaceProvider } from 'src/features/users';
import { useWarning } from 'src/features/warning';
import { ymdhms } from 'src/lib/lang/time';
import { checkPermission } from 'src/lib/privilege';
import { useApi } from 'src/lib/react-api';
import { fcp } from 'src/services';

const { Title } = Typography;

/**
 * 人员预警详情页面
 */
const WarningDetailPage: React.FC = () => {
  const session = useSession();
  const { warningId } = useParams();
  const [{ result: warning }, getWarning] = useWarning({ warningId });

  const navigate = useNavigate();
  const [closeState, closeIllegal] = useApi(fcp.closeWarning, {
    onSuccess: () => {
      message.success('关闭成功');
      getWarning({ warningId });
    },
    onFailure: (err) => {
      message.error(`删除失败: ${err.message}`);
    },
  });

  if (!warning) {
    return <Spin />;
  }

  return (
    <>
      <Container
        title={
          <Button
            type="link"
            style={{ padding: 0 }}
            onClick={() => {
              if (window.history.length > 1) {
                navigate(-1);
              } else {
                navigate('/person/warning');
              }
            }}
          >
            <LeftOutlined />
            返回
          </Button>
        }
      >
        <div style={{ display: 'flex', alignItems: 'space-between' }}>
          <Title style={{ fontSize: 16 }}>预警详情</Title>
          {checkPermission(session, ['person:peopleAlertAd']) && (
            <Popconfirm
              title="确认已处理该预警，对应的违法将会关闭。"
              onConfirm={() => closeIllegal({ warningId: warningId })}
            >
              <Button
                type="primary"
                loading={closeState.loading}
                disabled={warning.state !== WarningState.OPEN}
                style={{
                  marginLeft: 'auto',
                }}
              >
                标记为已处理
              </Button>
            </Popconfirm>
          )}
        </div>
        <Divider />
        <Title style={{ fontSize: 16 }}>预警信息</Title>
        <Line title="过路时间:" content={warning?.capAt} formatter={ymdhms} />
        <Line title="预警名称:" content={warning?.name} />
        <Line title="预警卡口:" content={warning?.gantry?.name} />
        <Line title="卡口属性:" content={toGantryAttrText(warning?.gantry?.attr)} />
        <Line
          title="所属部门:"
          content={<NamespaceProvider ns={warning.gantry.ns}>{(namespace) => namespace?.name}</NamespaceProvider>}
        />
        <Line title="所属路段:" content={warning?.gantry?.section?.name} />
        {warning.images.length ? (
          <Image.PreviewGroup>
            <Row gutter={16}>
              {warning.images?.map((url) => (
                <Col span={8} key={url} style={{ marginTop: 16 }}>
                  <Image src={url} width="100%" key="image" alt="image" />
                </Col>
              ))}
              {warning.plateImages?.map((url) => (
                <Col span={8} key={url} style={{ marginTop: 16 }}>
                  <Image src={url} width="100%" key="image" alt="image" />
                </Col>
              ))}
            </Row>
          </Image.PreviewGroup>
        ) : (
          <Empty description="暂无图像" style={{ height: 200 }} />
        )}
        <Divider />
        <Title style={{ fontSize: 16 }}>违法信息</Title>
        {warning?.illegals?.map((illegal, index) => (
          <Fragment key={illegal.id}>
            {!!index && <Divider />}
            <Line title="违法时间:" content={illegal?.capAt} formatter={ymdhms} />
            <Line title="违法名称:" content={illegal?.name} />
            <Line title="卡口属性:" content={toGantryAttrText(warning?.gantry?.attr)} />
            {illegal?.images.length ? (
              <Image.PreviewGroup>
                <Row gutter={16}>
                  {illegal?.images?.map((url) => (
                    <Col span={8} key={url} style={{ marginTop: 16 }}>
                      <Image src={url} width="100%" key="image" alt="image" />
                    </Col>
                  ))}
                  {illegal?.plateImages?.map((url) => (
                    <Col span={8} key={url} style={{ marginTop: 16 }}>
                      <Image src={url} width="100%" key="image" alt="image" />
                    </Col>
                  ))}
                </Row>
              </Image.PreviewGroup>
            ) : (
              <Empty description="暂无图像" style={{ height: 200 }} />
            )}
          </Fragment>
        ))}
      </Container>
    </>
  );
};

const Container = styled(Card)`
  border: none !important;
  .ant-card-head {
    border: 0 !important;
    .ant-card-extra {
      padding: 8px 0;
    }
  }
  .ant-typography {
    color: rgba(0, 0, 0, 0.65);
  }
`;

export default WarningDetailPage;
