import { Card, Tabs, TabsProps } from 'antd';
import styled from 'styled-components';

import IllegalTypeReport from './illegal-type-report.table';
import LawEnforcePointReport from './law-enforce-point-report.table';
import NamespaceReport from './namespace-report.table';
import SectionReport from './section-report.table';
import SummaryReport from './summary-report.table';

/**
 * 基础数据报表页面
 */
const BasicReportPage: React.FC = () => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: '总报表',
      children: <SummaryReport />,
    },
    {
      key: '2',
      label: '按部门',
      children: <NamespaceReport />,
    },
    {
      key: '3',
      label: '按执法点',
      children: <LawEnforcePointReport />,
    },
    {
      key: '4',
      label: '按路段',
      children: <SectionReport />,
    },
    {
      key: '5',
      label: '按违法类型',
      children: <IllegalTypeReport />,
    },
  ];
  return (
    <>
      <Container
        bodyStyle={{
          paddingBottom: 0,
          paddingTop: 0,
        }}
        title="基础数据报表"
      >
        <Tabs defaultActiveKey="1" destroyInactiveTabPane items={items} />
      </Container>
    </>
  );
};

const Container = styled(Card)`
  border: none !important;
  min-width: 1200px;
  .ant-card-head {
    border: 0 !important;
    .ant-card-extra {
      padding: 8px 0;
    }
  }
  .ant-typography {
    color: rgba(0, 0, 0, 0.65);
  }
`;

export default BasicReportPage;
