import { ApiError, CreateSectionDto, ListSectionsRequest, Section } from '@36node-fcp/core-sdk';
import { Button, Divider, Form, Input, message, Popconfirm } from 'antd';
import { AxiosError } from 'axios';
import { useMemo } from 'react';

import { AdColumnsType, AdTable } from 'src/components/antd/ad-table';
import { ExportModal } from 'src/components/xlsx-export-modal';
import { ImportModal } from 'src/components/xlsx-import-modal';
import { SectionApiErrorMap, useRoadSectionList } from 'src/features/road-section';
import { useSearch } from 'src/hook/search';
import { useApi } from 'src/lib/react-api';
import { useSlice } from 'src/lib/react-slice';
import { ImportValidateError } from 'src/lib/react-xlsx';
import { fcp, passApiErrors } from 'src/services';

import { RoadSectionEditor } from './road-section.editor';

type SearchValues = {} & ListSectionsRequest;
type SearchFormProps = {
  onSearch: (values: SearchValues) => void;
  initialValues?: SearchValues;
};

const defaultQuery: ListSectionsRequest = { _limit: 10, _offset: 0, _sort: '-createAt' };

const SearchForm: React.FC<SearchFormProps> = ({ onSearch, initialValues }) => {
  return (
    <Form onFinish={onSearch} initialValues={initialValues} layout={'inline'}>
      <Form.Item name="name_like">
        <Input placeholder="路段名查询" allowClear />
      </Form.Item>
    </Form>
  );
};

type State = {
  importVisible: boolean;
  exportVisible: boolean;
  editorVisible: boolean;
  editSection?: Section;
};

const initState: State = {
  importVisible: false,
  exportVisible: false,
  editorVisible: false,
};

const reducers = {
  openImport(state: State) {
    state.importVisible = true;
  },
  closeImport(state: State) {
    state.importVisible = false;
  },
  openExport(state: State) {
    state.exportVisible = true;
  },
  closeExport(state: State) {
    state.exportVisible = false;
  },
  openEditor(state: State, Section?: Section) {
    state.editorVisible = true;
    state.editSection = Section;
  },
  closeEditor(state: State) {
    state.editorVisible = false;
    state.editSection = undefined;
  },
};

const upload = async (doc: CreateSectionDto) =>
  fcp.upsertSection({ body: doc }).catch((err: AxiosError<ApiError>) => {
    throw passApiErrors(err.response.data, SectionApiErrorMap);
  });

const beforeUpload = async (records: CreateSectionDto[]) => {
  const set = new Set();
  const errors: ImportValidateError[] = [];
  records.forEach(({ name }, index) => {
    if (set.has(name)) {
      errors.push({
        row: index + 2,
        message: '路段名文件内重复',
      });
    }
    set.add(name);
  });
  return errors;
};

const toQuery = (values: SearchValues): ListSectionsRequest => {
  return { ...defaultQuery, ...values };
};

/**
 * 路段管理页面
 */
const SectionPage: React.FC = () => {
  const [search, setSearch] = useSearch<SearchValues>();
  const [{ result, loading, request = {}, total }, listSections] = useRoadSectionList(toQuery(search));
  const { _limit: limit = 10, _offset: offset = 0 } = request;
  const [{ exportVisible, importVisible, editorVisible, editSection }, dispatch] = useSlice(reducers, initState);

  const deleteOptions = useMemo(
    () => ({
      onSuccess: () => {
        message.success('删除成功');
        listSections(request);
      },
      onFailure: (err) => {
        message.error(`删除失败: ${err.message}`);
      },
    }),
    [request]
  );
  const [deleteState, deleteSection] = useApi(fcp.deleteSection, deleteOptions);

  // 搜索、分页、排序触发
  const handleSearch = (values: SearchValues) => {
    setSearch({
      ...search, // 上一次留存的查询条件
      _offset: 0, // 重置页码
      ...values, // 本次输入的查询条件，取消的条件用 undefined 覆盖上一次的查询条件
    });
  };

  const handleRefresh = () => listSections(request);

  const columns: AdColumnsType<Section> = useMemo(
    () => [
      {
        title: '路段名',
        dataIndex: 'name',
        width: '86%',
        rules: [{ required: true, message: '请填写路段名称' }],
      },
      {
        title: '操作',
        render: (node, record) => (
          <>
            <Button type="link" style={{ padding: 0 }} onClick={() => dispatch.openEditor(record)}>
              编辑
            </Button>
            <Divider type="vertical" />
            <Popconfirm title="你确定要删除这行内容吗？" onConfirm={() => deleteSection({ sectionId: record.id })}>
              <Button type="link" danger style={{ padding: 0 }} loading={deleteState.loading}>
                删除
              </Button>
            </Popconfirm>
          </>
        ),
      },
    ],
    [dispatch, deleteSection]
  );

  return (
    <>
      <AdTable
        columns={columns}
        title="路段管理"
        rowKey="id"
        loading={loading}
        scroll={{ x: 'max-content' }}
        dataSource={result}
        onAddNew={() => dispatch.openEditor()}
        onChange={handleSearch}
        onRefresh={handleRefresh}
        onUpload={dispatch.openImport}
        onExport={dispatch.openExport}
        pagination={{
          total,
          current: offset / limit + 1,
          pageSize: limit,
        }}
        showColumnsFilter={false}
        extraTools={<SearchForm onSearch={handleSearch} initialValues={search} />}
      />
      {exportVisible && (
        <ExportModal
          api={fcp.listSections}
          args={request}
          columns={columns}
          filename="路段.xlsx"
          onClose={dispatch.closeExport}
          title="路段导出"
          total={total}
        />
      )}
      {importVisible && (
        <ImportModal
          api={upload}
          beforeUpload={beforeUpload}
          columns={columns}
          template="路段管理导入模板.xlsx"
          onClose={dispatch.closeImport}
          onFinish={handleRefresh}
          title="路段管理导入"
        />
      )}

      {editorVisible && (
        <RoadSectionEditor onClose={dispatch.closeEditor} onFinish={handleRefresh} Section={editSection} />
      )}
    </>
  );
};

export default SectionPage;
