import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';
import type { RcFile, UploadProps } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';
import React, { useEffect, useState } from 'react';

import { getFileNameUUID, getFileSuffix, mutiload } from 'src/lib/s3';

const beforeUpload = (file: RcFile) => {
  const isMp4 = file.type === 'video/mp4';
  if (!isMp4) {
    message.error('请上传MP4格式的视频！');
  }
  const isLt20M = file.size / 1024 / 1024 < 20;
  if (!isLt20M) {
    message.error('视频大小不能超过20MB！');
  }
  return isMp4 && isLt20M ? true : Upload.LIST_IGNORE; // 文件不符合要求时，不需要在文件列表中展示
};

interface VideoUploaderProp {
  setVideos: (urls: string[]) => void;
  initVideos?: string[];
}

export function VideoUploader({ setVideos, initVideos }: VideoUploaderProp) {
  const [fileList, setFileList] = useState<UploadFile[]>(() => {
    let videoList: UploadFile[] = [];
    if (initVideos && initVideos.length > 0) {
      initVideos.forEach((video, i) => {
        const vd: UploadFile = {
          uid: i.toString(),
          name: '处置视频' + i,
          status: 'done',
          url: video,
        };
        videoList.push(vd);
      });
    }
    return videoList;
  });

  useEffect(() => {
    setVideos(
      fileList.map((f) => {
        if (f.url !== undefined) {
          return f.url;
        }
        return '';
      })
    );
  }, [fileList]);

  const customRequest = async (options: any) => {
    const { file, onSuccess, onProgress, onError } = options;
    // 生成的文件名，保留文件后缀名，进行拼接
    const objName = getFileNameUUID() + getFileSuffix(file.name);

    // // 调用 ali-oss 中的方法,flieName是存放的文件夹名称，可自己定义
    const res = await mutiload(`${objName}`, file, onProgress);
    if (!res.errCode) {
      file.url = res.url;
      onSuccess(res, file);
    } else {
      onError(res.error);
    }
  };

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  return (
    <>
      <Upload fileList={fileList} onChange={handleChange} beforeUpload={beforeUpload} customRequest={customRequest}>
        {fileList.length >= 8 ? null : <Button icon={<UploadOutlined />}>上传文件</Button>}
      </Upload>
    </>
  );
}
