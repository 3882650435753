import { useMemo, useReducer } from 'react';

import { makeAction, PayloadAction, PayloadActionCreator, PayloadActionMaybeCreator } from './action';
import bindActionCreators from './bind-action-creators';
import { CaseReducers, makeReducer, Reducer } from './reducer';

type ActionCreatorForCaseReducer<CR> = CR extends (state: any) => any
  ? CR extends (state: any, payload: infer P) => any
    ? PayloadActionMaybeCreator<P>
    : never
  : CR extends (state: any, payload: infer P) => any
  ? PayloadActionCreator<P>
  : never;

export type CaseReducerActions<CRs extends CaseReducers<any>> = {
  [Type in keyof CRs]: ActionCreatorForCaseReducer<CRs[Type]>;
};

/**
 * The return value of `makeSlice`
 *
 * @public
 */
export interface Slice<State = any, CRs extends CaseReducers<State> = CaseReducers<State>> {
  /**
   * The slice's reducer.
   */
  reducer: Reducer<State>;

  /**
   * Action creators for the types of actions that are handled by the slice
   * reducer.
   */
  actions: CaseReducerActions<CRs>;

  /**
   * The individual case reducer functions that were passed in the `reducers` parameter.
   * This enables reuse and testing if they were defined inline when calling `makeSlice`.
   */
  caseReducers: CRs;

  /**
   * Provides access to the initial state value given to the slice.
   * If a lazy state initializer was provided, it will be called and a fresh value returned.
   */
  getInitState: () => State;

  /**
   * Judge if the given action is a slice action.
   */
  isAction: (action: PayloadAction<unknown>) => boolean;
}

export function makeSlice<State, CRs extends CaseReducers<State> = CaseReducers<State>>(
  reducers: CRs,
  initState: State
): Slice<State, CRs> {
  const actionCreators: Record<string, Function> = {};

  Object.keys(reducers).forEach((type) => {
    actionCreators[type] = makeAction(type);
  });

  const isAction = (action: any): action is PayloadAction<any> => action.type in reducers;
  let reducer = makeReducer(initState, reducers);

  return {
    actions: actionCreators as any,
    caseReducers: reducers,
    getInitState: () => initState,
    isAction,
    reducer,
  };
}

/**
 * Slice hook
 *
 * @param caseReducers 切片reducers
 * @param initState 初始化状态
 * @returns [sliceState, actionCreators]
 */
export function useSlice<State, CRs extends CaseReducers<State> = CaseReducers<State>>(
  caseReducers: CRs,
  initState: State
): [State, CaseReducerActions<CRs>] {
  const { actions, reducer, getInitState } = useMemo(() => makeSlice(caseReducers, initState), []);
  const [state, dispatch] = useReducer(reducer, getInitState());
  const boundActionCreators = useMemo(() => bindActionCreators(actions, dispatch), []);
  return [state, boundActionCreators as any];
}
