import { Subject } from '@36node-fcp/core-sdk';
import { concat } from 'lodash';
import { useMemo } from 'react';

import { AdColumnsType, AdTable } from 'src/components/antd/ad-table';
import { ExportModal } from 'src/components/xlsx-export-modal';
import { NamespaceReportItem, useNamespaceReport } from 'src/features/report';
import { useSlice } from 'src/lib/react-slice';
import { Namespace } from 'src/sdk';

import { useReportSearch } from './report.context';
import SearchForm from './search.form';

type State = {
  exportVisible: boolean;
};

const initState: State = {
  exportVisible: false,
};
const reducers = {
  openExport(state: State) {
    state.exportVisible = true;
  },
  closeExport(state: State) {
    state.exportVisible = false;
  },
};

/**
 * 部门报表
 */
const NamespaceReport: React.FC = () => {
  const [{ exportVisible }, dispatch] = useSlice(reducers, initState);
  const [search] = useReportSearch();
  const [{ loading, list, result, total }] = useNamespaceReport(search);

  const columns = useMemo<AdColumnsType<NamespaceReportItem>>(() => {
    const columnsPart1 = [
      {
        title: '部门名',
        dataIndex: 'ns',
        compute: (val: Namespace) => val.name,
      },
    ];
    const columnsPart2 = [
      {
        title: '违法数',
        dataIndex: 'illegalCount',
      },
      {
        title: '预警数',
        dataIndex: 'warningCount',
      },
      {
        title: '查处数',
        dataIndex: 'warningCloseCount',
      },
    ];
    return search.subject.includes(Subject.VEHICLE)
      ? concat(
          columnsPart1,
          [
            {
              title: '过车数',
              dataIndex: 'trackCount',
            },
          ],
          columnsPart2
        )
      : concat(columnsPart1, columnsPart2);
  }, [search]);

  return (
    <>
      <AdTable
        bordered
        columns={columns}
        title={<div>按部门（{total}）</div>}
        rowKey="uniqKey"
        loading={loading}
        scroll={{ x: 'max-content' }}
        dataSource={result}
        showColumnsFilter={false}
        pagination={false}
        onExport={dispatch.openExport}
        extraTools={<SearchForm />}
      />
      {exportVisible && (
        <ExportModal
          dataSource={list}
          columns={columns}
          filename="部门报表.xlsx"
          onClose={dispatch.closeExport}
          title="部门报表"
          total={total}
        />
      )}
    </>
  );
};

export default NamespaceReport;
