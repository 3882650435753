import { Modal, Progress } from 'antd';
import { styled } from 'styled-components';

import { ExportStatus, useExport, XlsxImportColumn } from 'src/lib/react-xlsx';
import { Response } from 'src/sdk';

type ModalExportProps<K = any, T extends Object = any> = {
  api?: (args: K) => Response<T[]>;
  dataSource?: any[];
  args?: K;
  columns: XlsxImportColumn[];
  filename?: string;
  onClose: () => void;
  onFinish?: () => void;
  title?: string;
  total?: number;
  width?: number;
};

function getTip(status: ExportStatus, error?: Error) {
  switch (status) {
    case ExportStatus.INIT:
      return `准备导出`;
    case ExportStatus.FETCHING:
      return '正在下载...';
    case ExportStatus.WRITING:
      return '正在写入文件...';
    case ExportStatus.SUCCESS:
      return '导出成功';
    case ExportStatus.FAILURE:
      return error?.message || '导出失败';
  }
}

function getColor(status: ExportStatus) {
  switch (status) {
    case ExportStatus.FAILURE:
      return 'red';
    default:
      return;
  }
}

function getLoading(status: ExportStatus) {
  switch (status) {
    case ExportStatus.FETCHING:
    case ExportStatus.WRITING:
      return true;
    default:
      return false;
  }
}

function getOkText(status: ExportStatus) {
  switch (status) {
    case ExportStatus.SUCCESS:
      return '完成';
    case ExportStatus.FAILURE:
      return '关闭';
    default:
      return '导出';
  }
}

export function ExportModal<K = any, T extends Object = any>(props: ModalExportProps) {
  const { api, dataSource, args, columns: coloumns, filename, onClose, onFinish, title, total, width } = props;
  const [{ progress, status, error }, callExport] = useExport<K, T>(coloumns, undefined, api, dataSource);

  const handleClose = () => onClose();
  const handleOk = () => {
    if (status === ExportStatus.INIT) {
      callExport(args, filename);
    }
    if (status === ExportStatus.SUCCESS || status === ExportStatus.FAILURE) {
      onFinish?.();
      onClose();
    }
  };

  return (
    <Modal
      title={title}
      open
      onOk={handleOk}
      onCancel={handleClose}
      okText={getOkText(status)}
      confirmLoading={getLoading(status)}
      width={width}
      maskClosable={false}
    >
      <Container>
        {total && <p>{`本次将导出 ${total} 条记录。`}</p>}
        <p style={{ color: getColor(status) }}>{getTip(status, error)}</p>
        <Progress
          percent={progress}
          status={status === ExportStatus.FAILURE ? 'exception' : 'active'}
          size={[450, 20]}
        />
      </Container>
    </Modal>
  );
}

const Container = styled.div`
  padding: 24px 0;
`;
