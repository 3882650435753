// eslint-disable-next-line import/no-extraneous-dependencies
import { PromisePool } from '@supercharge/promise-pool';
import { ColumnType } from 'antd/es/table';
import Schema, { Rule, Rules } from 'async-validator';
import { useCallback } from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies

import { MAX_TABLE_RECORDS } from 'src/config';
import { useSlice } from 'src/lib/react-slice';
import { readXlsx } from 'src/lib/xlsx';

export enum ImportStatus {
  INIT = 'INIT',
  READING = 'READING',
  VALIDATING = 'VALIDATING',
  INVALID = 'INVALID',
  VALID = 'VALID',
  UPLOADING = 'UPLOADING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export interface ImportValidateError {
  row: number;
  message: string;
}

export interface XlsxImportFile extends File {
  uid: string;
  readonly lastModifiedDate: Date;
}

export interface XlsxImportColumn<T = any> extends ColumnType<T> {
  rules?: Rule;
  importDisabled?: boolean;
  import?: (val: any, record: T) => any;
}

export type XlsxImportState<T = any> = {
  current: number; // 当前记录
  total: number; // 总数
  status: ImportStatus; // 状态
  validateErrors: ImportValidateError[]; // 校验错误
  error?: any; // api 错误
  rows: T[]; // 数据
};

export type XlsxImportActions = {
  read: (file: XlsxImportFile) => Promise<boolean>;
  callImport: () => Promise<void>;
};

const initialState: XlsxImportState = {
  current: 0,
  total: 0,
  status: ImportStatus.INIT,
  validateErrors: [],
  rows: [],
};

const reducers = {
  read: (state: XlsxImportState) => {
    state.status = ImportStatus.READING;
  },
  validate: (state: XlsxImportState) => {
    state.status = ImportStatus.VALIDATING;
  },
  invalid: (state: XlsxImportState, validateErrors: ImportValidateError[]) => {
    state.status = ImportStatus.INVALID;
    state.validateErrors = validateErrors;
  },
  valid: (state: XlsxImportState) => {
    state.status = ImportStatus.VALID;
  },
  progress: (state: XlsxImportState, { current, total }: { current: number; total: number }) => {
    state.current = current;
    state.total = total;
    state.status = ImportStatus.UPLOADING;
  },
  success: (state: XlsxImportState) => {
    state.status = ImportStatus.SUCCESS;
  },
  failure: (state: XlsxImportState, error: any) => {
    state.status = ImportStatus.FAILURE;
    state.error = error;
  },
  setRows: (state: XlsxImportState, rows: any[]) => {
    state.rows = rows;
  },
};

const getColKey = (col) => (col.key || col.dataIndex) as string;

/**
 * 通过 filereader 读取文件
 */
function readFile(file: XlsxImportFile): Promise<Buffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = async (e) => {
      const data = e.target?.result;
      if (data) {
        resolve(data as Buffer);
      } else {
        reject(new Error('文件读取失败'));
      }
    };
    reader.onerror = (e) => {
      reject(e);
    };
    reader.readAsArrayBuffer(file);
  });
}

function transform<T>(record: T, columns: XlsxImportColumn<T>[]): T {
  return columns.reduce((acc, cur) => {
    const key = getColKey(cur);
    if (cur.importDisabled || !key) return acc;
    if (cur.import) {
      acc[key] = cur.import(record[key], record);
    } else {
      acc[key] = record[key];
    }
    return acc;
  }, {} as any);
}

export const useImport = <T extends Object = any>(
  upload: (obj: T) => any,
  columns: XlsxImportColumn<T>[],
  options?: {
    isSupportConcurrency?: boolean;
    concurrentNumber?: number;
    onSuccess?: (records: T[]) => void;
    onFailure?: (validateErrors: ImportValidateError[], error?: any) => void;
    onFileLoad?: (records: T[]) => Promise<ImportValidateError[] | void>; // 用于数据变换或者文件的整体校验，返回校验错误
  }
): [XlsxImportState<T>, XlsxImportActions] => {
  const [state, dispatch] = useSlice(reducers, initialState);

  const read = useCallback(async (file: XlsxImportFile) => {
    // 读取文件
    dispatch.read();

    try {
      const fileData = await readFile(file);
      const rows: T[] = readXlsx({
        columns: columns as any,
        fileData,
      });

      if (rows.length === 0) {
        dispatch.failure(new Error('文件内容为空'));
        return;
      }

      if (rows.length > MAX_TABLE_RECORDS) {
        dispatch.failure(new Error(`文件内容超过${MAX_TABLE_RECORDS}条`));
        return;
      }

      // 移除空 string, --, / 等，并进行 trim
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        for (const key in row) {
          if (typeof row[key] === 'string') {
            row[key] = (row[key] as string).trim() as any;
            if (row[key] === '' || row[key] === '--' || row[key] === '/') {
              delete row[key];
            }
          }
        }
      }

      // 开始校验
      dispatch.validate();
      const validateErrors: ImportValidateError[] = [];
      const descriptor = columns.reduce((acc, cur) => {
        if (cur.rules) {
          acc[getColKey(cur)] = cur.rules;
        }
        return acc;
      }, {} as Rules);
      const validator = new Schema(descriptor);
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        try {
          await validator.validate(row);
        } catch ({ errors }) {
          errors.forEach((error) => {
            validateErrors.push({
              row: i + 2,
              message: error.message,
            });
          });
        }
      }

      // 上传前数据变换或者整体文件内的校验
      if (options?.onFileLoad) {
        const beforeUploadErrs = await options.onFileLoad(rows);
        validateErrors.push(...(beforeUploadErrs || []));
      }

      if (validateErrors.length > 0) {
        dispatch.invalid(validateErrors);
        options?.onFailure?.(validateErrors);
        return;
      }

      // 校验成功
      dispatch.setRows(rows);
      dispatch.valid();
      return true;
    } catch (err) {
      dispatch.failure(err);
      options?.onFailure?.(state.validateErrors, err);
      return false;
    }
  }, []);

  const callImport = useCallback(async () => {
    try {
      // 开始上传
      const errors = [];
      let pos: number = 0;
      if (options?.isSupportConcurrency) {
        await PromisePool.withConcurrency(options?.concurrentNumber || 5)
          .for(state.rows)
          .handleError(async (error, _row, pool) => {
            errors.push(error);
            return pool.stop();
          })
          .process(async (row, index) => {
            try {
              const doc = transform(row, columns);
              await upload(doc);
              dispatch.progress({ current: pos++, total: state.rows.length });
            } catch (err) {
              throw new Error(`第${index + 2}行记录上传失败: ${err.message}`);
            }
          });
      } else {
        for (let i = 0; i < state.rows.length; i++) {
          const doc = transform(state.rows[i], columns);
          await upload(doc).catch((err: Error) => {
            throw new Error(`第${i + 2}行记录上传失败: ${err.message}`);
          });
          dispatch.progress({ current: i + 1, total: state.rows.length });
        }
      }
      // 上传成功
      if (!errors.length) {
        dispatch.progress({ current: state.rows.length, total: state.rows.length });
        dispatch.success();
        options?.onSuccess?.(state.rows);
      } else {
        throw errors[0];
      }
    } catch (err) {
      console.error(err);
      dispatch.failure(err);
      options?.onFailure?.(state.validateErrors, err);
    }
  }, [state.rows]);

  return [state, { read, callImport }];
};
