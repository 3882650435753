import { BellOutlined, DownloadOutlined } from '@ant-design/icons';
import { Dropdown, Layout, Menu, MenuProps, Space, Tooltip } from 'antd';
import React from 'react';
import { Link, Outlet, NavLink as RLink } from 'react-router-dom';
import { styled } from 'styled-components';

import { APP_DOWNLOAD_URL, TITLE, VEHICLE_ONLY } from 'src/config';
import { useSession } from 'src/features/session';
import { findFirstAllowAccessRoute } from 'src/lib/privilege';
import { Session } from 'src/sdk';

const NavLink = styled(RLink)`
  &.active {
    color: #fff !important;
  }
`;

const menuItems = [
  {
    key: 'home',
    path: '/home',
    label: <NavLink to="/home">首页</NavLink>,
  },
  {
    key: 'monitor',
    path: '/monitor',
    label: <NavLink to="/monitor">地图</NavLink>,
  },
  {
    key: 'vehicle',
    path: '/vehicle',
    label: <NavLink to="/vehicle">车辆</NavLink>,
    children: [
      {
        key: 'vehicle:warning',
        path: '/vehicle/warning',
        label: <NavLink to="/vehicle/warning">预警管理</NavLink>,
      },
      {
        key: 'vehicle:illegal',
        path: '/vehicle/illegal',
        label: <NavLink to="/vehicle/illegal">违法库管理</NavLink>,
      },
      {
        key: 'vehicle:track',
        path: '/vehicle/track',
        label: <NavLink to="/vehicle/track">过车信息</NavLink>,
      },
      {
        key: 'vehicle:store',
        path: '/vehicle/store',
        label: <NavLink to="/vehicle/store">车辆库</NavLink>,
      },
    ],
  },
  !VEHICLE_ONLY && {
    key: 'person',
    path: '/person',
    label: <NavLink to="/person">人员</NavLink>,
    children: [
      {
        key: 'person:warning',
        path: '/person/warning',
        label: <NavLink to="/person/warning">预警管理</NavLink>,
      },
      {
        key: 'person:illegal',
        path: '/person/illegal',
        label: <NavLink to="/person/illegal">违法库管理</NavLink>,
      },
    ],
  },
  {
    key: 'blacklist',
    path: '/blacklist',
    label: <NavLink to="/blacklist">黑名单</NavLink>,
  },
  {
    key: 'system',
    path: '/system',
    label: <NavLink to="/system">系统</NavLink>,
  },
];

const userMenuItems: MenuProps['items'] = [
  {
    label: <Link to="/change-password">修改密码</Link>,
    key: 'password',
  },
  {
    type: 'divider',
  },
  {
    label: <Link to="/logout">登出</Link>,
    key: 'logout',
  },
];

/**
 * 按权限过滤菜单
 * @param session 当前登录用户
 * @param items 菜单
 * @returns 过滤后的菜单
 */
export const menuItemsReduce = (session: Session, items: any[]) => {
  let _items = items.filter((item) => findFirstAllowAccessRoute(session, item.path));

  _items.forEach((item, index) => {
    if (item.children) {
      _items[index] = { ...item };
      _items[index].children = menuItemsReduce(session, item.children);
    }
  });
  return _items;
};

const MainLayout: React.FC = () => {
  const session = useSession();
  return (
    <Layout>
      <Header>
        <Logo>
          <img src="/logo.png" alt="logo" />
          <span>{TITLE}</span>
        </Logo>
        <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={['monitor']}
          items={menuItemsReduce(session, menuItems)}
          style={{ minWidth: 390 }}
        />
        <HeaderRight>
          <Space>
            <Tooltip title="下载违法校对客户端" trigger="hover" color="#fff" overlayInnerStyle={{ color: '#000' }}>
              <a href={APP_DOWNLOAD_URL} download>
                <DownloadOutlined />
              </a>
            </Tooltip>
            <BellOutlined />
            <Dropdown menu={{ items: userMenuItems }}>
              <DisplayName onClick={(e) => e.preventDefault()}>
                {session.user.nickname || session.user.username}
              </DisplayName>
            </Dropdown>
          </Space>
        </HeaderRight>
      </Header>
      <Content>
        <Outlet />
      </Content>
    </Layout>
  );
};

export default MainLayout;

const Logo = styled.div`
  height: 56px;
  color: white;
  line-height: 56px;
  padding: 0 16px;
  text-align: center;

  img {
    display: inline;
    width: 32px;
    height: 32px;
    vertical-align: middle;
  }

  span {
    margin-left: 16px;
    font-size: 20px;
    font-weight: 600;
    vertical-align: middle;
  }
`;

const Header = styled(Layout.Header)`
  --text-color: rgba(255, 255, 255, 0.65);

  position: sticky;
  top: 0;
  z-index: 1;

  width: 100%;
  color: var(--text-color);
  line-height: 64px;

  display: flex;
  align-items: center;

  ul[role='menu'] {
    margin-left: 40px;
  }

  li.ant-menu-item,
  li.ant-menu-submenu {
    --text-color: rgba(255, 255, 255, 0.65);
    --active-text-color: #fff;

    cursor: pointer;
    a {
      color: var(--text-color);
    }
    a.active {
      color: var(--active-text-color);
    }
    .ant-btn {
      color: var(--text-color);
    }

    &:hover {
      color: var(--active-text-color);
      a {
        color: var(--active-text-color);
      }
      .ant-btn {
        color: var(--active-text-color);
      }
    }
  }
`;

const HeaderRight = styled.div`
  flex: 1;
  text-align: right;
  --text-color: rgba(255, 255, 255, 0.65);

  color: var(--text-color);

  a {
    color: var(--text-color);
  }
  a:hover {
    color: #fff;
  }
`;

const Content = styled(Layout.Content)`
  min-height: calc(100vh - 64px) !important;
  padding: 24px;
  display: block;
  margin: 0 auto;

  @media (max-width: 1600px) {
    width: 100%;
  }

  @media (min-width: 1600px) {
    width: 1600px;
  }
`;

const DisplayName = styled.span`
  &:hover {
    cursor: pointer;
    color: #fff;
  }
`;
