import { ApiError, CreateVehicleDto, ListVehiclesRequest, Vehicle } from '@36node-fcp/core-sdk';
import { Alert, Button, Card, DatePicker, Divider, Form, Input, message, Popconfirm, Typography } from 'antd';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { isArray, isEmpty, map, min } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { AdColumnsType, AdTable } from 'src/components/antd/ad-table';
import { ExportModal } from 'src/components/xlsx-export-modal';
import { ImportModal } from 'src/components/xlsx-import-modal';
import { MAX_BATCH_REMOVE_RECORDS } from 'src/config';
import {
  toVehiclePropertyText,
  toVehiclePropertyValue,
  VehiclePropertyFilter,
  VehiclePropertyUiList,
} from 'src/features/illegal';
import { useSession } from 'src/features/session';
import { useVehicleList, VehicleStoreApiErrorMap } from 'src/features/vehicle';
import { useSearch } from 'src/hook/search';
import { ymdhms } from 'src/lib/lang/time';
import { checkPermission } from 'src/lib/privilege';
import { useApi } from 'src/lib/react-api';
import { useSlice } from 'src/lib/react-slice';
import { ImportValidateError } from 'src/lib/react-xlsx';
import { fcp, passApiErrors } from 'src/services';

import { VehicleStoreEditor } from './store.editor';

const Container = styled(Card)`
  border: none !important;
  .ant-card-head {
    border: 0 !important;
    .ant-card-extra {
      padding: 8px 0;
    }
  }
  .ant-card-body {
    margin-bottom: 24px;
  }
`;

type SearchValues = { createAt?: any } & ListVehiclesRequest;

type SearchFormProps = {
  onSearch: (values: ListVehiclesRequest) => void;
  initialValues?: ListVehiclesRequest;
};

const defaultQuery: ListVehiclesRequest = {
  _limit: 10,
  _offset: 0,
};

// 判断页面是否操作过查询
const isSearched = (search: SearchValues) => {
  // 切换页码不算查询
  // 默认查询条件不算查询
  const { _limit, _offset, ...rest } = search;
  return !isEmpty(rest);
};

const SearchForm: React.FC<SearchFormProps> = ({ onSearch, initialValues }) => {
  return (
    <Container>
      <Form onFinish={onSearch} initialValues={initialValues} layout={'inline'}>
        <Form.Item name="plate" label="车牌：">
          <Input placeholder="车牌查询" allowClear />
        </Form.Item>
        <Form.Item name="plateType" label="号牌种类：">
          <Input placeholder="号牌种类查询" allowClear />
        </Form.Item>
        <Form.Item label="入库时间：" name="createAt">
          <DatePicker.RangePicker
            showTime
            showSecond={false}
            placeholder={['开始时间', '结束时间']}
            format="YYYY-MM-DD HH:mm"
          />
        </Form.Item>
        <Form.Item shouldUpdate>
          <Button type="primary" htmlType="submit">
            查询
          </Button>
        </Form.Item>
      </Form>
    </Container>
  );
};

type State = {
  importVisible: boolean;
  exportVisible: boolean;
  editorVisible: boolean;
  vehicle: Vehicle;
};

const initState: State = {
  importVisible: false,
  exportVisible: false,
  editorVisible: false,
  vehicle: null,
};

const reducers = {
  openImport(state: State) {
    state.importVisible = true;
  },
  closeImport(state: State) {
    state.importVisible = false;
  },
  openExport(state: State) {
    state.exportVisible = true;
  },
  closeExport(state: State) {
    state.exportVisible = false;
  },
  openEditor(state: State, vehicle: Vehicle = null) {
    state.editorVisible = true;
    state.vehicle = vehicle;
  },
  closeEditor(state: State) {
    state.editorVisible = false;
    state.vehicle = null;
  },
};

const toQuery = (values: SearchValues): ListVehiclesRequest => {
  const { createAt, ...rest } = values;

  if (createAt && isArray(createAt) && createAt[0] && createAt[1]) {
    rest.createAt_gt = dayjs(createAt[0].toISOString()).startOf('minute').toISOString();
    rest.createAt_lt = dayjs(createAt[1].toISOString()).endOf('minute').toISOString();
  } else if (createAt === null) {
    rest.createAt_gt = undefined;
    rest.createAt_lt = undefined;
  }
  return { ...defaultQuery, ...rest };
};

/**
 * 违法库管理页面
 */
const VehicleStorePage: React.FC = () => {
  const session = useSession();
  const [search, setSearch] = useSearch<SearchValues>();
  const [{ result, loading, request = {}, total }, listVehicles] = useVehicleList(toQuery(search));
  const { _limit: limit = 10, _offset: offset = 0 } = request;
  const [{ importVisible, exportVisible, editorVisible, vehicle }, dispatch] = useSlice(reducers, initState);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

  const deleteOptions = useMemo(
    () => ({
      onSuccess: () => {
        message.success('删除成功');
        listVehicles(request);
      },
      onFailure: (err) => {
        message.error(`删除失败: ${err.message}`);
      },
    }),
    [request]
  );
  const [deleteState, deleteVehicleRecord] = useApi(fcp.deleteVehicle, deleteOptions);

  const batchDeleteByIdsOptions = useMemo(
    () => ({
      onSuccess: () => {
        message.success('删除成功');
        setSelectedRowKeys([]);
        listVehicles(request);
      },
      onFailure: (err) => {
        message.error(`删除失败: ${err.message}`);
      },
    }),
    [request]
  );
  const [batchDeleteState, batchDeleteVehicleByIds] = useApi(fcp.batchDeleteVehicleByIds, batchDeleteByIdsOptions);

  const batchDeletebyStateOptions = useMemo(
    () => ({
      onSuccess: () => {
        listVehicles(request);
      },
      onFailure: (err) => {
        message.error(`批量删除失败: ${err.message}`);
      },
    }),
    [request]
  );
  const [batchDeleteStateVehicle, batchDeleteStateRecord] = useApi(fcp.batchDeleteVehicles, batchDeletebyStateOptions);

  // 搜索、分页、排序触发
  const handleSearch = (values: SearchValues) => {
    setSearch({
      ...search, // 上一次留存的查询条件
      _offset: 0, // 重置页码
      ...values, // 本次输入的查询条件，取消的条件用 undefined 覆盖上一次的查询条件
    });
  };

  const handleRefresh = () => listVehicles(request);
  const handleBatchDelete = () => batchDeleteStateRecord(request as any);

  const upload = useCallback(async (doc: CreateVehicleDto) => {
    return fcp.upsertVehicle({ body: doc }).catch((err: AxiosError<ApiError>) => {
      throw passApiErrors(err.response.data, VehicleStoreApiErrorMap);
    });
  }, []);

  const beforeUpload = async (records: CreateVehicleDto[]) => {
    const set = new Set();
    const errors: ImportValidateError[] = [];
    records.forEach(({ plate, plateType }, index) => {
      if (set.has(`${plate}-${plateType}`)) {
        errors.push({
          row: index + 2,
          message: '表格内有重复车辆',
        });
      }
      set.add(`${plate}-${plateType}`);
    });
    return errors;
  };

  const columns = useMemo<AdColumnsType<Vehicle>>(
    () => [
      {
        title: '车牌',
        dataIndex: 'plate',
        rules: [{ required: true, message: '请填写车牌' }],
      },
      {
        title: '使用性质',
        dataIndex: 'property',
        rules: [{ type: 'enum', enum: map(VehiclePropertyUiList, 'text'), message: '所选使用性质无效' }],
        filters: VehiclePropertyFilter,
        compute: toVehiclePropertyText,
        import: toVehiclePropertyValue,
        defaultFilteredValue: search.property && [].concat(search.property),
      },
      {
        title: '号牌种类',
        dataIndex: 'plateType',
        rules: [{ required: true, message: '请填写号牌种类' }],
      },
      {
        title: '所属企业',
        dataIndex: 'company',
      },
      {
        title: '车主姓名',
        dataIndex: 'ownerName',
      },
      {
        title: '车主手机',
        dataIndex: 'ownerPhone',
        import: (value) => value?.toString(),
      },
      {
        title: '创建时间',
        dataIndex: 'createAt',
        compute: ymdhms,
      },
      {
        title: '操作',
        render: (node, record) => (
          <>
            {checkPermission(session, ['vehicle:carAd']) && (
              <Button type="link" style={{ padding: 0 }} onClick={() => dispatch.openEditor(record)}>
                编辑
              </Button>
            )}

            <Divider type="vertical" />
            {checkPermission(session, ['vehicle:carAd']) && (
              <Popconfirm
                title="你确定要删除这行内容吗？"
                onConfirm={() => deleteVehicleRecord({ vehicleId: record.id })}
              >
                <Button type="link" danger style={{ padding: 0 }} loading={deleteState.loading}>
                  删除
                </Button>
              </Popconfirm>
            )}
          </>
        ),
      },
    ],
    [dispatch, deleteVehicleRecord, search]
  );

  const onSelectChange = (newSelectedRowKeys: string[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const CustomTooltip = () => (
    <Alert
      message={
        <>
          <Typography.Text>
            已选择 <span style={{ color: '#1677ff' }}>{selectedRowKeys.length}</span> 项
          </Typography.Text>
          <Popconfirm title="你确定要删除所选内容吗？" onConfirm={() => batchDeleteVehicleByIds(selectedRowKeys)}>
            <Button type="text" style={{ padding: 0, marginLeft: 12 }} loading={batchDeleteState.loading}>
              删除所选
            </Button>
          </Popconfirm>
        </>
      }
      type="info"
      showIcon
    />
  );

  return (
    <>
      <SearchForm onSearch={handleSearch} initialValues={search} />
      <AdTable
        columns={columns}
        extraTools={
          checkPermission(session, ['vehicle:carAd']) && (
            <Popconfirm
              title={
                <>
                  是否将查询到的内容全部删除？
                  <br />
                  将删除{min([total, MAX_BATCH_REMOVE_RECORDS])}条数据
                </>
              }
              onConfirm={handleBatchDelete}
            >
              <Button
                disabled={!isSearched(search) || !total || loading}
                loading={batchDeleteStateVehicle.loading}
                type="primary"
              >
                按查询结果删除
              </Button>
            </Popconfirm>
          )
        }
        title="车辆库列表"
        rowKey="id"
        loading={loading}
        scroll={{ x: 'max-content' }}
        dataSource={result}
        tooltip={selectedRowKeys.length > 0 && <CustomTooltip />}
        onAddNew={checkPermission(session, ['vehicle:carAd']) ? () => dispatch.openEditor() : null}
        onChange={handleSearch}
        onRefresh={handleRefresh}
        onExport={dispatch.openExport}
        onUpload={checkPermission(session, ['vehicle:carAd']) ? () => dispatch.openImport() : null}
        rowSelection={rowSelection}
        pagination={{
          total,
          current: offset / limit + 1,
          pageSize: limit,
        }}
      />
      {exportVisible && (
        <ExportModal
          api={fcp.listVehicles}
          args={request}
          columns={columns}
          filename="车辆库.xlsx"
          onClose={dispatch.closeExport}
          title="车辆库"
          total={total}
        />
      )}
      {importVisible && (
        <ImportModal
          api={upload}
          beforeUpload={beforeUpload}
          columns={columns}
          template="车辆库导入模板.xlsx"
          onClose={dispatch.closeImport}
          onFinish={handleRefresh}
          title="车辆库导入"
        />
      )}
      {editorVisible && (
        <VehicleStoreEditor onClose={dispatch.closeEditor} onFinish={handleRefresh} vehicle={vehicle} />
      )}
    </>
  );
};

export default VehicleStorePage;
