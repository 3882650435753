import {
  AggregateIllegalRecordsRequest,
  AggregateTrackRecordsRequest,
  AggregateWarningsRequest,
  IllegalAggregationGroup,
  Subject,
  TrackAggregationGroup,
  WarningAggregationGroup,
  WarningState,
} from '@36node-fcp/core-sdk';
import { Card } from 'antd';
import dayjs from 'dayjs';
import ReactECharts from 'echarts-for-react';
import { maxBy, sortBy } from 'lodash';
import { Link } from 'react-router-dom';

import { useIllegalRecordAggregation } from 'src/features/illegal';
import { useTrackRecordAggregation } from 'src/features/track-record';
import { useWarningsAggregation } from 'src/features/warning';

// const color = ['#aee9ea', '#ceb3f3', '#ffb5b6', '#d0e9fe'];
// const formatter = new Intl.NumberFormat('en-US');

const illegalQuery: AggregateIllegalRecordsRequest = {
  createAt_gt: dayjs().subtract(7, 'days').startOf('day').toISOString(), //过去七天
  createAt_lt: dayjs().startOf('day').toISOString(),
  _group: [IllegalAggregationGroup.CREATE_AT_DAY],
  _limit: 7,
};

const trackQuery: AggregateTrackRecordsRequest = {
  createAt_gt: dayjs().subtract(7, 'days').startOf('day').toISOString(), //过去七天
  createAt_lt: dayjs().startOf('day').toISOString(),
  _group: [TrackAggregationGroup.CREATE_AT_DAY],
  _limit: 7,
};

const warningQuery: AggregateWarningsRequest = {
  createAt_gt: dayjs().subtract(7, 'days').startOf('day').toISOString(), //过去七天
  createAt_lt: dayjs().startOf('day').toISOString(),
  _group: [WarningAggregationGroup.CREATE_AT_DAY],
  _limit: 7,
};

const warningCloseQuery: AggregateWarningsRequest = {
  createAt_gt: dayjs().subtract(7, 'days').startOf('day').toISOString(), //过去七天
  createAt_lt: dayjs().startOf('day').toISOString(),
  _group: [WarningAggregationGroup.CREATE_AT_DAY],
  _limit: 7,
  state: [WarningState.CLOSED],
};

const TrackWarningRatePage = ({ subject }: { subject: Subject[] }) => {
  const [{ result: illegalAggregations = [] }] = useIllegalRecordAggregation({ ...illegalQuery, subject }) as any;
  const [{ result: trackAggregations = [] }] = useTrackRecordAggregation(trackQuery) as any;
  const [{ result: warningAggregations = [] }] = useWarningsAggregation({ ...warningQuery, subject }) as any;
  const [{ result: investigateAggregations = [] }] = useWarningsAggregation({ ...warningCloseQuery, subject }) as any;

  const longestArray =
    maxBy(
      [illegalAggregations, trackAggregations, warningAggregations, investigateAggregations],
      (arr) => arr?.length
    ) || [];

  const illegalCountArray: number[] = [];
  const trackCountArray: number[] = [];
  const warningCountArray: number[] = [];
  const warningCloseCountArray: number[] = [];
  const dayArray: string[] = [];

  const formattedDay = (dayNumber: number) => {
    const currentDate = dayjs();
    let targetDate = currentDate.set('date', dayNumber);
    if (targetDate.isAfter(currentDate)) {
      targetDate = targetDate.subtract(1, 'month');
    }
    return targetDate.format('MM/DD');
  };

  if (longestArray.length) {
    sortBy(longestArray, [(group) => formattedDay(group.createAt.day)]).forEach((it: any, index) => {
      dayArray.push(formattedDay(it.createAt.day));
      const illegalCount = illegalAggregations?.find((item) => item.createAt?.day === it.createAt.day);
      illegalCountArray.push(illegalCount ? illegalCount.count : 0);

      const trackCount = trackAggregations?.find((item) => item.createAt?.day === it.createAt.day);
      trackCountArray.push(trackCount ? trackCount.count : 0);

      const warningCount = warningAggregations?.find((item) => item.createAt?.day === it.createAt.day);
      warningCountArray.push(warningCount ? warningCount.count : 0);

      const warningCloseCount = investigateAggregations?.find((item) => item.createAt?.day === it.createAt.day);
      warningCloseCountArray.push(warningCloseCount ? warningCloseCount.count : 0);
    });
  }

  const seriesArray = [
    {
      name: '违法',
      type: 'line',
      smooth: true,
      data: illegalCountArray,
      itemStyle: {
        color: '#5470c6',
      },
    },
    {
      name: '预警',
      type: 'line',
      smooth: true,
      data: warningCountArray,
      itemStyle: {
        color: '#91cc75',
      },
    },
    {
      name: '查处',
      type: 'line',
      smooth: true,
      data: warningCloseCountArray,
      itemStyle: {
        color: '#fac858',
      },
    },
    {
      name: '过车',
      type: 'line',
      smooth: true,
      data: trackCountArray,
      itemStyle: {
        color: '#ee6666',
      },
    },
  ];

  if (subject[0] !== Subject.VEHICLE) {
    seriesArray.pop();
  }

  const option = {
    legend: {
      top: -5,
      itemWidth: 10,
      itemGap: 15,
      icon: 'circle',
    },
    grid: {
      top: 29,
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    tooltip: {
      trigger: 'axis',
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        data: dayArray,
      },
    ],
    yAxis: [
      {
        type: 'value',
        splitNumber: 4,
      },
    ],
    series: seriesArray,
  };

  return (
    <Card title="过车、违法、预警、查处7日数据" extra={<Link to="/report">更多</Link>}>
      <ReactECharts key={seriesArray?.length} option={option} style={{ height: '260px' }} />
    </Card>
  );
};
export default TrackWarningRatePage;
