import { ApiError, CreateWhitelistDto, ListWhitelistsRequest, Whitelist } from '@36node-fcp/core-sdk';
import { Button, Divider, Form, Input, message, Popconfirm } from 'antd';
import { AxiosError } from 'axios';
import { useMemo } from 'react';

import { AdColumnsType, AdTable } from 'src/components/antd/ad-table';
import { ExportModal } from 'src/components/xlsx-export-modal';
import { ImportModal } from 'src/components/xlsx-import-modal';
import { useWhitelist, WhitelistApiErrorMap } from 'src/features/whitelist';
import { useSearch } from 'src/hook/search';
import { useApi } from 'src/lib/react-api';
import { useSlice } from 'src/lib/react-slice';
import { ImportValidateError } from 'src/lib/react-xlsx';
import { fcp, passApiErrors } from 'src/services';

import { WhitelistEditor } from './whitelist.editor';

type SearchValues = {} & ListWhitelistsRequest;
type SearchFormProps = {
  onSearch: (values: SearchValues) => void;
  initialValues?: SearchValues;
};

const defaultQuery: ListWhitelistsRequest = { _limit: 10, _offset: 0, _sort: '-createAt' };
const SearchForm: React.FC<SearchFormProps> = ({ onSearch, initialValues }) => {
  return (
    <Form onFinish={onSearch} initialValues={initialValues} layout={'inline'}>
      <Form.Item name="plate_like">
        <Input placeholder="请输入车牌" allowClear />
      </Form.Item>
    </Form>
  );
};

type State = {
  exportVisible: boolean;
  importVisible: boolean;
  editorVisible: boolean;
  editWhite?: Whitelist;
};

const initState: State = {
  exportVisible: false,
  importVisible: false,
  editorVisible: false,
};

const reducers = {
  openExport(state: State) {
    state.exportVisible = true;
  },
  closeExport(state: State) {
    state.exportVisible = false;
  },
  openImport(state: State) {
    state.importVisible = true;
  },
  closeImport(state: State) {
    state.importVisible = false;
  },
  openEditor(state: State, white?: Whitelist) {
    state.editorVisible = true;
    state.editWhite = white;
  },
  closeEditor(state: State) {
    state.editorVisible = false;
  },
};

const upload = async (doc: CreateWhitelistDto) =>
  fcp.upsertWhitelist({ body: doc }).catch((err: AxiosError<ApiError>) => {
    throw passApiErrors(err.response.data, WhitelistApiErrorMap);
  });

const beforeUpload = async (records: CreateWhitelistDto[]) => {
  const set = new Set();
  const errors: ImportValidateError[] = [];
  records.forEach(({ plate, plateType }, index) => {
    if (set.has(`${plate}-${plateType}`)) {
      errors.push({
        row: index + 2,
        message: '文件内车辆重复',
      });
    }
    set.add(`${plate}-${plateType}`);
  });
  return errors;
};

const toQuery = (values: SearchValues): ListWhitelistsRequest => {
  return { ...defaultQuery, ...values };
};

/**
 * 违法类型管理页面
 */
const WhitelistPage: React.FC = () => {
  const [search, setSearch] = useSearch<SearchValues>();
  const [{ result, loading, request = {}, total }, listWhitelists] = useWhitelist(toQuery(search));
  const { _limit: limit = 10, _offset: offset = 0 } = request;
  const [{ exportVisible, importVisible, editorVisible, editWhite }, dispatch] = useSlice(reducers, initState);

  const deleteOptions = useMemo(
    () => ({
      onSuccess: () => {
        message.success('删除成功');
        listWhitelists(request);
      },
      onFailure: (err) => {
        message.error(`删除失败: ${err.message}`);
      },
    }),
    [request]
  );
  const [deleteState, deleteWhitelist] = useApi(fcp.deleteWhitelist, deleteOptions);

  // 搜索、分页、排序触发
  const handleSearch = (values: SearchValues) => {
    setSearch({
      ...search, // 上一次留存的查询条件
      _offset: 0, // 重置页码
      ...values, // 本次输入的查询条件，取消的条件用 undefined 覆盖上一次的查询条件
    });
  };

  const handleRefresh = () => listWhitelists(request);

  const columns = useMemo<AdColumnsType<Whitelist>>(
    () => [
      {
        title: '车牌',
        dataIndex: 'plate',
        rules: [{ required: true, message: '请填写车牌' }],
      },
      {
        title: '号牌种类',
        dataIndex: 'plateType',
        rules: [{ required: true, message: '请正确填写号牌种类' }],
      },
      {
        title: '备注',
        dataIndex: 'remark',
      },
      {
        title: '操作',
        render: (node, record) => (
          <>
            <Button type="link" style={{ padding: 0 }} onClick={() => dispatch.openEditor(record)}>
              编辑
            </Button>
            <Divider type="vertical" />
            <Popconfirm title="你确定要删除这行内容吗？" onConfirm={() => deleteWhitelist({ whitelistId: record.id })}>
              <Button type="link" danger style={{ padding: 0 }} loading={deleteState.loading}>
                删除
              </Button>
            </Popconfirm>
          </>
        ),
      },
    ],
    [dispatch, deleteWhitelist]
  );

  return (
    <>
      <AdTable
        columns={columns}
        title="白名单管理"
        rowKey="id"
        loading={loading}
        scroll={{ x: 'max-content' }}
        dataSource={result}
        onAddNew={() => dispatch.openEditor()}
        onChange={handleSearch}
        onRefresh={handleRefresh}
        onExport={dispatch.openExport}
        onUpload={dispatch.openImport}
        pagination={{
          total,
          current: offset / limit + 1,
          pageSize: limit,
        }}
        extraTools={<SearchForm onSearch={handleSearch} initialValues={search} />}
      />
      {exportVisible && (
        <ExportModal
          api={fcp.listWhitelists}
          args={request}
          columns={columns}
          filename="白名单.xlsx"
          onClose={dispatch.closeExport}
          title="白名单导出"
          total={total}
        />
      )}
      {importVisible && (
        <ImportModal
          api={upload}
          beforeUpload={beforeUpload}
          columns={columns}
          template="白名单车辆批量导入模板.xlsx"
          onClose={dispatch.closeImport}
          onFinish={handleRefresh}
          title="白名单车辆导入"
        />
      )}
      {editorVisible && <WhitelistEditor white={editWhite} onClose={dispatch.closeEditor} onFinish={handleRefresh} />}
    </>
  );
};

export default WhitelistPage;
