import { ApiError } from '@36node-fcp/core-sdk';
import axios from 'axios';
import { get, isEmpty } from 'lodash';

import { AUTH_ENDPOINT } from 'src/config';
import { paramsSerializer } from 'src/lib/url';
import { injectToken } from 'src/sdk/helpers';
import { CreateSessionRequest, Session, Stargate } from 'src/sdk/stargate';

const SESSION_ID = 'session_id';
const SESSION = 'session';

export const getSessionId = () => sessionStorage.getItem(SESSION_ID) || localStorage.getItem(SESSION_ID);
export const saveSessionId = (sessionId: string) => localStorage.setItem(SESSION_ID, sessionId);
export const removeSessionId = () => localStorage.removeItem(SESSION_ID);

export const saveSession = (session: Session) => sessionStorage.setItem(SESSION, JSON.stringify(session));
export const getSession = (): Session | null => JSON.parse(sessionStorage.getItem(SESSION) || 'null');
export const removeSession = () => sessionStorage.removeItem(SESSION);
export const getToken = () => get(getSession(), 'token');

const stargateInstance = axios.create({ baseURL: AUTH_ENDPOINT, paramsSerializer });
stargateInstance.interceptors.request.use(injectToken(getToken));

export const stargate = new Stargate(stargateInstance);

export const logout = async () => {
  try {
    await stargate.logout({ session: getSessionId() });
  } catch (e) {
    throw e;
  } finally {
    removeSession();
    removeSessionId();
  }
  return null;
};

export const login = async (req: CreateSessionRequest) => {
  const res = await stargate.login(req);
  saveSession(res.data);
  saveSessionId(res.data.id);
  return res;
};

type StargateNormalizedError = {
  code?: string;
  keys?: string[];
  value?: any;
  message: string;
};

export const passStargateApiErrors = (
  err: ApiError,
  map: { [key: string]: { [key: string]: string } }
): StargateNormalizedError => {
  const { keyValue, code, message } = err;
  if (!keyValue || isEmpty(keyValue)) return err;
  const keys = Object.keys(keyValue);
  return {
    keys,
    code,
    value: keyValue,
    message: map[code]?.[String(keys)] || message,
  };
};
