import { Subject } from '@36node-fcp/core-sdk';
import { Flex } from 'antd';
import dayjs from 'dayjs';

import CardRangePicker, { getTimeDistance, RangePickerValue, TimeType } from 'src/components/card-range-picker';
import { VEHICLE_ONLY } from 'src/config';
import { SubjectMotorSelect } from 'src/features/illegal-type';

import { useReportSearch } from './report.context';

type SearchFormProps = {
  onSubjectSelect?: (value: Subject) => void;
  onDateRangeChange?: (value: RangePickerValue) => void;
};
const SearchForm: React.FC<SearchFormProps> = ({ onSubjectSelect, onDateRangeChange }) => {
  const [{ rangePickerValue, subject }, dispatch] = useReportSearch();
  const isActive = (type: TimeType) => {
    if (!rangePickerValue) {
      return '';
    }
    const value = getTimeDistance(type);
    if (!value) {
      return '';
    }
    if (!rangePickerValue[0] || !rangePickerValue[1]) {
      return '';
    }
    if (
      rangePickerValue[0].isSame(value[0] as dayjs.Dayjs, 'day') &&
      rangePickerValue[1].isSame(value[1] as dayjs.Dayjs, 'day')
    ) {
      return 'currentDate';
    }
    return '';
  };

  const selectDate = (type: TimeType) => {
    const dates = getTimeDistance(type);
    dispatch.setDateRange(dates);
  };

  const selectDateRange = (dates: RangePickerValue) => {
    dispatch.setDateRange(dates);
  };
  return (
    <Flex gap="24">
      <CardRangePicker
        rangePickerValue={rangePickerValue}
        isActive={isActive}
        handleRangePickerChange={selectDateRange}
        selectDate={selectDate}
      />
      {!VEHICLE_ONLY && (
        <SubjectMotorSelect
          placeholder="请选择违法主体"
          onChange={dispatch.setSubject}
          style={{ minWidth: 200, marginLeft: 'auto' }}
          value={subject.includes(Subject.VEHICLE) ? Subject.VEHICLE : Subject.NONMOTOR}
        ></SubjectMotorSelect>
      )}
    </Flex>
  );
};

export default SearchForm;
