import { BlacklistState } from '@36node-fcp/core-sdk';
import { LeftOutlined } from '@ant-design/icons';
import { Button, Card, Col, Empty, Image, message, Popconfirm, Row, Spin, Typography } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as Car } from 'src/assets/car.svg';
import Line from 'src/components/line';
import { toBlacklistVehicleState, useBlacklistRecord } from 'src/features/blacklist';
import { ymdhms } from 'src/lib/lang/time';
import { useApi } from 'src/lib/react-api';
import { fcp } from 'src/services';

const { Title } = Typography;

const Padding = styled.div`
  margin-top: 12px;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
`;

/**
 * 车辆黑名单详情页面
 */
const BlackListVehicleDetaiPage: React.FC = () => {
  const { blacklistId } = useParams();
  const [{ result: vehicleRecord }, getBlacklistRecord] = useBlacklistRecord({ blacklistId });
  const navigate = useNavigate();

  const [toggleState, toggleBlacklistVehicleState] = useApi(fcp.updateBlacklist, {
    onSuccess: () => {
      message.success('更新成功');
      getBlacklistRecord({ blacklistId });
    },
    onFailure: (err) => {
      message.error(`更新失败: ${err.message}`);
    },
  });

  if (!vehicleRecord) {
    return <Spin />;
  }

  return (
    <>
      <Container
        title={
          <Button
            type="link"
            style={{ padding: 0 }}
            onClick={() => {
              if (window.history.length > 1) {
                navigate(-1);
              } else {
                navigate('/blacklist');
              }
            }}
          >
            <LeftOutlined />
            返回
          </Button>
        }
        bodyStyle={{
          paddingBottom: 0,
          paddingTop: 0,
        }}
      >
        <Header>
          <Title style={{ fontSize: 20 }}>
            <Car
              style={{
                width: 23,
                height: 23,
                marginRight: 8,
                fill: '#1890ff',
                transform: 'translateY(4px)',
              }}
            />
            车牌号: {vehicleRecord.plate}
          </Title>
          <Popconfirm
            title="你确定要执行此操作吗？"
            onConfirm={() =>
              toggleBlacklistVehicleState({
                blacklistId,
                body: {
                  state: vehicleRecord.state === BlacklistState.OPEN ? BlacklistState.CLOSED : BlacklistState.OPEN,
                },
              })
            }
          >
            <Button
              loading={toggleState.loading}
              style={{
                marginLeft: 'auto',
              }}
            >
              {vehicleRecord.state === BlacklistState.OPEN ? '失效' : '生效'}
            </Button>
          </Popconfirm>
        </Header>
        <div style={{ paddingLeft: 32, display: 'flex' }}>
          <Line title="号牌种类:" content={vehicleRecord.plateType} formatter={ymdhms} />
          <Line title="当前状态:" content={toBlacklistVehicleState(vehicleRecord.state)} />
        </div>
      </Container>

      <Padding />

      <Card title="黑名单信息">
        <Title style={{ fontSize: 16 }}>基本信息</Title>
        <Line title="描述:" content={vehicleRecord.content} />
        <Title style={{ fontSize: 16 }}>图片信息</Title>
        {vehicleRecord.images.length ? (
          <Image.PreviewGroup>
            <Row gutter={16}>
              {vehicleRecord.images?.map((url) => (
                <Col span={8} key={url} style={{ marginTop: 16 }}>
                  <Image src={url} width="100%" key="image" alt="image" />
                </Col>
              ))}
            </Row>
          </Image.PreviewGroup>
        ) : (
          <Empty description="暂无图像" style={{ height: 200 }} />
        )}
      </Card>
    </>
  );
};

const Container = styled(Card)`
  border: none !important;
  .ant-card-head {
    border: 0 !important;
    .ant-card-extra {
      padding: 8px 0;
    }
  }
  .ant-typography {
    color: rgba(0, 0, 0, 0.65);
  }
`;

export default BlackListVehicleDetaiPage;
