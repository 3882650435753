import {
  AggregateIllegalRecordsRequest,
  ApiError,
  GetIllegalRecordRequest,
  ListIllegalRecordsRequest,
  VehicleProp,
} from '@36node-fcp/core-sdk';
import { Badge, Select, SelectProps } from 'antd';
import React from 'react';

import { useQuery } from 'src/lib/react-api';
import { fcp } from 'src/services';

/**
 * Api Error
 */
export const IllegalRecordApiErrorMap: { [key: string]: { [key: string]: string } } = {
  [ApiError.Code.DUPLICATE]: {},
};

/**
 * 违法记录状态
 */
type IllegalRecordStateUi = {
  text: string;
  color: string;
  value: string;
};

export const IllegalRecordExpireUiList: IllegalRecordStateUi[] = [
  { text: '未过期', color: '#FAAD14', value: 'EFFECTIVE' },
  { text: '过期', color: '#8c8c8c', value: 'EXPIRED' },
];

export const IllegalRecordExpireFilter = IllegalRecordExpireUiList;
export const toIllegalRecordExpireText = (value: string) =>
  IllegalRecordExpireUiList.find((item) => item.value === value)?.text;

export const IllegalRecordExpireBadge: React.FC<{ state: string }> = ({ state }) => {
  const ui = IllegalRecordExpireUiList.find((item) => item.value === state);
  if (!ui) return null;
  return <Badge color={ui.color} text={ui.text} />;
};

export const IllegalRecordStateUiList: IllegalRecordStateUi[] = [
  { text: '未处理', color: '#FAAD14', value: 'OPEN' },
  { text: '已发送', color: '#5197FA', value: 'PUSHED' },
  { text: '已关闭', color: '#25D2A0', value: 'CLOSED' },
];

export const IllegalRecordStateFilter = IllegalRecordStateUiList;
export const toIllegalRecordStateText = (value: string) =>
  IllegalRecordStateUiList.find((item) => item.value === value)?.text;
export const toIllegalRecordStateValue = (text: string) =>
  IllegalRecordStateUiList.find((item) => item.text === text)?.value;

export const IllegalRecordStateBadge: React.FC<{ state: string }> = ({ state }) => {
  const ui = IllegalRecordStateUiList.find((item) => item.value === state);
  if (!ui) return null;
  return <Badge color={ui.color} text={ui.text} />;
};

export function IllegalRecordStateSelect(props: SelectProps) {
  return (
    <Select {...props}>
      {IllegalRecordStateUiList.map((item) => (
        <Select.Option key={item.value} value={item.value}>
          <Badge color={item.color} text={item.text} />
        </Select.Option>
      ))}
    </Select>
  );
}

/**
 * 获取违法记录列表
 * @param req 违法记录列表请求参数
 * @returns [state, listIllegalRecords]
 */
export const useIllegalRecordList = (req: ListIllegalRecordsRequest) => {
  const [state, listIllegalRecords] = useQuery(fcp.listIllegalRecords, req || {});
  return [state, listIllegalRecords] as const;
};

/**
 * 获取违法记录统计
 */
export const useIllegalRecordAggregation = (req: AggregateIllegalRecordsRequest) => {
  const [state, aggregateIllegalRecords] = useQuery(fcp.aggregateIllegalRecords, req || {});
  return [state, aggregateIllegalRecords] as const;
};

/**
 * 车辆使用性质
 */
export const VehiclePropertyUiList = [
  { text: '大客车', value: VehicleProp.BUS },
  { text: '中客车', value: VehicleProp.MIDDLEBUS },
  { text: '集装箱车', value: VehicleProp.CONTAINERCAR },
  { text: '大件运输车', value: VehicleProp.LARGETRANSPORT },
  { text: '危化品车', value: VehicleProp.DANGERCHAMICAL },
  { text: '货车', value: VehicleProp.TRUCK },
  { text: 'J2', value: VehicleProp.J2 },
  { text: '疑似货车', value: VehicleProp.SUSPECTEDTRUCK },
];
export const VehiclePropertyFilter = VehiclePropertyUiList;
export const toVehiclePropertyText = (value: string) =>
  VehiclePropertyUiList.find((item) => item.value === value)?.text || '--';
export const toVehiclePropertyValue = (text: string) => VehiclePropertyUiList.find((item) => item.text === text)?.value;

export function VehiclePropertySelect(props: SelectProps) {
  return (
    <Select {...props}>
      {VehiclePropertyUiList.map((item) => (
        <Select.Option key={item.value} value={item.value}>
          {item.text}
        </Select.Option>
      ))}
    </Select>
  );
}

export const useIllegalRecord = (req?: GetIllegalRecordRequest) => {
  const [state, getIllegalRecord] = useQuery(fcp.getIllegalRecord, req);
  return [state, getIllegalRecord] as const;
};
