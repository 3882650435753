import { ApiError, ListWhitelistsRequest } from '@36node-fcp/core-sdk';

import { useQuery } from 'src/lib/react-api';
import { fcp } from 'src/services';

/**
 * Api Error
 */

export const WhitelistApiErrorMap: { [key: string]: { [key: string]: string } } = {
  [ApiError.Code.DUPLICATE]: {
    'plate,plateType': '车牌和号牌种类组合已存在',
  },
};

/**
 * 获取违法记录列表
 * @param req 违法记录列表请求参数
 * @returns [apiState, actions]
 */
export const useWhitelist = (req?: ListWhitelistsRequest) => {
  const [state, listWhitelists] = useQuery(fcp.listWhitelists, req || {});
  return [state, listWhitelists] as const;
};
