import { ApiError, CreateIllegalTypeDto, IllegalType, Subject } from '@36node-fcp/core-sdk';
import { Form, Input, InputNumber, message, Modal, Switch } from 'antd';
import styled from 'styled-components';

import { ILLEGAL_EXPIRED_DAYS, VEHICLE_ONLY } from 'src/config';
import {
  IllegalCategorySelect,
  IllegalLevelSelect,
  IllegalTypeApiErrorMap,
  SubjectSelect,
} from 'src/features/illegal-type';
import { useApi } from 'src/lib/react-api';
import { fcp, passApiErrors } from 'src/services';

type EditorValues = CreateIllegalTypeDto & {};

interface IllegalEditorProps {
  illegalType?: IllegalType;
  onClose: () => void;
  onFinish?: () => void;
}

export const IllegalTypeEditor: React.FC<IllegalEditorProps> = ({
  illegalType,
  onClose: handleClose,
  onFinish: handleFinish,
}) => {
  const isEdit = !!illegalType;
  const [form] = Form.useForm<EditorValues>();

  const onSuccess = () => {
    message.success(isEdit ? '编辑成功' : '创建成功');
    handleClose();
    handleFinish?.();
  };
  const onFailure = (err: ApiError) => {
    const ne = passApiErrors(err, IllegalTypeApiErrorMap);
    if (ne.keys) {
      ne.keys.forEach((key) => form.setFields([{ name: key, errors: [ne.message] }]));
    } else {
      message.error(ne.message);
    }
  };

  const [updateState, update] = useApi(fcp.updateIllegalType, { onSuccess, onFailure });
  const [createState, create] = useApi(fcp.createIllegalType, { onSuccess, onFailure });

  const handleOk = () => form.submit();
  const handleSubmit = async (values: EditorValues) => {
    let req = {};
    if (VEHICLE_ONLY) {
      req = { subject: Subject.VEHICLE };
    }
    if (isEdit) {
      update({ illegalTypeId: illegalType.id, body: { ...values, ...req } });
    } else {
      create({ body: { ...values, ...req } });
    }
  };

  const isLoading = updateState.loading || createState.loading;
  const title = isEdit ? '编辑违法类型' : '新建违法类型';
  const initialValues = isEdit ? illegalType : { genWarning: true, expireDay: ILLEGAL_EXPIRED_DAYS };

  return (
    <Modal title={title} open onOk={handleOk} onCancel={handleClose} confirmLoading={isLoading} width="650px">
      <Container>
        <Form
          name="illegal-type-editor"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          initialValues={initialValues}
          autoComplete="off"
          onFinish={handleSubmit}
          form={form}
        >
          <Form.Item label="违法名称" name="name" rules={[{ required: true, message: '请填写违法名称' }]}>
            <Input placeholder="填写违法名称" />
          </Form.Item>
          <Form.Item label="违法内容" name="content">
            <Input.TextArea placeholder="填写违法内容" allowClear />
          </Form.Item>
          <Form.Item label="违法代码" name="code" rules={[{ required: true, message: '请填写违法代码' }]}>
            <Input placeholder="填写违法代码" />
          </Form.Item>
          <Form.Item label="违法分类" name="category" rules={[{ required: true, message: '请选择违法分类' }]}>
            <IllegalCategorySelect allowClear />
          </Form.Item>
          <Form.Item label="违法等级" name="level" rules={[{ required: true, message: '请选择违法等级' }]}>
            <IllegalLevelSelect allowClear />
          </Form.Item>
          {!VEHICLE_ONLY && (
            <Form.Item label="违法主体" name="subject" rules={[{ required: true, message: '请选择违法主体' }]}>
              <SubjectSelect allowClear />
            </Form.Item>
          )}
          <Form.Item label="有效天数" name="expireDay" rules={[{ required: true, message: '填写有效天数' }]}>
            <InputNumber />
          </Form.Item>
          <Form.Item label="生成预警" name="genWarning" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Form>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  margin: 24px;
`;
