import { Subject, Warning } from '@36node-fcp/core-sdk';
import { Image, Typography } from 'antd';
import dayjs from 'dayjs';
import { styled } from 'styled-components';

import { IllegalLevelTag, toIllegalLevelColor } from 'src/features/illegal-type';

type WarningCardProps = {
  warning: Warning;
};

export const WarningCard: React.FC<WarningCardProps> = ({ warning }) => {
  const { images = [], name, plate, level, capAt, gantry, id, subject } = warning;
  const image = images[0];

  return (
    <Box
      className="animate__animated animate__lightSpeedInRight"
      style={{
        background: '#f5f5f5',
        width: 412,
      }}
      onClick={() => {
        window.open(`/${subject === Subject.VEHICLE ? 'vehicle' : 'person'}/warning/${id}`, '_blank'); //在新页面跳转
      }}
    >
      <Left>
        {image && <Image width={120} preview={false} src={image} />}
        {!image && <Image width={120} preview={false} src="src/assets/no-image.png" />}
      </Left>
      <Right>
        <div className="card-header">
          <div className="title">{plate ? plate : '人员违法'}</div>
          <div className="level">
            <IllegalLevelTag level={level} />
          </div>
        </div>
        <div style={{ color: toIllegalLevelColor(level), paddingBottom: 2, fontWeight: 'bold' }}>{name}</div>
        <Typography.Text type="secondary" style={{ marginRight: 8 }}>
          {dayjs(capAt).format('HH:mm')}
        </Typography.Text>
        <Typography.Text>{gantry.name}</Typography.Text>
      </Right>
    </Box>
  );
};

const Box = styled.div`
  min-height: 120px;
  width: 100%;
  display: flex;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 24px;
`;

const Left = styled.div`
  margin: 24px 0 auto 16px;
`;

const Right = styled.div`
  margin: 24px 16px;
  flex-grow: 1;

  .card-header {
    padding-bottom: 5px;
    .title {
      font-size: 18px;
      font-weight: bold;
    }

    .level {
    }

    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
`;
