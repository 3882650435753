import { ApiError, CreateGantryDto, Gantry, ListGantriesRequest } from '@36node-fcp/core-sdk';
import { DownOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Dropdown, Form, Input, MenuProps, message, Modal, Space, Spin } from 'antd';
import { AxiosError } from 'axios';
import { useMemo } from 'react';

import { AdColumnsType, AdTable } from 'src/components/antd/ad-table';
import { ExportModal } from 'src/components/xlsx-export-modal';
import { ImportModal } from 'src/components/xlsx-import-modal';
import { ROOT_NS } from 'src/config';
import {
  GantryApiErrorMap,
  GantryAttrTextList,
  GantryAttrUiList,
  toGantryAttrText,
  toGantryAttrValue,
  useGantryList,
} from 'src/features/gantry';
import { useLawEnforcePointList } from 'src/features/law-enforce-point';
import { useRoadSectionList } from 'src/features/road-section';
import { useNamespaceList } from 'src/features/users';
import { useSearch } from 'src/hook/search';
import { parseFloatN } from 'src/lib/lang/number';
import { useApi } from 'src/lib/react-api';
import { useSlice } from 'src/lib/react-slice';
import { ImportValidateError } from 'src/lib/react-xlsx';
import { Namespace } from 'src/sdk';
import { fcp, passApiErrors } from 'src/services';

import GantryDeviceList from './gantry-device-list';
import { GantryEditor } from './gantry.editor';

type SearchValues = {} & ListGantriesRequest;
type SearchFormProps = {
  onSearch: (values: SearchValues) => void;
  initialValues?: SearchValues;
};

const defaultQuery: ListGantriesRequest = { _limit: 10, _offset: 0, _sort: '-createAt' };

const SearchForm: React.FC<SearchFormProps> = ({ onSearch, initialValues }) => {
  return (
    <Form onFinish={onSearch} initialValues={initialValues} layout={'inline'}>
      <Form.Item name="name_like">
        <Input placeholder="卡口名查询" allowClear />
      </Form.Item>
    </Form>
  );
};

type IsEditorOpen = {
  [key: string]: boolean;
};

type State = {
  exportVisible: boolean;
  importVisible: boolean;
  editorVisible: boolean;
  editGantry?: Gantry;
  isEditorOpen?: IsEditorOpen;
  expandedRowKeys: string[];
};

const initState: State = {
  exportVisible: false,
  importVisible: false,
  editorVisible: false,
  isEditorOpen: {},
  expandedRowKeys: [],
};

const reducers = {
  openExport(state: State) {
    state.exportVisible = true;
  },
  closeExport(state: State) {
    state.exportVisible = false;
  },
  openImport(state: State) {
    state.importVisible = true;
  },
  closeImport(state: State) {
    state.importVisible = false;
  },
  openEditor(state: State, editGantry?: Gantry) {
    state.editorVisible = true;
    state.editGantry = editGantry;
  },
  closeEditor(state: State) {
    state.editorVisible = false;
    state.editGantry = undefined;
  },
  openDeviceEditor(state: State, gantryId?: string) {
    state.isEditorOpen[gantryId] = true;
    state.expandedRowKeys.push(gantryId);
  },
  closeDeviceEditor(state: State, gantryId?: string) {
    state.isEditorOpen[gantryId] = false;
  },
  openExpandRow(state: State, gantryId?: string) {
    state.expandedRowKeys.push(gantryId);
  },
  closeExpandedRow(state: State, gantryId?: string) {
    state.expandedRowKeys = state.expandedRowKeys.filter((item) => item !== gantryId);
  },
};

const upload = async (doc: CreateGantryDto) =>
  fcp.upsertGantry({ body: doc }).catch((err: AxiosError<ApiError>) => {
    throw passApiErrors(err.response.data, GantryApiErrorMap);
  });

const beforeUpload = async (records: CreateGantryDto[]) => {
  const set = new Set();
  const errors: ImportValidateError[] = [];
  records.forEach(({ name }, index) => {
    if (set.has(name)) {
      errors.push({
        row: index + 2,
        message: '卡口名重复',
      });
    }
    set.add(name);
  });
  return errors;
};

const opMoreMenuItems: MenuProps['items'] = [
  {
    key: 'addDevice',
    label: '添加设备',
  },
  {
    key: 'delete',
    label: <span style={{ color: 'red' }}>删除卡口</span>,
  },
];

const toQuery = (values: SearchValues): ListGantriesRequest => {
  return { ...defaultQuery, ...values };
};

/**
 * 卡口管理页面
 */
const GantryPage: React.FC = () => {
  const [search, setSearch] = useSearch<SearchValues>();
  const [{ result, loading, request = {}, total }, listGantries] = useGantryList(toQuery(search));
  const [{ result: namespaces = [] }] = useNamespaceList({ id_like: ROOT_NS, _limit: 1000 });
  const [{ result: lawEnforcePoints = [] }] = useLawEnforcePointList({ _limit: 1000 });
  const [{ result: gantrySections = [] }] = useRoadSectionList({ _limit: 1000 });

  const { _limit: limit = 10, _offset: offset = 0 } = request;
  const [{ exportVisible, importVisible, editorVisible, editGantry, isEditorOpen, expandedRowKeys }, dispatch] =
    useSlice(reducers, initState);

  const [, deleteGantry] = useApi(fcp.deleteGantry, {
    onSuccess: () => {
      message.success('删除成功');
      listGantries(request);
    },
    onFailure: (err) => {
      message.error(`删除失败: ${err.message}`);
    },
  });

  // 搜索、分页、排序触发
  const handleSearch = (values: SearchValues) => {
    setSearch({
      ...search, // 上一次留存的查询条件
      _offset: 0, // 重置页码
      ...values, // 本次输入的查询条件，取消的条件用 undefined 覆盖上一次的查询条件
    });
  };

  const handleRefresh = () => listGantries(request);
  const handleClickOpMore =
    (record: Gantry) =>
    async ({ key }) => {
      if (key === 'addDevice') {
        dispatch.openDeviceEditor(record.id);
      } else if (key === 'delete') {
        Modal.confirm({
          icon: <ExclamationCircleOutlined />,
          content: '确认删除该卡口吗？',
          onOk() {
            deleteGantry({ gantryId: record.id });
          },
        });
      }
    };

  const columns = useMemo<AdColumnsType<Gantry>>(
    () => [
      {
        title: '卡口名',
        dataIndex: 'name',
        rules: [{ required: true, message: '请填写卡口名' }],
      },
      {
        title: '所属部门',
        dataIndex: 'ns',
        filters: namespaces?.map((item: Namespace) => ({ text: item.name, value: item.id })).reverse(),
        rules: [
          { required: true, message: '请正确填写所属部门', type: 'enum', enum: namespaces?.map((item) => item.name) },
        ],
        compute: (val) => namespaces?.find((item) => item.id === val)?.name || '--',
        import: (nsName: string) => namespaces?.find((item) => item.name === nsName)?.id,
        defaultFilteredValue: search.ns && [].concat(search.ns),
      },
      {
        title: '所属路段',
        key: 'section',
        dataIndex: ['section', 'name'],
        filters: gantrySections?.map((item) => ({ text: item.name, value: item.id })),
        rules: [{ message: '请正确填写所属路段', type: 'enum', enum: gantrySections?.map((item) => item.name) }],
        import: (sectionName: string) => gantrySections?.find((item) => item.name === sectionName)?.id,
        defaultFilteredValue: search.section && [].concat(search.section),
      },
      {
        title: '卡口属性',
        dataIndex: 'attr',
        filters: GantryAttrUiList,
        rules: [{ required: true, message: '请正确填写卡口属性', type: 'enum', enum: GantryAttrTextList }],
        compute: toGantryAttrText,
        import: toGantryAttrValue,
        defaultFilteredValue: search.attr && [].concat(search.attr),
      },
      {
        title: '所属执法点',
        key: 'lawEnforcePoint',
        dataIndex: ['lawEnforcePoint', 'name'],
        filters: lawEnforcePoints?.map((item) => ({ text: item.name, value: item.id })),
        rules: [{ message: '请正确填写执法点', type: 'enum', enum: lawEnforcePoints?.map((item) => item.name) }],
        import: (name: string) => lawEnforcePoints?.find((item) => item.name === name)?.id,
        defaultFilteredValue: search.lawEnforcePoint && [].concat(search.lawEnforcePoint),
      },
      {
        title: '经度',
        dataIndex: 'lng',
        defaultHidden: true,
        rules: [
          { required: true, message: '请正确填写经度', type: 'number', min: 0, max: 180, transform: parseFloatN },
        ],
      },
      {
        title: '纬度',
        dataIndex: 'lat',
        defaultHidden: true,
        rules: [{ required: true, message: '请正确填写纬度', type: 'number', min: 0, max: 90, transform: parseFloatN }],
      },
      {
        title: '操作',
        width: 164,
        render: (_, record) => {
          return (
            <Space>
              <Button type="link" style={{ padding: 0 }} onClick={() => dispatch.openEditor(record)}>
                编辑
              </Button>

              <Dropdown
                menu={{ items: opMoreMenuItems, onClick: handleClickOpMore(record) }}
                placement="bottomRight"
                trigger={['click']}
              >
                <Button type="link" style={{ padding: 0 }}>
                  更多
                  <DownOutlined />
                </Button>
              </Dropdown>
            </Space>
          );
        },
      },
    ],
    [namespaces, lawEnforcePoints, gantrySections, dispatch, search]
  );

  // 如果这几个数据还没准备好，对应字段的数据筛选器会无法响应 defaultFilteredValue
  if (!namespaces || !lawEnforcePoints || !gantrySections) {
    return <Spin />;
  }

  return (
    <>
      <AdTable
        columns={columns}
        title="卡口管理"
        rowKey="id"
        loading={loading}
        scroll={{ x: 'max-content' }}
        dataSource={result}
        onAddNew={() => dispatch.openEditor()}
        onChange={handleSearch}
        onRefresh={handleRefresh}
        onExport={dispatch.openExport}
        onUpload={dispatch.openImport}
        pagination={{
          total,
          current: offset / limit + 1,
          pageSize: limit,
        }}
        expandable={{
          expandedRowRender: (record) => (
            <GantryDeviceList
              gantry={record}
              isEditorOpen={isEditorOpen[record.id]}
              onEditorOpen={dispatch.openDeviceEditor}
              onEditorClose={dispatch.closeDeviceEditor}
            />
          ),
          expandedRowKeys: expandedRowKeys,
          onExpand: (isExpand, record) => {
            isExpand === true ? dispatch.openExpandRow(record.id) : dispatch.closeExpandedRow(record.id);
          },
        }}
        extraTools={<SearchForm onSearch={handleSearch} initialValues={search} />}
      />
      {exportVisible && (
        <ExportModal
          api={fcp.listGantries}
          args={request}
          columns={columns}
          filename="卡口.xlsx"
          onClose={dispatch.closeExport}
          title="卡口导出"
          total={total}
        />
      )}
      {importVisible && (
        <ImportModal
          api={upload}
          beforeUpload={beforeUpload}
          columns={columns}
          template="卡口导入模板.xlsx"
          onClose={dispatch.closeImport}
          onFinish={handleRefresh}
          title="卡口导入"
        />
      )}
      {editorVisible && <GantryEditor onClose={dispatch.closeEditor} onFinish={handleRefresh} gantry={editGantry} />}
    </>
  );
};

export default GantryPage;
