import { ListRobotInfosRequest } from '@36node-fcp/core-sdk';

import { useQuery } from 'src/lib/react-api';
import { fcp } from 'src/services';

/**
 * 获取Robot列表
 * @param req Robot请求参数
 * @returns [apiState, actions]
 */
export const useRobotList = (req?: ListRobotInfosRequest) => {
  const [state, listRobotInfos] = useQuery(fcp.listRobotInfos, req || {});
  return [state, listRobotInfos] as const;
};

export const useRobotInfoById = (id: string) => {
  const [state, getRobotInfo] = useQuery(fcp.getRobotInfo, { robotInfoId: id });
  return [state, getRobotInfo] as const;
};
