import { legacyLogicalPropertiesTransformer, px2remTransformer, StyleProvider } from '@ant-design/cssinjs';
import { ConfigProvider, Spin } from 'antd';
import zhCN from 'antd/locale/zh_CN';
import dayjs from 'dayjs';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';

import 'dayjs/locale/zh-cn';
import 'leaflet/dist/leaflet.css';
import 'video-react/dist/video-react.css';
import 'animate.css';

import App from './app';

import './app.css';

import reportWebVitals from './reportWebVitals';

dayjs.locale('zh-cn');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const px2rem = px2remTransformer({
  rootValue: 16, // 32px = 1rem; @default 16
});

root.render(
  <React.StrictMode>
    <Suspense fallback={<Spin />}>
      <ConfigProvider
        locale={zhCN}
        theme={{
          components: {
            Cascader: {
              dropdownHeight: 300,
            },
          },
        }}
      >
        <StyleProvider hashPriority="high" transformers={[legacyLogicalPropertiesTransformer, px2rem]}>
          <App />
        </StyleProvider>
      </ConfigProvider>
    </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
