import { includes, intersection, keys, startsWith } from 'lodash';

import { BuiltInSuperManager } from 'src/config';
import { Session } from 'src/sdk';

/**
 * 权限列表
 */
export type Privilege = {
  text: string;
  value: string;
  parent?: string;
};
export const privilegeList: Privilege[] = [
  { text: '系统', value: 'system' },
  { text: '系统健康', value: 'systemHealthAd', parent: 'system' },
  { text: '卡口管理', value: 'gantryAd', parent: 'system' },
  { text: '设备管理', value: 'gantryDeviceAd', parent: 'system' },
  { text: '执法点管理', value: 'lawEnforcePointAd', parent: 'system' },
  { text: '路段管理', value: 'roadAd', parent: 'system' },
  { text: '违法类型管理', value: 'illegalTypeAd', parent: 'system' },
  { text: '白名单管理', value: 'whiteListAd', parent: 'system' },
  { text: '规则管理', value: 'ruleAd', parent: 'system' },
  { text: '数据管理', value: 'robotAd', parent: 'system' },
  { text: '车辆', value: 'vehicle' },
  { text: '车辆违法库管理', value: 'carIllegalAd', parent: 'vehicle' },
  { text: '车辆预警管理', value: 'carAlertAd', parent: 'vehicle' },
  { text: '车辆库管理', value: 'carAd', parent: 'vehicle' },
  { text: '人员', value: 'person' },
  { text: '人员违法库管理', value: 'peopleIllegalAd', parent: 'person' },
  { text: '人员预警管理', value: 'peopleAlertAd', parent: 'person' },
  { text: '黑名单', value: 'blacklist' },
  { text: '车辆黑名单管理', value: 'carBlacklistAd', parent: 'blacklist' },
  { text: '首页', value: 'home' },
  { text: '首页', value: 'homeAd', parent: 'home' },
];

/**
 * 路由权限关系
 */
export const routePrivilegeMap = {
  '/home': 'home:homeAd',
  '/monitor': '',
  '/vehicle/warning': '',
  '/vehicle/warning/:warningId': '',
  '/vehicle/illegal': '',
  '/vehicle/illegal/:illegalRecordId': '',
  '/vehicle/track': '',
  '/vehicle/track/:trackRecordId': '',
  '/vehicle/store': 'vehicle:carAd',
  '/person/warning': '',
  '/person/warning/:warningId': '',
  '/person/illegal': '',
  '/blacklist': 'blacklist:carBlacklistAd',
  '/blacklist/:blacklistId': 'blacklist:carBlacklistAd',
  '/system/system-health': 'system:systemHealthAd',
  '/system/gantry': 'system:gantryAd',
  '/system/law-enforce-point': 'system:lawEnforcePointAd',
  '/system/device': 'system:gantryDeviceAd',
  '/system/road-section': 'system:roadAd',
  '/system/illegal-type': 'system:illegalTypeAd',
  '/system/user': 'system:userAd',
  '/system/role': 'system:roleAd',
  '/system/whitelist': 'system:whiteListAd',
  '/system/rule': 'system:ruleAd',
  '/system/source': 'system:robotAd',
  '/system/destination': 'system:robotAd',
  '/system/source/:robotId': 'system:robotAd',
  '/system/destination/:robotId': 'system:robotAd',
};

/**
 * 校验权限
 * @param session 当前登录用户
 * @param requiredPrivileges 需要的权限
 * @returns 布尔值
 */
export const checkPermission = (session: Session, requiredPrivileges: string[]): Boolean => {
  if (session.user.roles.includes(BuiltInSuperManager)) {
    return true;
  }
  return intersection(session.privileges, requiredPrivileges).length > 0;
};

/**
 * 根据路径校验权限
 * @param session 当前登录用户
 * @param path 需要校验的路径
 * @returns 布尔值
 */
export const checkPermissionWithPath = (session: Session, path: string): Boolean => {
  if (session.user.roles.includes(BuiltInSuperManager)) {
    return true;
  }
  return !routePrivilegeMap[path] || checkPermission(session, [routePrivilegeMap[path]]);
};

/**
 * 查找第一个允许访问的路由
 * @param session 当前登录用户
 * @param path 需要校验的路径
 * @returns 路径
 */
export const findFirstAllowAccessRoute = (session: Session, path: string) => {
  if (session.user.roles.includes(BuiltInSuperManager)) {
    return true;
  }
  if (['/system/user', '/system/role'].includes(path)) {
    return session.user.roles.includes(BuiltInSuperManager);
  }
  const matchRoutes = keys(routePrivilegeMap).filter(
    (item) => (startsWith(item, path) || startsWith(path, item)) && !includes(item, ':')
  );
  return matchRoutes.find((item) => checkPermissionWithPath(session, item));
};
