import { MenuUnfoldOutlined } from '@ant-design/icons';
import { Button, Divider, Drawer, Empty, Popconfirm, Space } from 'antd';
import { isEmpty } from 'lodash';
import React from 'react';
import { styled } from 'styled-components';

import { WatchWarningMode, WatchWarningModeSelect } from 'src/features/warning';
import { useSlice } from 'src/lib/react-slice';

import { BatchModal } from './batch.modal';
import { useMonitor } from './monitor.provider';
import { WarningCard } from './warning-card';

const drawStyles = { body: { padding: '12px 0' } };

type WarningDrawProps = {
  onClose: () => void;
};

type State = {
  showBatchModal: boolean;
  watchMode: WatchWarningMode;
};

const initState: State = {
  showBatchModal: false,
  watchMode: WatchWarningMode.ALL,
};

const reducer = {
  openBatchModal: (state: State) => {
    state.showBatchModal = true;
  },
  closeBatchModal: (state: State) => {
    state.showBatchModal = false;
  },
  setWatchMode: (state: State, watchMode: WatchWarningMode) => {
    state.watchMode = watchMode;
  },
};

export const WarningDraw: React.FC<WarningDrawProps> = (props) => {
  const [{ settings, warnings }, monitorDispatch] = useMonitor();
  const [{ showBatchModal, watchMode }, dispatch] = useSlice(reducer, initState);

  const watched =
    !isEmpty(settings.motor.gantryMap) ||
    !isEmpty(settings.nonMotor.gantryMap) ||
    !isEmpty(settings.motor.gantries) ||
    !isEmpty(settings.nonMotor.gantries);

  return (
    <Drawer
      open={true}
      placement="right"
      width={460}
      mask={false}
      getContainer={false}
      closable={false}
      styles={drawStyles}
    >
      <WarningsDrawerBox>
        <div className="header">
          <Space align="center">
            <Button
              type="text"
              icon={<MenuUnfoldOutlined style={{ fontSize: 22, transform: 'translateY(2px)', opacity: 0.6 }} />}
              onClick={props.onClose}
            />
            <WatchWarningModeSelect style={{ width: 166 }} onChange={dispatch.setWatchMode} />
          </Space>

          <Space style={{ paddingRight: 24 }}>
            <Button type="primary" size="small" onClick={dispatch.openBatchModal}>
              调整布控
            </Button>

            <Popconfirm title="你确定要取消所有布控吗？" onConfirm={monitorDispatch.reset}>
              <Button danger size="small" disabled={!watched}>
                取消布控
              </Button>
            </Popconfirm>
          </Space>
        </div>

        <Divider style={{ margin: '12px 0' }} />

        <div className="warnings">
          {isEmpty(warnings) ? (
            <Empty description="暂无数据" />
          ) : (
            warnings[watchMode].map((warning) => <WarningCard warning={warning} key={warning.id} />)
          )}
        </div>
      </WarningsDrawerBox>
      {showBatchModal && <BatchModal onClose={dispatch.closeBatchModal} />}
    </Drawer>
  );
};

const WarningsDrawerBox = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  .header {
    padding: 12px 24px;
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 4px;
  }

  .warnings {
    padding: 0 24px;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
  }
`;
