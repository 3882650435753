import { Layout, Menu, theme } from 'antd';
import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import styled from 'styled-components';

import { useSession } from 'src/features/session';

import { menuItemsReduce } from './main';

export const getMenuItems = () => {
  return [
    {
      key: 'basic-data',
      path: '/report/basic-data',
      label: <NavLink to="basic-data">基础数据统计</NavLink>,
    },
    {
      key: 'illegal-vehicle',
      path: '/report/illegal-vehicle',
      label: <NavLink to="illegal-vehicle">高频违法车</NavLink>,
    },
    {
      key: 'vehicle-property-store',
      path: '/report/vehicle-property-store',
      label: <NavLink to="vehicle-property-store">车辆使用性质统计</NavLink>,
    },
  ];
};

/**
 * 报表模块的布局
 *
 * 左侧菜单
 * 右侧内容
 */

const ReportLayout: React.FC = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const session = useSession();
  const items = menuItemsReduce(session, getMenuItems());

  return (
    <Layout style={{ padding: '24px 0', background: colorBgContainer }}>
      <Layout.Sider style={{ background: colorBgContainer }} width={200}>
        <NavMenu mode="inline" openKeys={['source', 'destination']} style={{ height: '100%' }} items={items} />
      </Layout.Sider>
      <Layout.Content style={{ padding: '0 24px', minHeight: 280 }}>
        <Outlet />
      </Layout.Content>
    </Layout>
  );
};

export default ReportLayout;

const NavMenu = styled(Menu)`
  li.ant-menu-submenu,
  li.ant-menu-item {
    overflow: visible !important;

    .ant-menu-submenu-title {
      margin-left: 0px;
      margin-right: 0px;
      padding-right: 16px;
      border-radius: 0;
    }

    span.ant-menu-title-content {
      overflow: visible !important;

      a {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 20px 0 28px;
        color: #575757;

        margin-left: -28px;
        margin-right: -20px;
      }

      a.active {
        background: #f5faff;
        color: #1890ff;
        border-right: 2px solid #1890ff;
      }
    }
  }

  li.ant-menu-submenu > .ant-menu-submenu-title,
  li.ant-menu-submenu-selected > .ant-menu-submenu-title {
    color: #aaa !important;
    font-weight: 500;

    i.ant-menu-submenu-arrow {
      display: none;
    }
  }

  ul.ant-menu-sub {
    background: inherit !important;

    li.ant-menu-item {
      span.ant-menu-title-content {
        a {
          margin-left: -52px;
          padding: 0 20px 0 52px;
        }
      }
    }
  }
`;
