import { AggregateWarningsRequest, GetWarningRequest, ListWarningsRequest, WarningState } from '@36node-fcp/core-sdk';
import { Badge, Select, SelectProps } from 'antd';
import React from 'react';

import { useQuery } from 'src/lib/react-api';
import { fcp } from 'src/services';

/**
 * 预警是否来自黑名单
 */

export const WarningBlacklistFilter = [
  { text: '是', value: true },
  { text: '否', value: false },
];

/**
 * 车辆预警状态
 */

type WarningStateUi = {
  text: string;
  color: string;
  value: string;
};

export const WarningStateUiList: WarningStateUi[] = [
  { text: '已处理', color: '#25D2A0', value: WarningState.CLOSED },
  { text: '待处理', color: '#FAAD14', value: WarningState.OPEN },
];

export const WarningStateFilter = WarningStateUiList;
export const toWarningStateText = (value: string) => WarningStateUiList.find((item) => item.value === value)?.text;
export const toWarningStateValue = (text: string) => WarningStateUiList.find((item) => item.text === text)?.value;

export const WarningStateBadge: React.FC<{ state: string }> = ({ state }) => {
  const ui = WarningStateUiList.find((item) => item.value === state);
  if (!ui) return null;
  return <Badge color={ui.color} text={ui.text} />;
};

/**
 * 获取车辆预警记录列表
 * @param req 车辆预警列表请求参数
 * @returns [state, listWarnings]
 */
export const useWarningList = (req?: ListWarningsRequest) => {
  const [state, listWarnings] = useQuery(fcp.listWarnings, req || {});
  return [state, listWarnings] as const;
};

export const useWarning = (req?: GetWarningRequest) => {
  const [state, getWarning] = useQuery(fcp.getWarning, req);
  return [state, getWarning] as const;
};

/**
 * 预警监听
 */
export enum WatchWarningMode {
  ALL = 'all',
  MOTOR = 'motor',
  NON_MOTOR = 'nonMotor',
}

export const WatchWarningModeUiList = [
  { text: '全部 ', value: WatchWarningMode.ALL },
  { text: '机动车', value: WatchWarningMode.MOTOR },
  { text: '非机动车及行人', value: WatchWarningMode.NON_MOTOR },
];

export const WatchWarningModeSelect = (props: SelectProps) => {
  return (
    <Select defaultValue={WatchWarningMode.ALL} {...props}>
      {WatchWarningModeUiList.map((item) => (
        <Select.Option key={item.value} value={item.value}>
          {item.text}
        </Select.Option>
      ))}
    </Select>
  );
};

/**
 * 获取预警统计
 */
export const useWarningsAggregation = (req: AggregateWarningsRequest) => {
  const [state, aggregateWarnings] = useQuery(fcp.aggregateWarnings, req || {});
  return [state, aggregateWarnings] as const;
};
