import { ApiError, ListSectionsRequest } from '@36node-fcp/core-sdk';
import { Select, SelectProps } from 'antd';

import { useQuery } from 'src/lib/react-api';
import { fcp } from 'src/services';

/**
 * Api Error
 */
export const SectionApiErrorMap: { [key: string]: { [key: string]: string } } = {
  [ApiError.Code.DUPLICATE]: {
    name: '路段名已存在',
  },
};

/**
 * 获取路段管理列表
 * @param req 路段管理列表请求参数
 * @returns [apiState, actions]
 */
export const useRoadSectionList = (req?: ListSectionsRequest) => {
  const [state, listRoadSections] = useQuery(fcp.listSections, req || {});
  return [state, listRoadSections] as const;
};

/**
 * 路段下拉选择组件
 */
export function RoadSectionSelect(props: SelectProps) {
  const [{ result: gantrySections = [] }] = useRoadSectionList({ _limit: 1000 });

  return (
    <Select
      {...props}
      placeholder="请选择所属路段"
      filterOption={(input, option) => option?.item.name.toLowerCase().includes(input.toLowerCase())}
      popupMatchSelectWidth={false}
    >
      {gantrySections?.map((item) => (
        <Select.Option key={item.id} value={item.id} item={item}>
          {item.name}
        </Select.Option>
      ))}
    </Select>
  );
}
