import { ApiError, FcpAPIClient } from '@36node-fcp/core-sdk';
import axios, { AxiosResponse } from 'axios';
import { isEmpty } from 'lodash';

import { FCP_ENDPOINT } from 'src/config';
import { paramsSerializer } from 'src/lib/url';
import { injectToken } from 'src/sdk/helpers';

import { getToken, removeSession, removeSessionId } from './stargate';

const fcpInstance = axios.create({ baseURL: FCP_ENDPOINT, paramsSerializer });
fcpInstance.interceptors.request.use(injectToken(getToken));
fcpInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      removeSession();
      removeSessionId();
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

type NormalizedError = {
  code?: string;
  keys?: string[];
  value?: any;
  message: string;
};

export class Fcp extends FcpAPIClient {
  batchDeleteVehicleByIds = async (ids: string[]) => {
    const resp = await Promise.all(ids.map((id) => this.deleteVehicle({ vehicleId: id })));
    let response: AxiosResponse<void> = resp[0] as any;
    return response;
  };
}

export const fcp = new Fcp(fcpInstance);

export const passApiErrors = (err: ApiError, map: { [key: string]: { [key: string]: string } }): NormalizedError => {
  const { keyValue, code, message } = err;
  if (!keyValue || isEmpty(keyValue)) return err;
  const keys = Object.keys(keyValue);
  return {
    keys,
    code,
    value: keyValue,
    message: map[code]?.[String(keys)] || message,
  };
};
