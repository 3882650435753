import { Error, HealthStat, ListHealthStatsRequest } from '@36node-fcp/core-sdk';
import { Button, Form, Input } from 'antd';
import { useMemo } from 'react';

import { AdColumnsType, AdTable } from 'src/components/antd/ad-table';
import { HealthStatBadge, HealthStatFilter, toHealthStatText, useHealthStatList } from 'src/features/health-stat';
import { useSearch } from 'src/hook/search';
import { ymdhms } from 'src/lib/lang/time';
import { useSlice } from 'src/lib/react-slice';

import HealthStatDetailPage from './system-health.detail';

type SearchValues = {} & ListHealthStatsRequest;
type SearchFormProps = {
  onSearch: (values: SearchValues) => void;
  initialValues?: SearchValues;
};
const defaultQuery: SearchValues = { _limit: 10, _offset: 0, _sort: '-createAt' };

const SearchForm: React.FC<SearchFormProps> = ({ onSearch, initialValues }) => {
  return (
    <Form onFinish={onSearch} initialValues={initialValues} layout={'inline'}>
      <Form.Item name="recordKey_like">
        <Input placeholder="关键字查询" allowClear />
      </Form.Item>
    </Form>
  );
};

type State = {
  showMoreVisible: boolean;
  healthStat?: HealthStat;
};

const initialState: State = {
  showMoreVisible: false,
};

const reducers = {
  openShowMore(state: State, healthStat?: HealthStat) {
    state.showMoreVisible = true;
    state.healthStat = healthStat;
  },
  closeShowMore(state: State) {
    state.showMoreVisible = false;
    state.healthStat = undefined;
  },
};

const SystemHealthPage: React.FC = () => {
  const [query, setQuery] = useSearch(defaultQuery);
  const [{ result, loading, request = {}, total }, listHealthStats] = useHealthStatList(query);
  const { _limit: limit = 10, _offset: offset = 0 } = request;
  const [{ showMoreVisible, healthStat }, dispatch] = useSlice(reducers, initialState);

  // 搜索、分页、排序触发
  const handleSearch = (values: SearchValues) => {
    setQuery({
      ...request, // 上一次留存的查询条件
      _offset: 0, // 重置页码
      ...values, // 本次输入的查询条件，取消的条件用 undefined 覆盖上一次的查询条件
    });
  };

  const handleRefresh = () => listHealthStats(request);

  const columns = useMemo<AdColumnsType<HealthStat>>(
    () => [
      {
        title: '入库时间',
        dataIndex: 'createAt',
        compute: ymdhms,
      },
      {
        title: '类型',
        dataIndex: 'type',
        compute: toHealthStatText,
        filters: HealthStatFilter,
        filterMultiple: false,
        render: (value) => <HealthStatBadge type={value} />,
      },
      {
        title: '来源',
        dataIndex: 'source',
      },
      {
        title: '关键字',
        dataIndex: 'recordKey',
      },
      {
        title: '错误字段',
        dataIndex: 'errors',
        render: (value) => value.map((item: Error, index) => <div key={index}>{item.property}</div>),
      },
      {
        title: '操作',
        render: (node, record) => (
          <>
            <Button type="link" style={{ padding: 0 }} onClick={() => dispatch.openShowMore(record)}>
              详情
            </Button>
          </>
        ),
      },
    ],
    [dispatch]
  );
  return (
    <>
      <AdTable
        columns={columns}
        title="MQTT数据错误"
        rowKey="id"
        loading={loading}
        dataSource={result}
        scroll={{ x: 'max-content' }}
        onChange={handleSearch}
        onRefresh={handleRefresh}
        pagination={{
          total,
          current: offset / limit + 1,
          pageSize: limit,
        }}
        extraTools={<SearchForm onSearch={handleSearch} initialValues={query} />}
      />
      {showMoreVisible && <HealthStatDetailPage record={healthStat} onClose={dispatch.closeShowMore} />}
    </>
  );
};

export default SystemHealthPage;
