import { ApiError, CreateLawEnforcePointDto, LawEnforcePoint, ListLawEnforcePointsRequest } from '@36node-fcp/core-sdk';
import { Button, Divider, Form, Input, message, Popconfirm } from 'antd';
import { AxiosError } from 'axios';
import { useMemo } from 'react';

import { AdColumnsType, AdTable } from 'src/components/antd/ad-table';
import { ExportModal } from 'src/components/xlsx-export-modal';
import { ImportModal } from 'src/components/xlsx-import-modal';
import { LawEnforcePointApiErrorMap, useLawEnforcePointList } from 'src/features/law-enforce-point';
import { useSearch } from 'src/hook/search';
import { useApi } from 'src/lib/react-api';
import { useSlice } from 'src/lib/react-slice';
import { ImportValidateError } from 'src/lib/react-xlsx';
import { fcp, passApiErrors } from 'src/services';

import { LawEnforcePointEditor } from './law-enforce-point.editor';

type SearchValues = {} & ListLawEnforcePointsRequest;
type SearchFormProps = {
  onSearch: (values: SearchValues) => void;
  initialValues?: SearchValues;
};

const defaultQuery: ListLawEnforcePointsRequest = { _limit: 10, _offset: 0, _sort: '-createAt' };

const SearchForm: React.FC<SearchFormProps> = ({ onSearch, initialValues }) => {
  return (
    <Form onFinish={onSearch} initialValues={initialValues} layout={'inline'}>
      <Form.Item name="name_like">
        <Input placeholder="执法点名查询" allowClear />
      </Form.Item>
    </Form>
  );
};

type State = {
  exportVisible: boolean;
  importVisible: boolean;
  editorVisible: boolean;
  editLawEnforcePoint?: LawEnforcePoint;
};

const initState: State = {
  exportVisible: false,
  importVisible: false,
  editorVisible: false,
};

const reducers = {
  openExport(state: State) {
    state.exportVisible = true;
  },
  closeExport(state: State) {
    state.exportVisible = false;
  },
  openImport(state: State) {
    state.importVisible = true;
  },
  closeImport(state: State) {
    state.importVisible = false;
  },
  openEditor(state: State, lawEnforcePoint?: LawEnforcePoint) {
    state.editorVisible = true;
    state.editLawEnforcePoint = lawEnforcePoint;
  },
  closeEditor(state: State) {
    state.editorVisible = false;
    state.editLawEnforcePoint = undefined;
  },
};

const upload = async (doc: CreateLawEnforcePointDto) =>
  fcp.upsertLawEnforcePoint({ body: doc }).catch((err: AxiosError<ApiError>) => {
    throw passApiErrors(err.response.data, LawEnforcePointApiErrorMap);
  });

const beforeUpload = async (records: CreateLawEnforcePointDto[]) => {
  const codeSet = new Set();
  const nameSet = new Set();
  const errors: ImportValidateError[] = [];
  records.forEach(({ code, name }, index) => {
    if (codeSet.has(code)) {
      errors.push({
        row: index + 2,
        message: '文件内执法点编号重复',
      });
    }
    if (nameSet.has(name)) {
      errors.push({
        row: index + 2,
        message: '文件内执法点名重复',
      });
    }
    codeSet.add(code);
    nameSet.add(name);
  });
  return errors;
};

const toQuery = (values: SearchValues): ListLawEnforcePointsRequest => {
  return { ...defaultQuery, ...values };
};

/**
 * 执法点管理页面
 */
const LawEnforcePointPage: React.FC = () => {
  const [search, setSearch] = useSearch<SearchValues>();
  const [{ result, loading, request = {}, total }, listLawEnforcePointRecords] = useLawEnforcePointList(
    toQuery(search)
  );
  const { _limit: limit = 10, _offset: offset = 0 } = request;
  const [{ exportVisible, importVisible, editorVisible, editLawEnforcePoint }, dispatch] = useSlice(
    reducers,
    initState
  );

  const deleteOptions = useMemo(
    () => ({
      onSuccess: () => {
        message.success('删除成功');
        listLawEnforcePointRecords(request);
      },
      onFailure: (err) => {
        message.error(`删除失败: ${err.message}`);
      },
    }),
    [request]
  );
  const [deleteState, deleteIllegalType] = useApi(fcp.deleteLawEnforcePoint, deleteOptions);

  // 搜索、分页、排序触发
  const handleSearch = (values: SearchValues) => {
    setSearch({
      ...search, // 上一次留存的查询条件
      _offset: 0, // 重置页码
      ...values, // 本次输入的查询条件，取消的条件用 undefined 覆盖上一次的查询条件
    });
  };

  const handleRefresh = () => listLawEnforcePointRecords(request);

  const columns = useMemo<AdColumnsType<LawEnforcePoint>>(
    () => [
      {
        title: '执法点编号',
        dataIndex: 'code',
        rules: [{ required: true, message: '请填写执法点编号' }],
        import: String,
      },
      {
        title: '执法点名',
        dataIndex: 'name',
        rules: [{ required: true, message: '请填写执法点名' }],
      },
      {
        title: '操作',
        render: (node, record) => (
          <>
            <Button type="link" style={{ padding: 0 }} onClick={() => dispatch.openEditor(record)}>
              编辑
            </Button>
            <Divider type="vertical" />
            <Popconfirm
              title="你确定要删除这行内容吗？"
              onConfirm={() => deleteIllegalType({ lawEnforcePointId: record.id })}
            >
              <Button type="link" danger style={{ padding: 0 }} loading={deleteState.loading}>
                删除
              </Button>
            </Popconfirm>
          </>
        ),
      },
    ],
    [dispatch, deleteIllegalType, search]
  );

  return (
    <>
      <AdTable
        columns={columns}
        title="执法点管理"
        rowKey="id"
        loading={loading}
        scroll={{ x: 'max-content' }}
        dataSource={result}
        onAddNew={() => dispatch.openEditor()}
        onChange={handleSearch}
        onRefresh={handleRefresh}
        onExport={dispatch.openExport}
        onUpload={dispatch.openImport}
        pagination={{
          total,
          current: offset / limit + 1,
          pageSize: limit,
        }}
        showColumnsFilter={false}
        extraTools={<SearchForm onSearch={handleSearch} initialValues={search} />}
      />
      {exportVisible && (
        <ExportModal
          api={fcp.listLawEnforcePoints}
          args={request}
          columns={columns}
          filename="执法点.xlsx"
          onClose={dispatch.closeExport}
          title="执法点导出"
          total={total}
        />
      )}
      {importVisible && (
        <ImportModal
          api={upload}
          columns={columns}
          beforeUpload={beforeUpload}
          template="执法点管理导入模板.xlsx"
          onClose={dispatch.closeImport}
          onFinish={handleRefresh}
          title="执法点导入"
        />
      )}
      {editorVisible && (
        <LawEnforcePointEditor
          onClose={dispatch.closeEditor}
          onFinish={handleRefresh}
          lawEnforcePoint={editLawEnforcePoint}
        />
      )}
    </>
  );
};

export default LawEnforcePointPage;
