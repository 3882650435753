import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';
import type { RcFile, UploadProps } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';
import { isArray } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';

import { ImportStatus, ImportValidateError, useImport } from 'src/lib/react-xlsx';
import { useTemplate } from 'src/lib/react-xlsx/template';
import { getFileNameUUID, getFileSuffix, mutiload } from 'src/lib/s3';

import { OssFile } from '.';
import { XLSXPreviewModal } from '../xlsx-preview-modal';

type XLSXUploadFile = OssFile & {
  rows?: any[];
};

function getTip(status: ImportStatus, error?: Error, total: number = 0) {
  switch (status) {
    case ImportStatus.INIT:
      return `准备导入`;
    case ImportStatus.READING:
      return '正在读取文件...';
    case ImportStatus.VALIDATING:
      return '正在校验文件...';
    case ImportStatus.INVALID:
      return '文件校验失败';
    case ImportStatus.VALID:
      return '文件读取成功';
    case ImportStatus.UPLOADING:
      return '正在上传...';
    case ImportStatus.SUCCESS:
      return `成功导入${total}条数据`;
    case ImportStatus.FAILURE:
      return error?.message || '导入失败';
  }
}

function getColor(status: ImportStatus) {
  switch (status) {
    case ImportStatus.INVALID:
    case ImportStatus.FAILURE:
      return 'red';
    default:
      return;
  }
}

interface XLSXUploaderProp {
  onChange?: (value: XLSXUploadFile[] | XLSXUploadFile | string | string[]) => void;
  value?: XLSXUploadFile[] | XLSXUploadFile | string | string[];
  multiple?: boolean;
  mode?: 'file' | 'string';
  template?: string;
  beforeUpload?: (records: any[]) => Promise<ImportValidateError[] | void>;
  columns?: any[];
  label?: string;
}

export function XLSXUploader({
  onChange,
  value,
  multiple = false,
  mode = 'string',
  template,
  beforeUpload,
  columns,
  label,
}: XLSXUploaderProp) {
  if (value && ((multiple === false && isArray(value)) || (multiple === true && !isArray(value)))) {
    throw new Error('请传递正确的multiple和value！');
  }
  const [{ total, status, error, validateErrors, rows }, dispatch] = useImport<any>(undefined, columns, {
    onFileLoad: beforeUpload,
  });

  const [previewFile, setPreviewFile] = useState<UploadFile>(null);

  const [, downloadTemplate] = useTemplate(columns, template);

  const handleBeforeUpload = async (file: RcFile) => {
    return (await dispatch.read(file)) ? true : Upload.LIST_IGNORE;
  };

  const initFile = useMemo(() => {
    if (!value) return [];
    return (isArray(value) ? value : [value]).map(
      (xlsxFile, i) =>
        ({
          uid: i.toString(),
          name: typeof xlsxFile === 'string' ? xlsxFile : xlsxFile.name,
          status: 'done',
          url: typeof xlsxFile === 'string' ? xlsxFile : xlsxFile.url,
          rows: typeof xlsxFile === 'string' ? [] : xlsxFile.rows,
        }) as UploadFile
    );
  }, [value]);

  const [fileList, setFileList] = React.useState<UploadFile[]>([]);

  useEffect(() => {
    if (initFile.length === 0) return;
    setFileList(initFile);
  }, [initFile]);

  const customRequest = async (options: any) => {
    const { file, onSuccess, onProgress, onError } = options;
    // 生成的文件名，保留文件后缀名，进行拼接
    const objName = getFileNameUUID() + getFileSuffix(file.name);

    // // 调用 ali-oss 中的方法,flieName是存放的文件夹名称，可自己定义
    const res = await mutiload(`${objName}`, file, onProgress);
    if (!res.errCode) {
      file.url = res.url;
      onSuccess(res, file);
    } else {
      onError(res.error);
    }
  };

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    if (multiple) {
      setFileList(newFileList);
    } else {
      setFileList(newFileList.slice(0, 1));
    }
    if (mode === 'file') {
      const ossFiles = newFileList.map((file) => ({ name: file.name, url: file.url, rows: rows }) as OssFile);
      return onChange(multiple ? ossFiles : ossFiles[0]);
    }
    const files = newFileList.map((file) => file.url);
    return onChange(multiple ? files : files[0]);
  };

  return (
    <>
      <Upload
        fileList={fileList}
        onChange={handleChange}
        beforeUpload={handleBeforeUpload}
        customRequest={customRequest}
        accept=".xls, .xlsx"
        showUploadList={{
          showDownloadIcon: true,
        }}
        onPreview={(file) => setPreviewFile(file)}
      >
        {!multiple && fileList.length >= 1 ? null : <Button icon={<UploadOutlined />}>上传文件</Button>}
      </Upload>
      {template && (
        <Button type="link" onClick={() => downloadTemplate()}>
          下载文件模板
        </Button>
      )}
      {status && status !== ImportStatus.INIT && (
        <p style={{ color: getColor(status) }}>{getTip(status, error, total)}</p>
      )}
      {validateErrors.slice(0, 20).map((err, index) => (
        <p key={index}>
          第{err.row}行: {err.message}
        </p>
      ))}
      {previewFile && (
        <XLSXPreviewModal
          width={600}
          url={previewFile.url}
          title={label || '放行车辆名单'}
          onClose={() => setPreviewFile(null)}
          columns={columns}
        />
      )}
    </>
  );
}
