import { Gantry, GantryDevice } from '@36node-fcp/core-sdk';
import { Button, message, Popconfirm, Space, Spin, Typography } from 'antd';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import styled from 'styled-components';

import { useGantryDeviceList } from 'src/features/gantry-device';
import { useApi } from 'src/lib/react-api';
import { fcp } from 'src/services';

import { GantryDeviceEditor } from './gantry-device.editor';

const DeviceLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  margin: 0 64px;
  border-bottom: 1px solid #eee;
`;

interface IGantryDeviceListProps {
  gantry: Gantry;
  isEditorOpen: boolean;
  onEditorClose: (gantryId: string) => void;
  onEditorOpen: (gantryId: string) => void;
}

const EmptyDeviceList = () => (
  <DeviceLine>
    <Typography.Text type="secondary">暂无设备</Typography.Text>
  </DeviceLine>
);

const GantryDeviceList = ({ gantry, isEditorOpen, onEditorClose, onEditorOpen }: IGantryDeviceListProps) => {
  const [{ result: devices = [], loading }, listGantryDevices] = useGantryDeviceList({ gantry: [gantry?.id] });
  const [currentDevice, setCurrentDevice] = useState<GantryDevice | null>(null);

  const handleRefresh = () => listGantryDevices({ _limit: 10, _offset: 0, gantry: [gantry?.id] });

  const [, deleteGantryDevice] = useApi(fcp.deleteGantryDevice, {
    onSuccess: () => {
      message.success('删除成功');
      handleRefresh();
    },
    onFailure: (err) => {
      message.error(`删除失败: ${err.message}`);
    },
  });

  if (loading) {
    return <Spin />;
  }

  const deviceList = devices.map((device) => (
    <DeviceLine key={device.id}>
      <div>{device.name}</div>

      <Space>
        <Button
          type="link"
          style={{
            padding: 0,
          }}
          onClick={() => {
            setCurrentDevice(device);
            onEditorOpen(gantry.id);
          }}
        >
          编辑
        </Button>

        <Popconfirm title="确定删除吗" onConfirm={() => deleteGantryDevice({ gantryDeviceId: device.id })}>
          <Button
            type="link"
            danger
            style={{
              padding: 0,
            }}
          >
            删除
          </Button>
        </Popconfirm>
      </Space>
    </DeviceLine>
  ));

  return (
    <>
      {isEmpty(devices) ? <EmptyDeviceList /> : deviceList}
      {isEditorOpen && (
        <GantryDeviceEditor
          onClose={() => {
            setCurrentDevice(null);
            onEditorClose(gantry.id);
          }}
          onFinish={() => {
            handleRefresh();
          }}
          currentGantry={gantry}
          gantryDevice={currentDevice}
        />
      )}
    </>
  );
};

export default GantryDeviceList;
