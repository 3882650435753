import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import styled from 'styled-components';

import { useRules } from 'src/features/rule';
import { useSlice } from 'src/lib/react-slice';

import { CustomIllegalTypePage } from './custom-illegal-type.page';
import { RuleEntityEditor } from './rule.editor';

type State = {
  editorVisible: boolean;
  editIndex?: number;
};

const initState: State = {
  editorVisible: false,
  editIndex: -1,
};

const reducers = {
  openEditor(state: State, editIndex?: number) {
    state.editorVisible = true;
    state.editIndex = editIndex;
  },
  closeEditor(state: State) {
    state.editorVisible = false;
    state.editIndex = -1;
  },
};

const RulePage: React.FC = () => {
  const [{ editorVisible, editIndex }, dispatch] = useSlice(reducers, initState);

  const [{ result: rules }, listRules] = useRules();

  return (
    <Container>
      <Top>
        <div className="title">
          车辆自定义违法转换
          <Tooltip placement="right" title="规则顺序代表执行优先级，可用拖拽方式改变优先级">
            <InfoCircleOutlined style={{ marginLeft: 6 }} />
          </Tooltip>
        </div>
        <Button type="primary" onClick={() => dispatch.openEditor()}>
          新建规则
        </Button>
      </Top>
      <CustomIllegalTypePage
        records={rules?.entities}
        onEdit={(index) => dispatch.openEditor(index)}
        onRefresh={() => listRules({})}
      />
      {editorVisible && (
        <RuleEntityEditor
          onClose={dispatch.closeEditor}
          onFinish={() => listRules({})}
          records={rules?.entities}
          index={editIndex}
        />
      )}
    </Container>
  );
};
export default RulePage;

const Container = styled.div`
  margin: 16px 24px;
`;
const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  .title {
    font-size: 1rem;
    font-weight: 600;
    color: rgb(0, 0, 0, 0.88);
    line-height: 1.5;
  }
`;
