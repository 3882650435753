import { ApiError, RuleEntity, RuleType, Subject } from '@36node-fcp/core-sdk';
import { Form, Input, message, Modal } from 'antd';
import { cloneDeep } from 'lodash';
import { useState } from 'react';
import styled from 'styled-components';

import { GantryAttrSelect } from 'src/features/gantry';
import { GantryDeviceSelect } from 'src/features/gantry-device';
import { VehiclePropertySelect } from 'src/features/illegal';
import { CustomIllegalTypeMaxMinRate, CustomIllegalTypeTotal, IllegalTypeSelect } from 'src/features/illegal-type';
import { CustomIllegalTypeSelect } from 'src/features/rule';
import { useApi } from 'src/lib/react-api';
import { fcp } from 'src/services';

type EditorValues = RuleEntity & {
  carWayCodesString?: string;
};

interface RuleEntityEditorProps {
  records?: RuleEntity[];
  index?: number;
  onClose: () => void;
  onFinish?: () => void;
}

export const RuleEntityEditor: React.FC<RuleEntityEditorProps> = ({
  records,
  index,
  onClose: handleClose,
  onFinish: handleFinish,
}) => {
  const isEdit = index >= 0 && index < records.length;
  const [form] = Form.useForm<EditorValues>();
  const ruleName = Form.useWatch('name', form);

  const onSuccess = () => {
    message.success(isEdit ? '编辑成功' : '创建成功');
    handleClose();
    handleFinish?.();
  };

  const onFailure = (err: ApiError) => {
    const { message: msg } = err;
    console.error('失败原因:', msg);
    if (isEdit) {
      message.error('规则编辑失败');
    } else {
      message.error('规则创建失败');
    }
  };
  const [updateState, update] = useApi(fcp.updateRule, { onSuccess, onFailure });

  const handleOk = () => form.submit();
  const handleSubmit = async (values: EditorValues) => {
    const { carWayCodesString, ...ruleEntity } = values;
    const data = cloneDeep(records) || [];
    const saveValues: RuleEntity = {
      ...ruleEntity,
      params: {
        max: ruleEntity.params?.max && ruleEntity.params?.max / 100,
        min: ruleEntity.params?.min && ruleEntity.params?.min / 100,
        expire: ruleEntity.params?.expire,
        count: ruleEntity.params?.count,
      },
      carWayCodes: carWayCodesString?.split(';'),
    };
    if (isEdit) {
      data[index] = saveValues;
    } else {
      data.push(saveValues);
    }

    update({
      body: {
        entities: data,
      },
    });
  };

  const isLoading = updateState.loading;
  const title = isEdit ? '编辑规则' : '创建规则';
  const initialValues: any = isEdit
    ? {
        ...records[index],
        params: {
          max: records[index].params?.max ? Math.round(records[index].params?.max * 100) : null,
          min: records[index].params?.min ? Math.round(records[index].params?.min * 100) : null,
          expire: records[index].params?.expire,
          count: records[index].params?.count,
        },
        carWayCodesString: records[index].carWayCodes?.join(';'),
      }
    : {};

  const [name, setName] = useState(initialValues ? initialValues.name : 0);

  const handleTypeChange = (value) => {
    setName(value);
  };

  return (
    <Modal title={title} open onOk={handleOk} onCancel={handleClose} confirmLoading={isLoading}>
      <Container>
        <Form
          name="rule-editor"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          initialValues={initialValues}
          autoComplete="off"
          onFinish={handleSubmit}
          form={form}
        >
          <Form.Item label="类型" name="name" rules={[{ required: true, message: '请选择规则类型' }]}>
            <CustomIllegalTypeSelect
              style={{ width: '100%' }}
              placeholder="请选择规则类型"
              disabled={isEdit}
              onChange={handleTypeChange}
            />
          </Form.Item>
          <Form.Item
            label="源违法类型"
            name="inputIllegalCodes"
            rules={[{ required: true, message: '请选择源违法类型' }]}
          >
            <IllegalTypeSelect
              subject={[Subject.VEHICLE]}
              mode="multiple"
              style={{ width: '100%' }}
              placeholder="请选择源违法类型"
            />
          </Form.Item>
          {name !== RuleType.DROP && name !== RuleType.ACCURATE_DROP && (
            <Form.Item
              label="目标违法类型"
              name="outputIllegalCode"
              rules={[{ required: true, message: '请选择目标违法类型' }]}
            >
              <IllegalTypeSelect
                subject={[Subject.VEHICLE]}
                style={{ width: '100%' }}
                placeholder="请选择目标违法类型"
              />
            </Form.Item>
          )}
          {name === RuleType.ACCURATE_DROP && (
            <Form.Item label="设备" name="gantryDevices" rules={[{ required: true, message: '请选择设备' }]}>
              <GantryDeviceSelect placeholder="请选择设备" mode="multiple" />
            </Form.Item>
          )}
          {name !== RuleType.ACCURATE_DROP && (
            <Form.Item label="卡口类型" name="gantryAttr">
              <GantryAttrSelect mode="multiple" style={{ width: '100%' }} placeholder="请选择卡口类型" />
            </Form.Item>
          )}
          <Form.Item label="车辆性质" name="vehicleProperty">
            <VehiclePropertySelect mode="multiple" style={{ width: '100%' }} placeholder="请选择车辆性质" />
          </Form.Item>
          {name !== RuleType.ACCURATE_DROP && (
            <Form.Item
              label="条件"
              name="params"
              rules={[{ required: ruleName !== RuleType.DROP, message: '请填写条件' }]}
            >
              {ruleName === RuleType.GRANDTOTAL ? (
                <CustomIllegalTypeTotal />
              ) : (
                <CustomIllegalTypeMaxMinRate ruleType={ruleName} />
              )}
            </Form.Item>
          )}
          {ruleName === RuleType.DROP && (
            <Form.Item
              label="车道"
              name="carWayCodesString"
              rules={[
                {
                  pattern: /^([1-9]-[1-9])(;[1-9]-[1-9])*$/,
                  message: '车道编号无效',
                },
              ]}
            >
              <Input placeholder="请输入车道，多个条件用英文的分号分割" />
            </Form.Item>
          )}
        </Form>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  margin: 24px;
`;
