import { Typography } from 'antd';

const { Text } = Typography;

type LineProps = {
  title: string;
  content?: number | string | React.ReactNode;
  formatter?: (value: any) => string;
};

const Line: React.FC<LineProps> = ({ title, content, formatter }) => {
  let text = content;
  if (formatter) {
    text = formatter(content);
  }

  return (
    <div
      style={{
        paddingTop: 8,
        paddingBottom: 8,
        marginRight: 8,
      }}
    >
      <Text strong style={{ marginRight: 8 }}>
        {title}
      </Text>
      <Text>{text || '--'}</Text>
    </div>
  );
};

export const LabelItem: React.FC<LineProps> = ({ title, content, formatter }) => {
  let text = content;
  if (formatter) {
    text = formatter(content);
  }

  return (
    <div
      style={{
        paddingTop: 8,
        paddingBottom: 8,
        marginRight: 8,
      }}
    >
      <Text style={{ marginRight: 8 }}>{title}</Text>
      <Text strong>{text || '--'}</Text>
    </div>
  );
};

export default Line;
