/**
 * 布尔值转换
 */

export const BooleanLabelList = ['是', '否'];

export function boolToCN(val: boolean) {
  return val ? '是' : '否';
}

export function cnToBool(val: string) {
  return val === '是' ? true : false;
}
