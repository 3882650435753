import { ApiError, Gantry, GantryAttr, ListGantriesRequest } from '@36node-fcp/core-sdk';
import { Cascader, CascaderProps, Select, SelectProps } from 'antd';
import { groupBy, isString } from 'lodash';
import { useMemo } from 'react';

import { useQuery } from 'src/lib/react-api';
import { fcp } from 'src/services';

/**
 * api error
 */

export const GantryApiErrorMap: { [key: string]: { [key: string]: string } } = {
  [ApiError.Code.DUPLICATE]: {
    name: '卡口名已存在',
  },
};

/**
 * 卡口属性
 */
export const GantryAttrUiList = [
  { value: GantryAttr.MAINLINE, text: '主线' },
  { value: GantryAttr.INTERCITY, text: '市际卡口' },
  { value: GantryAttr.SVCRAMP, text: '服务区匝道' },
  { value: GantryAttr.EXPORTRAMP, text: '出口匝道' },
  { value: GantryAttr.HUBRAMP, text: '枢纽卡口' },
];

export const GantryAttrTextList = GantryAttrUiList.map((item) => item.text);

export const toGantryAttrText = (value: string) => {
  const item = GantryAttrUiList.find((item) => item.value === value);
  return item ? item.text : '--';
};

export const toGantryAttrValue = (text: string) => {
  const item = GantryAttrUiList.find((item) => item.text === text);
  return item ? item.value : '--';
};

/**
 * 卡口属性选择
 * @param props SelectProps
 * @returns
 */
export function GantryAttrSelect(props: SelectProps) {
  return (
    <Select placeholder="卡口属性" {...props}>
      {GantryAttrUiList.map((item) => (
        <Select.Option key={item.value} value={item.value}>
          {item.text}
        </Select.Option>
      ))}
    </Select>
  );
}

/**
 * 获取单个卡口
 * @param gantryId 卡口 id
 * @returns [apiState, getGantry]
 */
export const useGantry = (gantryId: string) => {
  const [state, getGantry] = useQuery(fcp.getGantry, { gantryId });
  return [state, getGantry] as const;
};

/**
 * 获取卡口记录列表
 * @param req 违法记录列表请求参数
 * @returns [apiState, listGantries]
 */
export const useGantryList = (req?: ListGantriesRequest) => {
  const [state, listGantries] = useQuery(fcp.listGantries, req || {});
  return [state, listGantries] as const;
};

export const GantryProvider: React.FC<{
  id: string;
  children: (gantry: Gantry | undefined) => JSX.Element;
}> = ({ id, children }) => {
  const [{ result }] = useGantry(id);
  return children(result);
};

/**
 * 卡口下拉选择组件
 * 固定位 路段/卡口 的展示形式
 */

const gantrySelectDisplayRender = (labels: string[]) => labels[labels.length - 1];

export function GantryCascaderSelect(props: CascaderProps) {
  const [{ result: gantries = [] }] = useGantryList({ _limit: 1000 });

  const treeData = useMemo(() => {
    const grouped = groupBy(gantries, 'section.name');
    return Object.keys(grouped).map((sectionName) => ({
      value: sectionName === 'undefined' ? '无路段' : sectionName,
      label: sectionName === 'undefined' ? '无路段' : sectionName,
      selectable: false,
      children: grouped[sectionName].map((item) => ({ value: item.id, label: item.name })),
    }));
  }, [gantries]);

  /**
   * 内部 value 数据: [路段名,卡口id]
   * 组件 value 数据: 卡口 id
   */
  const gantry = gantries.find((item) => isString(props.value) && item.id === props.value);
  const value = gantry ? [gantry.section?.name ? gantry.section?.name : '无路段', gantry.id] : undefined;

  return (
    <Cascader
      showSearch
      placeholder={props.placeholder}
      options={treeData}
      onChange={(value) => props.onChange(value ? value[value.length - 1] : '', undefined)}
      showCheckedStrategy={Cascader.SHOW_CHILD}
      displayRender={gantrySelectDisplayRender}
      value={value}
      style={props.style}
      size={props.size}
    />
  );
}

export function GantrySelect(props: SelectProps) {
  const [{ result: gantries = [] }] = useGantryList({ _limit: 1000 });

  return (
    <Select
      placeholder="卡口"
      {...props}
      filterOption={(input, option) => option?.item.name.toLowerCase().includes(input.toLowerCase())}
      popupMatchSelectWidth={false}
    >
      {gantries.map((gantry) => (
        <Select.Option key={gantry.id} value={gantry.id} item={gantry}>
          {gantry.name}
        </Select.Option>
      ))}
    </Select>
  );
}
