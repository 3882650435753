import { GantryAttr, Subject } from '@36node-fcp/core-sdk';
import { ProfileOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Col, Input, Modal, Row, Space, Tabs, TabsProps, Tree, TreeDataNode } from 'antd';
import { Key } from 'antd/es/table/interface';
import { concat, uniq } from 'lodash';
import React from 'react';
import { styled } from 'styled-components';

import { VEHICLE_ONLY } from 'src/config';
import { GantryAttrSelect } from 'src/features/gantry';
import { useIllegalTypeTree } from 'src/features/illegal-type';
import { useGantryTree } from 'src/features/section-gantry.tree';
import { NamespaceCascaderSelect } from 'src/features/users';
import { useSlice } from 'src/lib/react-slice';

import { GantryMapEntity, MonitorState, useMonitorSettings } from './monitor.provider';

type BatchModalProps = {
  onClose: () => void;
};

type SubState = {
  namespace?: string;
  gantryAttr?: GantryAttr;
  searchWord: string;
  searched?: boolean;
  checkedKeys: string[];
  gantries: string[];
  codes: string[];
  gantryMap: Record<string, GantryMapEntity>;
  expandedKeys: Key[];
};

type State = {
  motor: SubState;
  nonMotor: SubState;
  tabKey: string;
};

const init = (settings: MonitorState['settings']): State => ({
  motor: {
    namespace: undefined,
    gantryAttr: undefined,
    searchWord: '',
    expandedKeys: [],
    checkedKeys: settings.motor.gantryDevices?.map((item) => `d-${item}`) || [],
    gantries: settings.motor.gantries?.map((item) => `g-${item}`) || [],
    gantryMap: settings.motor.gantryMap,
    codes: settings.motor.codes,
  },
  nonMotor: {
    namespace: undefined,
    gantryAttr: undefined,
    searchWord: '',
    expandedKeys: [],
    checkedKeys: settings.nonMotor.gantryDevices?.map((item) => `d-${item}`) || [],
    gantries: settings.nonMotor.gantries?.map((item) => `g-${item}`) || [],
    gantryMap: settings.nonMotor.gantryMap,
    codes: settings.nonMotor.codes,
  },
  tabKey: 'motor',
});

const reducers = {
  setNamespace: (state: State, namespace?: string) => {
    state[state.tabKey].namespace = namespace;
  },
  setGantryAttr: (state: State, attr?: GantryAttr) => {
    state[state.tabKey].gantryAttr = attr;
  },
  setSearchWord: (state: State, word: string) => {
    state[state.tabKey].searchWord = word;
  },
  setCheckedKeys: (state: State, checkedKeys: string[]) => {
    state[state.tabKey].checkedKeys = checkedKeys;
  },
  setGantries: (state: State, gantries: string[]) => {
    state[state.tabKey].gantries = gantries;
  },
  setCodes: (state: State, codes: string[]) => {
    state[state.tabKey].codes = codes;
  },
  setExpandedKeys: (state: State, keys: Key[]) => {
    state[state.tabKey].expandedKeys = keys;
  },
  setTabKey: (state: State, key: string) => {
    state.tabKey = key;
  },
};

const items: TabsProps['items'] = [
  {
    key: 'motor',
    label: '机动车',
  },
  {
    key: 'nonMotor',
    label: '非机动车及行人',
  },
];

const vehicleOnlyItems: TabsProps['items'] = [
  {
    key: 'motor',
    label: '机动车',
  },
];

const hasKey = (tree: TreeDataNode[], key: string) => {
  if (!tree) return false;
  if (tree.find((item) => item.key === key)) return true;
  return tree.some((item) => hasKey(item.children, key));
};

export const BatchModal: React.FC<BatchModalProps> = (props) => {
  const [settings, settingsDispatch] = useMonitorSettings();
  const [state, dispatch] = useSlice(reducers, init(settings));
  const { motor, nonMotor, tabKey } = state;
  const [motorGantryTree, allDevices] = useGantryTree(
    motor.searchWord,
    { attr: motor.gantryAttr, ns_like: motor.namespace },
    motor.gantryMap
  );
  const [nonMotorGantryTree] = useGantryTree(
    nonMotor.searchWord,
    { attr: nonMotor.gantryAttr, ns_like: nonMotor.namespace },
    nonMotor.gantryMap
  );
  const motorIllegalTypeTree = useIllegalTypeTree({ subject: [Subject.VEHICLE] });
  const nonMotorIllegalTypeTree = useIllegalTypeTree({ subject: [Subject.PERSON, Subject.NONMOTOR] });

  const gantryTree = tabKey === 'motor' ? motorGantryTree : nonMotorGantryTree;
  const illegalTypeTree = tabKey === 'motor' ? motorIllegalTypeTree : nonMotorIllegalTypeTree;
  const {
    checkedKeys = [],
    codes = [],
    gantryAttr,
    searchWord,
    expandedKeys,
    gantries,
    namespace,
  } = state[tabKey] as SubState;

  const onKeywordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const word = e.target.value;
    dispatch.setSearchWord(word);
  };

  const onOk = () => {
    settingsDispatch.setBatchGantries({
      motor: {
        codes: motor.codes,
        gantries: motor.gantries.map((g) => g.slice(2)), //选择卡口
        gantryDevices: motor.checkedKeys?.filter((g) => g?.startsWith('d-')).map((g) => g.slice(2)), //选择的是卡口设备
      },
      nonMotor: {
        codes: nonMotor.codes,
        gantries: nonMotor.gantries.map((g) => g.slice(2)), //选择卡口
        gantryDevices: nonMotor.checkedKeys?.filter((g) => g?.startsWith('d-')).map((g) => g.slice(2)), //选择的是卡口设备
      },
    });

    props.onClose();
  };

  const handleTreeCheck = (keys, e) => {
    const newCheckedKeys = keys.filter((key) => !key.startsWith('s-') && !key.startsWith('g-'));
    const hiddenKeys = checkedKeys.filter((key) => !hasKey(gantryTree, key));
    const finalCheckedKeys = concat(hiddenKeys, newCheckedKeys);
    dispatch.setCheckedKeys(finalCheckedKeys);

    // 选中的卡口
    const gantryKeys = allDevices
      .filter((device) => finalCheckedKeys.includes(`d-${device.id}`))
      .map((device) => `g-${device.gantry.id}`);
    dispatch.setGantries(uniq(gantryKeys));
  };

  const Title = (
    <Tabs defaultActiveKey="motor" items={VEHICLE_ONLY ? vehicleOnlyItems : items} onChange={dispatch.setTabKey} />
  );

  return (
    <Modal
      open={true}
      style={{ top: 20 }}
      title={Title}
      forceRender
      onCancel={props.onClose}
      maskClosable={false}
      width={1200}
      footer={
        <Footer>
          <Alert
            type="info"
            message={
              <span>
                1.本次布控将更改原先所有卡口的布控设置。2.标<span style={{ color: 'red' }}>*</span>
                的卡口表示原有单独的布控设置。
              </span>
            }
            showIcon
            style={{ marginTop: 12, width: 600 }}
          />
          <Space>
            <Button onClick={props.onClose}>取消</Button>
            <Button type="primary" onClick={onOk} disabled={!(checkedKeys.length > 0 && codes.length > 0)}>
              批量布控
            </Button>
          </Space>
        </Footer>
      }
    >
      <Container>
        <Row gutter={12}>
          <Col span={14}>
            <Card
              title={<span style={{ fontWeight: 'bold' }}>选择卡口</span>}
              bodyStyle={{ height: 800, overflowY: 'auto' }}
              extra={
                <Space>
                  <ProfileOutlined />
                  已选择{gantries?.filter((g) => g?.startsWith('g-')).length}个卡口
                </Space>
              }
            >
              <Space style={{ marginBottom: 20 }}>
                <Input
                  placeholder="按卡口名称筛选"
                  value={searchWord}
                  onChange={onKeywordChange}
                  allowClear
                  style={{ width: 250 }}
                />
                <NamespaceCascaderSelect
                  style={{ width: 250 }}
                  value={namespace as any}
                  onChange={dispatch.setNamespace as any}
                />
                <GantryAttrSelect
                  style={{ width: 120 }}
                  value={gantryAttr}
                  onChange={dispatch.setGantryAttr}
                  allowClear
                />
              </Space>

              <TreeContainer id="gantryTree" offset={40}>
                <Tree
                  checkable
                  showLine
                  selectable={false}
                  onCheck={handleTreeCheck}
                  treeData={gantryTree}
                  checkedKeys={checkedKeys}
                  onExpand={dispatch.setExpandedKeys}
                  expandedKeys={expandedKeys}
                />
              </TreeContainer>
            </Card>
          </Col>

          <Col span={10}>
            <Card
              title={<span style={{ fontWeight: 'bold' }}>选择需要推送的违法</span>}
              bordered
              bodyStyle={{ height: 800, overflowY: 'auto' }}
              extra={
                <Space>
                  已选择{codes?.filter((g) => !g?.startsWith('l-') && !g?.startsWith('l-all')).length}个违法
                </Space>
              }
            >
              <TreeContainer id="codeTree">
                <Tree
                  checkable
                  showLine
                  selectable={false}
                  treeData={illegalTypeTree}
                  onCheck={dispatch.setCodes}
                  checkedKeys={codes}
                  defaultExpandAll
                />
              </TreeContainer>
            </Card>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  width: 100%;
  .ant-tree-treenode-switcher-open {
    width: 100%;
    margin-top: 2px;
  }

  .ant-tree-treenode-switcher-close {
    width: 100%;
    margin-top: 2px;
  }

  .hidden {
    display: none;
  }
`;

const TreeContainer = styled.div<{ offset?: number }>`
  max-height: calc(100% - ${(props) => props.offset || 0}px);
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 12px;
`;
