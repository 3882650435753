import { Navigate, RouteObject } from 'react-router-dom';

import ProtectedRoute from 'src/components/protected-route/protectedRoute';
import { VEHICLE_ONLY } from 'src/config';
import BlackListVehicleDetaiPage from 'src/containers/blacklist/vehicle-detail.page';
import BlackListVehiclePage from 'src/containers/blacklist/vehicle.page';
import ForbiddenPage from 'src/containers/forbidden/forbidden.page';
import HomePage from 'src/containers/home/home.page';
import MonitorPage from 'src/containers/monitor/monitor.page';
import PersonIllegalPage from 'src/containers/person/illegal.page';
import PersonWarningDetailPage from 'src/containers/person/warning-detail.page';
import PersonWarningPage from 'src/containers/person/warning.page';
import BasicReportPage from 'src/containers/report/basic-report.page';
import IllegalVehiclePage from 'src/containers/report/illegal-vehicle.page';
import { ReportProvider } from 'src/containers/report/report.context';
import VehiclePropertyStorePage from 'src/containers/report/vehicle-property-store.page';
import { VehicleProvider } from 'src/containers/report/vehicle.context';
import ChangePasswordPage from 'src/containers/session/change-password.page';
import LoginPage from 'src/containers/session/login.page';
import GantryDevicePage from 'src/containers/system/gantry-device.page';
import GantryPage from 'src/containers/system/gantry.page';
import IllegalTypePage from 'src/containers/system/illegal-type.page';
import LawEnforcePointPage from 'src/containers/system/law-enforce-point.page';
import RoadSectionPage from 'src/containers/system/road-section.page';
import RobotPage from 'src/containers/system/robot.page';
import RolesPage from 'src/containers/system/roles.page';
import RulePage from 'src/containers/system/rule.page';
import SystemHealthPage from 'src/containers/system/system-health.page';
import UsersPage from 'src/containers/system/users.page';
import WhitelistPage from 'src/containers/system/whitelist.page';
import VehicleIllegalDetailPage from 'src/containers/vehicle/illegal-detail.page';
import VehicleIllegalPage from 'src/containers/vehicle/illegal.page';
import VehicleStorePage from 'src/containers/vehicle/store.page';
import TrackRecordDetailPage from 'src/containers/vehicle/track-record-detail.page';
import TrackRecordPage from 'src/containers/vehicle/track-record.page';
import VehicleWarningDetailPage from 'src/containers/vehicle/warning-detail.page';
import VehicleWarningPage from 'src/containers/vehicle/warning.page';
import { RequireAuth } from 'src/features/session';
import { routePrivilegeMap } from 'src/lib/privilege';
import { logout } from 'src/services';

import MainLayout from './main';
import ReportLayout from './report';
import SystemLayout from './system';

const RobotManager = ({ title }) => {
  return (
    <div>
      <h3>{title}</h3>
      <p>请参照《Robot编写指南》编写数据源Robot。</p>
      <p>Robot 启动后会自动出现在这个页面。</p>
      <p>如果 Robot 不再使用，可以在这个页面删除相关的资源。</p>
    </div>
  );
};

export const getRoutes = (): RouteObject[] => {
  return [
    { path: '/login', element: <LoginPage /> },
    { path: '/logout', element: <Navigate to="/login" />, loader: logout },
    {
      path: '/',
      element: (
        <RequireAuth>
          <MainLayout />
        </RequireAuth>
      ),
      children: [
        { index: true, element: <Navigate to="/monitor" /> },
        {
          path: 'home',
          element: <ProtectedRoute element={<HomePage />} privileges={[routePrivilegeMap['/home']]} />,
        },
        {
          path: 'report',
          element: <ReportLayout />,
          children: [
            {
              index: true,
              element: (
                <ProtectedRoute
                  element={<Navigate to="basic-data" />}
                  privileges={[routePrivilegeMap['/home']]}
                  allowRedirect="/report"
                />
              ),
            },
            {
              path: 'basic-data',
              element: (
                <ProtectedRoute
                  element={
                    <ReportProvider>
                      <BasicReportPage />
                    </ReportProvider>
                  }
                  privileges={[routePrivilegeMap['/home']]}
                />
              ),
            },
            {
              path: 'illegal-vehicle',
              element: <ProtectedRoute element={<IllegalVehiclePage />} privileges={[routePrivilegeMap['/home']]} />,
            },
            {
              path: 'vehicle-property-store',
              element: (
                <ProtectedRoute
                  element={
                    <VehicleProvider>
                      <VehiclePropertyStorePage />
                    </VehicleProvider>
                  }
                  privileges={[routePrivilegeMap['/home']]}
                />
              ),
            },
          ],
        },
        { path: '/change-password', element: <ChangePasswordPage /> },
        {
          path: 'monitor',
          element: <MonitorPage />,
        },
        {
          path: 'vehicle',
          children: [
            {
              index: true,
              element: (
                <ProtectedRoute
                  element={<Navigate to="illegal" />}
                  privileges={[routePrivilegeMap['/vehicle/illegal']]}
                  allowRedirect="/vehicle"
                />
              ),
            },
            {
              path: 'warning',
              element: <VehicleWarningPage />,
            },
            {
              path: 'warning/:warningId',
              element: <VehicleWarningDetailPage />,
            },
            {
              path: 'illegal',
              element: <VehicleIllegalPage />,
            },
            {
              path: 'illegal/:illegalRecordId',
              element: <VehicleIllegalDetailPage />,
            },
            {
              path: 'track',
              element: <TrackRecordPage />,
            },
            {
              path: 'track/:trackRecordId',
              element: <TrackRecordDetailPage />,
            },
            {
              path: 'store',
              element: (
                <ProtectedRoute element={<VehicleStorePage />} privileges={[routePrivilegeMap['/vehicle/store']]} />
              ),
            },
          ],
        },
        !VEHICLE_ONLY && {
          path: 'person',
          children: [
            {
              index: true,
              element: (
                <ProtectedRoute
                  element={<Navigate to="illegal" />}
                  privileges={[routePrivilegeMap['/person/illegal']]}
                  allowRedirect="/person"
                />
              ),
            },
            {
              path: 'warning',
              element: <PersonWarningPage />,
            },
            {
              path: 'warning/:warningId',
              element: <PersonWarningDetailPage />,
            },
            {
              path: 'illegal',
              element: <PersonIllegalPage />,
            },
          ],
        },
        {
          path: 'blacklist',
          children: [
            {
              index: true,
              element: (
                <ProtectedRoute element={<BlackListVehiclePage />} privileges={[routePrivilegeMap['/blacklist']]} />
              ),
            },
            {
              path: ':blacklistId',
              element: (
                <ProtectedRoute
                  element={<BlackListVehicleDetaiPage />}
                  privileges={[routePrivilegeMap['/blacklist/:blacklistId']]}
                />
              ),
            },
          ],
        },
        {
          path: 'system',
          element: <SystemLayout />,
          children: [
            {
              index: true,
              element: (
                <ProtectedRoute
                  element={<Navigate to="system-health" />}
                  privileges={[routePrivilegeMap['/system/system-health']]}
                  allowRedirect="/system"
                />
              ),
            },
            {
              path: 'system-health',
              element: (
                <ProtectedRoute
                  element={<SystemHealthPage />}
                  privileges={[routePrivilegeMap['/system/system-health']]}
                />
              ),
            },
            {
              path: 'gantry',
              element: <ProtectedRoute element={<GantryPage />} privileges={[routePrivilegeMap['/system/gantry']]} />,
            },
            {
              path: 'law-enforce-point',
              element: (
                <ProtectedRoute
                  element={<LawEnforcePointPage />}
                  privileges={[routePrivilegeMap['/system/law-enforce-point']]}
                />
              ),
            },
            {
              path: 'device',
              element: (
                <ProtectedRoute element={<GantryDevicePage />} privileges={[routePrivilegeMap['/system/device']]} />
              ),
            },
            {
              path: 'road-section',
              element: (
                <ProtectedRoute
                  element={<RoadSectionPage />}
                  privileges={[routePrivilegeMap['/system/road-section']]}
                />
              ),
            },
            {
              path: 'illegal-type',
              element: (
                <ProtectedRoute
                  element={<IllegalTypePage />}
                  privileges={[routePrivilegeMap['/system/illegal-type']]}
                />
              ),
            },
            {
              path: 'user',
              element: <ProtectedRoute element={<UsersPage />} privileges={[routePrivilegeMap['/system/user']]} />,
            },
            {
              path: 'role',
              element: <ProtectedRoute element={<RolesPage />} privileges={[routePrivilegeMap['/system/role']]} />,
            },
            {
              path: 'whitelist',
              element: (
                <ProtectedRoute element={<WhitelistPage />} privileges={[routePrivilegeMap['/system/whitelist']]} />
              ),
            },
            {
              path: 'rule',
              element: <ProtectedRoute element={<RulePage />} privileges={[routePrivilegeMap['/system/rule']]} />,
            },
            {
              path: 'source',
              element: (
                <ProtectedRoute
                  element={<RobotManager title="数据源管理" />}
                  privileges={[routePrivilegeMap['/system/source']]}
                />
              ),
            },
            {
              path: 'destination',
              element: (
                <ProtectedRoute
                  element={<RobotManager title="数据目的地管理" />}
                  privileges={[routePrivilegeMap['/system/destination']]}
                />
              ),
            },
            {
              path: 'source/:robotId',
              element: (
                <ProtectedRoute element={<RobotPage />} privileges={[routePrivilegeMap['/system/source/:robotId']]} />
              ),
            },
            {
              path: 'destination/:robotId',
              element: (
                <ProtectedRoute
                  element={<RobotPage />}
                  privileges={[routePrivilegeMap['/system/destination/:robotId']]}
                />
              ),
            },
          ],
        },
        { path: '/403', element: <ForbiddenPage /> },
      ],
    },
  ];
};
