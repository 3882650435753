import { AggregateIllegalRecordsRequest, IllegalAggregationGroup, Subject } from '@36node-fcp/core-sdk';
import { Card } from 'antd';
import dayjs from 'dayjs';
import ReactECharts from 'echarts-for-react';
import { filter, map, sumBy } from 'lodash';

import { useIllegalRecordAggregation } from 'src/features/illegal';
import { useIllegalTypeList } from 'src/features/illegal-type';

const formatter = new Intl.NumberFormat('en-US');

const color = ['#ff4500', '#ffa500', '#ffd700', '#32cd32', '#87ceeb', '#56c39b'];

const EffectiveIllegalCodePage = ({ subject }: { subject: Subject[] }) => {
  const illegalQuery: AggregateIllegalRecordsRequest = {
    subject,
    expiredAt_gt: dayjs().startOf('hour').toISOString(), //有效违法
    _group: [IllegalAggregationGroup.CODE],
    _limit: 100,
    _sort: '-count',
  };

  const [{ result: illegalAggregations = [] }] = useIllegalRecordAggregation(illegalQuery);
  const [{ result: illegalTypes = [] }] = useIllegalTypeList({ _limit: 1000 });

  const transformedData = map(illegalAggregations?.slice(0, 5), ({ count, code }) => ({
    name: illegalTypes.find((item) => item.code === code)?.name,
    value: count,
  }));
  if (transformedData.length) {
    transformedData.push({
      name: '其他',
      value: sumBy(illegalAggregations?.slice(5), 'count'),
    });
  }
  const totalCount = map(transformedData, 'value').reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  const option = {
    tooltip: {
      trigger: 'item',
    },
    legend: {
      icon: 'circle',
      orient: 'vertical',
      right: 10,
      top: 35,
      itemGap: 15,
      formatter: function (name) {
        const val = filter(transformedData, { name })[0].value;
        return (
          '{name|' +
          name +
          '}' +
          '{per|' +
          ((val / totalCount) * 100).toFixed(1) +
          '%' +
          '}' +
          '{val|' +
          formatter.format(val || 0) +
          '}'
        );
      },
      textStyle: {
        rich: {
          name: {
            color: '#333',
            fontSize: '14px',
            width: 155,
            textAlign: 'left',
          },
          value: {
            color: '#333',
            fontSize: '14px',
          },
          per: {
            color: '#999',
            fontSize: '14px',
            width: 72,
            textAlign: 'left',
          },
        },
      },
    },
    graphic: [
      {
        type: 'text',
        left: 87,
        top: '40%',
        style: {
          text: '违法总数',
          textAlign: 'center',
          fill: '#aaa',
          fontSize: '16px',
        },
      },
      {
        type: 'text',
        left: 80,
        top: '50%',
        style: {
          text: formatter.format(totalCount || 0),
          textAlign: 'center',
          fill: '#333',
          fontSize: '24px',
          fontWeight: 600,
        },
      },
    ],
    series: [
      {
        type: 'pie',
        radius: ['62%', '90%'],
        center: [120, '50%'],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: 'left',
        },
        itemStyle: {
          borderRadius: 5,
          borderColor: '#fff',
          borderWidth: 5,
        },
        labelLine: {
          show: false,
        },
        data: transformedData,
        color: color,
      },
    ],
  };

  return (
    <Card title="当前有效违法类型分布">
      <ReactECharts option={option} style={{ height: '260px' }} />
    </Card>
  );
};
export default EffectiveIllegalCodePage;
