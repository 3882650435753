import { RuleEntity } from '@36node-fcp/core-sdk';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { Button, Col, Descriptions, Divider, Image, message, Popconfirm, Row, Space, Spin } from 'antd';
import type { DescriptionsProps } from 'antd';
import { FormProps } from 'antd/lib';
import { clone } from 'lodash';
import React, { useMemo } from 'react';
import { styled } from 'styled-components';

import RuleAccurateDropImg from 'src/assets/rule-accurate-drop.png';
import RuleDropImg from 'src/assets/rule-drop.png';
import RuleGrandTotalImg from 'src/assets/rule-grandtotal.png';
import RuleOverspeedImg from 'src/assets/rule-overspeed.png';
import RuleUnderspeedImg from 'src/assets/rule-underspeed.png';
import { toGantryAttrText } from 'src/features/gantry';
import { useGantryDeviceList } from 'src/features/gantry-device';
import { toVehiclePropertyText } from 'src/features/illegal';
import { useIllegalTypeList } from 'src/features/illegal-type';
import { useApi } from 'src/lib/react-api';
import { fcp } from 'src/services';

type CustomIllegalTypeProps = {
  records?: RuleEntity[];
  onEdit?: (index: number) => void;
  onRefresh?: () => void;
} & FormProps;

const RuleLogo = {
  OVERSPEED: RuleOverspeedImg,
  UNDERSPEED: RuleUnderspeedImg,
  GRANDTOTAL: RuleGrandTotalImg,
  DROP: RuleDropImg,
  ACCURATE_DROP: RuleAccurateDropImg,
};

const labelStyle: DescriptionsProps['labelStyle'] = {
  fontWeight: 600,
};

const contentStyle: DescriptionsProps['contentStyle'] = {
  fontWeight: 600,
};

function randomKey() {
  return Math.random().toString(36).slice(2);
}

export const CustomIllegalTypePage: React.FC<CustomIllegalTypeProps> = ({ records, onEdit, onRefresh }) => {
  const [{ result: illegalTypes = [] }] = useIllegalTypeList({ _limit: 1000 });
  const [{ result: gds = [] }] = useGantryDeviceList({ _limit: 1000 });

  const onSuccess = () => {
    message.success('更新成功');
    onRefresh?.();
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [updateState, updateRules] = useApi(fcp.updateRule, { onSuccess });

  const onDelete = (index: number) => {
    const datas = records?.filter((_, i) => i !== index);
    if (datas) {
      updateRules({ body: { entities: datas } });
    }
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    const datas = clone(records);
    const source = records[sourceIndex];

    datas.splice(sourceIndex, 1);
    if (destinationIndex === 0) {
      datas.unshift(source);
    } else if (destinationIndex === datas.length) {
      datas.push(source);
    } else {
      datas.splice(destinationIndex, 0, source);
    }

    updateRules({ body: { entities: datas } });
  };

  const RuleItem = (record: RuleEntity, index: number) => {
    const {
      name,
      inputIllegalCodes,
      outputIllegalCode,
      params,
      gantryAttr,
      vehicleProperty,
      carWayCodes,
      gantryDevices,
    } = record;
    const items: DescriptionsProps['items'] = [
      {
        key: '1',
        label: '源违法类型',
        children: inputIllegalCodes
          ?.map((code, index) => illegalTypes.find((item) => item.code === code)?.name)
          .join(' / '),
        labelStyle,
        contentStyle,
      },
      {
        key: '2',
        label: '目的违法类型',
        children: illegalTypes.find((item) => item.code === outputIllegalCode)?.name,
        labelStyle,
        contentStyle,
      },
      {
        key: '3',
        label: '转换条件',
        span: 2,
        children: (
          <Space>
            {params?.expire && <div>{params.expire ? params.expire + '天内' : ''}</div>}
            {params?.count && <div>{params.count ? params.count + '次' : ''}</div>}
            {params?.min && (
              <div>
                {params.min}% - {params.max ? params.max + '%' : '∞'}
              </div>
            )}
          </Space>
        ),
      },
      {
        key: '4',
        label: '卡口类型',
        children: gantryAttr?.map((attr) => toGantryAttrText(attr)).join(' / '),
      },
      {
        key: '5',
        label: '车辆性质',
        children: vehicleProperty?.map((prop) => toVehiclePropertyText(prop)).join(' / '),
      },
      {
        key: '6',
        label: '车道',
        span: 2,
        children: carWayCodes?.join(';'),
      },
      {
        key: '7',
        label: '设备',
        children: (
          <Row gutter={24}>
            {gantryDevices && gds.length
              ? gds
                  .map((gd) => {
                    if (gantryDevices.includes(gd.id)) {
                      return gd.name;
                    }
                    return undefined;
                  })
                  .filter((item) => item)
                  .map((item) => <Col span={12}>{item}</Col>)
              : ''}
          </Row>
        ),
      },
    ];
    return (
      <ContainerItem>
        <div>
          <Image width={60} preview={false} src={RuleLogo[name]} />
        </div>
        <Descriptions items={items} column={4} style={{ marginBottom: -16, marginLeft: 12 }} />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button type="link" style={{ padding: 3 }} onClick={() => onEdit(index)}>
            编辑
          </Button>
          <Popconfirm title="你确定要执行此操作吗？" onConfirm={() => onDelete(index)}>
            <Button type="link" danger style={{ padding: 3 }}>
              删除
            </Button>
          </Popconfirm>
        </div>
      </ContainerItem>
    );
  };

  const showDatas = useMemo(
    () =>
      records?.map((record) => ({
        ...record,
        params: {
          max: record.params?.max ? Math.round(record.params?.max * 100) : null,
          min: record.params?.min ? Math.round(record.params?.min * 100) : null,
          expire: record.params?.expire,
          count: record.params?.count,
        },
        key: randomKey(),
      })),
    [records]
  );

  const isLoading = updateState.loading;
  return (
    <Container>
      <Spin spinning={isLoading}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {showDatas?.map((record, index) => (
                  <Draggable key={record.key} draggableId={record.key} index={index}>
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        {RuleItem(record, index)}
                        <Divider />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Spin>
    </Container>
  );
};

const Container = styled.div`
  padding: 24px;
`;

const ContainerItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
