import { GantryAttr, GantryDevice } from '@36node-fcp/core-sdk';
import { TreeDataNode } from 'antd';
import { groupBy, includes, uniqBy } from 'lodash';
import React from 'react';
import Highlighter from 'react-highlight-words';

import { GantryMapEntity } from 'src/containers/monitor/monitor.provider';

import { useGantryDeviceList } from './gantry-device';

type Filter = {
  ns_like?: string;
  attr?: GantryAttr;
};

export const useGantryTree = (
  searchWord?: string,
  filter?: Filter,
  gantryMap?: Record<string, GantryMapEntity>
): [TreeDataNode[], GantryDevice[]] => {
  const [{ result: allDevices = [] }] = useGantryDeviceList({ _limit: 1000 });

  const devices = allDevices.filter((device) => {
    if (filter?.ns_like && !device.gantry.ns.startsWith(filter.ns_like)) {
      return false;
    }
    if (filter?.attr && device.gantry.attr !== filter.attr) {
      return false;
    }
    if (searchWord) {
      return device.gantry.name.toLocaleLowerCase().includes(searchWord.toLocaleLowerCase());
    }
    return true;
  });

  const tree = React.useMemo(() => {
    const deviceGroupedByGantryId = groupBy(devices, (device) => device.gantry?.id);
    const filterGantries = uniqBy(
      devices.filter((device) => device.gantry).map((device) => device.gantry),
      'id'
    );
    const gantryGroupedBySectionId = groupBy(filterGantries, (gantry) => gantry.section?.id);
    const sections = uniqBy(
      filterGantries.filter((gantry) => gantry.section).map((gantry) => gantry.section),
      'id'
    );

    return sections.map((section) => {
      const gantries = gantryGroupedBySectionId[section.id] || [];
      const children = gantries
        .filter((gantry) => {
          const devices = deviceGroupedByGantryId[gantry.id] || [];
          return devices.length > 0;
        })
        .map((gantry) => {
          const devices = deviceGroupedByGantryId[gantry.id] || [];
          const children = devices.map((device) => ({
            title: device.name,
            key: `d-${device.id}`,
          }));

          return {
            title: (
              <div>
                <Highlighter
                  highlightStyle={{
                    color: 'red',
                    background: 'transparent',
                  }}
                  textToHighlight={gantry.name}
                  searchWords={[searchWord]}
                />
                {includes(Object.keys(gantryMap), gantry.id) && <span style={{ color: 'red' }}>*</span>}
              </div>
            ),
            key: `g-${gantry.id}`,
            children,
          };
        });

      return {
        title: section.name + `(${children.length}卡口)`,
        key: `s-${section.id}`, // s 开头的 key 会被过滤掉
        children,
      };
    });
  }, [searchWord, filter?.ns_like, filter?.attr, allDevices]);

  return [tree, allDevices];
};
