import { ApiError, Blacklist, BlacklistState, CreateBlacklistDto } from '@36node-fcp/core-sdk';
import { Alert, Form, Input, message, Modal, Typography } from 'antd';
import styled from 'styled-components';

import { Uploader } from 'src/components/uploader';
import { BlacklistApiErrorMap } from 'src/features/blacklist';
import { useApi } from 'src/lib/react-api';
import { fcp, passApiErrors } from 'src/services';

const { Title } = Typography;

type EditorValues = CreateBlacklistDto & {};

interface VehicleEditorProps {
  Vehicle?: Blacklist;
  onClose: () => void;
  onFinish?: () => void;
}

export const VehicleEditor: React.FC<VehicleEditorProps> = ({
  Vehicle,
  onClose: handleClose,
  onFinish: handleFinish,
}) => {
  const isEdit = !!Vehicle;
  const onSuccess = () => {
    message.success(isEdit ? '编辑成功' : '创建成功');
    handleClose();
    handleFinish?.();
  };

  const onFailure = (err: ApiError) => {
    const ne = passApiErrors(err, BlacklistApiErrorMap);
    if (ne.keys) {
      ne.keys.forEach((key) => form.setFields([{ name: key, errors: [ne.message] }]));
    } else {
      message.error(ne.message);
    }
  };

  const [form] = Form.useForm<EditorValues>();
  const [updateState, update] = useApi(fcp.updateBlacklist, { onSuccess, onFailure });
  const [createState, create] = useApi(fcp.createBlacklist, { onSuccess, onFailure });

  const handleOk = () => form.submit();
  const handleSubmit = async (values: EditorValues) => {
    if (isEdit) {
      update({ blacklistId: Vehicle.id, body: values });
    } else {
      create({ body: values });
    }
  };

  const isLoading = updateState.loading || createState.loading;
  const title = isEdit ? '编辑车辆黑名单' : '录入车辆黑名单';
  const error = updateState.error || createState.error;

  return (
    <Modal title={title} width="650px" open onOk={handleOk} onCancel={handleClose} confirmLoading={isLoading}>
      <Container>
        {error && <Alert message={error.message} type="error" />}
        <Form
          name="vehicle-editor"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          initialValues={Vehicle}
          autoComplete="off"
          onFinish={handleSubmit}
          form={form}
        >
          <Title style={{ fontSize: 14 }}>基础信息</Title>
          <div style={{ paddingLeft: 32 }}>
            <Form.Item label="车牌" name="plate" rules={[{ required: true, message: '请填写车牌号' }]}>
              <Input placeholder="填写车牌号" />
            </Form.Item>
            {/* GantryAttrSelect 换成select */}
            <Form.Item label="号牌种类" name="plateType" rules={[{ required: true, message: '请填写号牌种类' }]}>
              <Input placeholder="填写号牌种类" />
            </Form.Item>
            <Form.Item label="描述" name="content" rules={[{ required: true, message: '请填写描述' }]}>
              <Input placeholder="填写描述" />
            </Form.Item>
            <Form.Item hidden name="state" label="状态" initialValue={BlacklistState.OPEN}>
              <Input />
            </Form.Item>
          </div>
          <Title style={{ fontSize: 14 }}>图片信息</Title>
          <div style={{ paddingLeft: 32 }}>
            <Form.Item label="车辆照片" name="images">
              <Uploader
                setImages={(urls) => {
                  form.setFieldValue('images', urls);
                }}
              />
            </Form.Item>
          </div>
        </Form>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  margin: 24px;
`;
