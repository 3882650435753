import { AggregateWarningsRequest, WarningAggregationGroup } from '@36node-fcp/core-sdk';
import dayjs from 'dayjs';
import * as echarts from 'echarts';
import ReactECharts from 'echarts-for-react';
import { map, sortBy } from 'lodash';
import styled from 'styled-components';

import { useWarningsAggregation } from 'src/features/warning';

const formatter = new Intl.NumberFormat('en-US');

const ChartsBox = styled.div`
  display: flex;
  flex-direction: column;
  height: 180px;
  padding: 20px;
  background-color: #fff;
  border: 1px #eee solid;

  .title {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: #aaa;
  }

  .result {
    font-size: 24px;
    margin-top: 10px;
  }

  .trends {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
    height: 50px;
    color: #aaa;
    font-size: 12px;
  }

  .border {
    border-bottom: 1px #ddd solid;
  }

  .bottom {
    font-size: 12px;
    margin-top: 10px;
    color: #666;
  }
`;

const WarningOverviewPage: React.FC = () => {
  const todayQuery: AggregateWarningsRequest = {
    createAt_gt: dayjs().startOf('day').toISOString(),
    createAt_lt: dayjs().startOf('hour').toISOString(),
    _group: [WarningAggregationGroup.CREATE_AT_HOUR],
    _limit: 24,
  };

  const yesterdayQuery: AggregateWarningsRequest = {
    createAt_gt: dayjs().subtract(1, 'days').startOf('day').toISOString(),
    createAt_lt: dayjs().startOf('day').toISOString(),
  };

  const [{ result: todayAggregations = [] }] = useWarningsAggregation(todayQuery);
  const [{ result: yesterdayAggregations = [] }] = useWarningsAggregation(yesterdayQuery);
  const sortByToday = sortBy(todayAggregations, 'createAt.hour');
  const countArray = map(sortByToday, 'count');
  const hourArray = map(sortByToday, 'createAt.hour');
  const totalCount = countArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  const formattedHourArray = hourArray.map((hour: any) => {
    const startTime = dayjs().hour(hour).minute(0).format('HH:mm');
    const endTime = dayjs()
      .hour(hour + 1)
      .minute(0)
      .format('HH:mm');
    return `${startTime}~${endTime}`;
  });

  const option = {
    grid: {
      left: -5,
      right: -5,
      top: 0,
      bottom: 0,
    },
    color: ['#fade8c'],
    tooltip: {
      trigger: 'axis',
      formatter: '{b0}: {c0}',
    },
    xAxis: {
      show: false,
      type: 'category',
      data: formattedHourArray,
    },
    yAxis: {
      show: false,
      type: 'value',
      boundaryGap: ['0', '1'],
    },
    series: [
      {
        type: 'line',
        smooth: true,
        smoothMonotone: 'x',
        itemStyle: {
          color: 'rgb(250, 211, 55)',
        },
        showSymbol: false,
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgb(250, 211, 55, 0.4)',
            },
            {
              offset: 1,
              color: 'rgb(250, 211, 55, 0.1)',
            },
          ]),
        },
        emphasis: {
          focus: 'series',
        },
        data: countArray,
      },
    ],
  };
  return (
    <ChartsBox>
      <div className="title">
        <span>今日预警</span>
      </div>
      <div className="result">{formatter.format(totalCount || 0)}</div>
      <div className="trends">
        <ReactECharts option={option} style={{ width: '100%', height: '100%' }} />
      </div>
      <div className="border"></div>
      <div className="bottom">昨日预警&emsp;&emsp;{formatter.format(yesterdayAggregations?.[0]?.count || 0)}</div>
    </ChartsBox>
  );
};

export default WarningOverviewPage;
