import { Subject } from '@36node-fcp/core-sdk';
import { createContext, useContext } from 'react';

import { getTimeDistance, RangePickerValue } from 'src/components/card-range-picker';
import { ReportRequest } from 'src/features/report';
import { CaseReducerActions, useSlice } from 'src/lib/react-slice';

type ReportState = {
  search: ReportRequest & {
    rangePickerValue: RangePickerValue;
  };
};

const initReportState: ReportState = {
  search: {
    rangePickerValue: getTimeDistance('yesterday'),
    createAt_gt: getTimeDistance('yesterday')[0].toISOString(), //时间根据选择改变
    createAt_lt: getTimeDistance('yesterday')[1].toISOString(), //时间根据选择改变
    subject: [Subject.VEHICLE], //非机动车为 [Subject.NONMOTOR, Subject.PERSON]
  },
};

const reportReducers = {
  setDateRange: (state: ReportState, dateRange: RangePickerValue) => {
    state.search.rangePickerValue = dateRange;
    state.search.createAt_gt = dateRange[0].startOf('day').toISOString();
    state.search.createAt_lt = dateRange[1].add(1, 'day').startOf('day').toISOString();
  },
  setSubject: (state: ReportState, subject: Subject) => {
    state.search.subject = subject === Subject.VEHICLE ? [subject] : [Subject.NONMOTOR, Subject.PERSON];
  },
};
const ReportSearchContext = createContext<ReportState['search']>(null);
const ReportDispatchContext = createContext<CaseReducerActions<typeof reportReducers>>(null);

export const ReportProvider = ({ children }) => {
  const [{ search }, dispatch] = useSlice(reportReducers, initReportState);
  return (
    <ReportDispatchContext.Provider value={dispatch}>
      <ReportSearchContext.Provider value={search}>{children}</ReportSearchContext.Provider>
    </ReportDispatchContext.Provider>
  );
};

export const useReportSearch = () => {
  const dispatch = useContext(ReportDispatchContext);
  const search = useContext(ReportSearchContext);
  return [search, dispatch] as const;
};
