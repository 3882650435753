import { keyBy, keys, map } from 'lodash';
import { useCallback, useEffect } from 'react';
import { read, utils } from 'xlsx';

import { useSlice } from 'src/lib/react-slice';

export enum PreviewStatus {
  INIT = 'INIT',
  FETCHING = 'FETCHING',
  CONVERTING = 'CONVERTING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export type XlsxPreviewState = {
  status: PreviewStatus;
  error?: any; // 错误信息
  result?: any[];
};

const initialState: XlsxPreviewState = {
  status: PreviewStatus.INIT,
};

const reducers = {
  fetch: (state: XlsxPreviewState) => {
    state.status = PreviewStatus.FETCHING;
  },
  convert: (state: XlsxPreviewState) => {
    state.status = PreviewStatus.CONVERTING;
  },
  success: (state: XlsxPreviewState, result: any[]) => {
    state.status = PreviewStatus.SUCCESS;
    state.result = result;
  },
  failure: (state: XlsxPreviewState, error: any) => {
    state.status = PreviewStatus.FAILURE;
    state.error = error;
  },
};

export const usePreview = (
  url: string,
  columns?: any[],
  options?: { onSuccess?: () => void; onFailure?: (error: any) => void }
) => {
  const [state, dispatch] = useSlice(reducers, initialState);

  const readRemoteXLSX = useCallback(async () => {
    try {
      dispatch.fetch();
      const res = await fetch(url);
      dispatch.convert();
      const ab = await res.arrayBuffer();
      const workbook = read(ab, { dense: true });
      const ws = workbook.Sheets[workbook.SheetNames[0]];
      const json = utils.sheet_to_json(ws);
      if (!columns) {
        dispatch.success(json);
      }
      const columnMap = keyBy(columns, 'title');
      const result = map(json, (item, index) => {
        const row = {};
        keys(columnMap).forEach((title) => {
          const key = columnMap[title]['key'] ? columnMap[title]['key'] : columnMap[title]['dataIndex'];
          if (key) {
            row[key] = item[title];
          }
        });
        row['key'] = index;
        return row;
      });
      dispatch.success(result);
      options?.onSuccess?.();
    } catch (error) {
      dispatch.failure(error);
      options?.onFailure?.(error);
    }
  }, [url]);

  useEffect(() => {
    readRemoteXLSX();
  }, [url]);

  return [state] as const;
};
