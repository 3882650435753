import { ApiError } from '@36node-fcp/core-sdk';
import { Alert, Form, Input, message, Modal } from 'antd';
import styled from 'styled-components';

import { RolesSelect } from 'src/features/roles';
import { NamespaceCascaderSelect, UserApiErrorMap } from 'src/features/users';
import { useApi } from 'src/lib/react-api';
import { CreateUserRequest, User } from 'src/sdk';
import { passStargateApiErrors, stargate } from 'src/services';

type EditorValues = CreateUserRequest & {};

interface UserEditorProps {
  user?: User;
  onClose: () => void;
  onFinish?: () => void;
  defaultNamespace?: string;
}

export const UsersEditor: React.FC<UserEditorProps> = ({
  user,
  onClose: handleClose,
  onFinish: handleFinish,
  defaultNamespace,
}) => {
  const isEdit = !!user;
  const onSuccess = () => {
    message.success(isEdit ? '编辑成功' : '创建成功');
    handleClose();
    handleFinish?.();
  };
  const onFailure = (err: ApiError) => {
    const ne = passStargateApiErrors(err, UserApiErrorMap);
    if (ne.keys) {
      const errors = ne.keys.map((key) => ({ name: key, errors: [ne.message] }));
      form.setFields(errors);
    } else {
      message.error(ne.message);
    }
  };

  const [form] = Form.useForm<EditorValues>();
  const [updateState, update] = useApi(stargate.updateUser, { onSuccess, onFailure });
  const [createState, create] = useApi(stargate.createUser, { onSuccess, onFailure });

  const handleOk = () => form.submit();
  const handleSubmit = async (values: any) => {
    if (isEdit) {
      update({ user: user.id, body: values });
    } else {
      create({ body: values });
    }
  };

  const isLoading = updateState.loading || createState.loading;
  const title = isEdit ? '编辑用户' : '新增用户';
  const error = updateState.error || createState.error;

  return (
    <Modal title={title} open onOk={handleOk} onCancel={handleClose} confirmLoading={isLoading} width="650px">
      <Container>
        {error && !Object.values(ApiError.Code).includes(error.code) && <Alert message={error.message} type="error" />}
        <Form
          name="user-editor"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          initialValues={isEdit ? user : { ns: defaultNamespace }}
          autoComplete="off"
          onFinish={handleSubmit}
          form={form}
        >
          <Form.Item
            label="用户名"
            name={`username`}
            rules={[
              { required: true, message: '请填写用户名' },
              {
                pattern: /^[A-Za-z][A-Za-z\d_-]+$/,
                message: '必须英文字母开头，可以包含数字、连字符和下划线',
              },
            ]}
          >
            <Input placeholder="填写用户名" disabled={isEdit} autoComplete="off" />
          </Form.Item>
          <Form.Item label="姓名" name={`nickname`} rules={[{ required: true, message: '请填写姓名' }]}>
            <Input placeholder="填写姓名" autoComplete="off" />
          </Form.Item>
          <Form.Item label="所属部门" name={`ns`} rules={[{ required: true, message: '请选择所属部门' }]}>
            <NamespaceCascaderSelect placeholder="选择所属部门" onChange={(value) => form.setFieldValue('ns', value)} />
          </Form.Item>
          <Form.Item label="角色" name={`roles`}>
            <RolesSelect placeholder="选择角色" onChange={(value) => form.setFieldValue('roles', value)} />
          </Form.Item>
          <Form.Item
            label="密码"
            name={`password`}
            rules={[
              { required: !isEdit, message: '请填写密码' },
              {
                validator: (rule, val) => {
                  const needValidate = isEdit ? Boolean(val && val.length) : true;
                  if (needValidate) {
                    const regex =
                      /^(?![A-Za-z]+$)(?![A-Z\d]+$)(?![A-Z\W_]+$)(?![a-z\d]+$)(?![a-z\W_]+$)(?![\d\W_]+$)\S{8,}$/;
                    if (val && regex.test(val)) {
                      return Promise.resolve(); // 验证成功，返回一个解决的 Promise
                    } else {
                      return Promise.reject(); // 验证失败，返回一个拒绝的 Promise，可以包含错误消息
                    }
                  } else {
                    return Promise.resolve();
                  }
                },
                message: '至少8位密码，包括大写、小写、数字、特殊字符中的三种',
              },
            ]}
          >
            <Input.Password placeholder="填写密码" autoComplete="off" />
          </Form.Item>
        </Form>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  margin: 24px;
`;
