import { GantryDeviceState } from '@36node-fcp/core-sdk';
import { Empty, Popover } from 'antd';
import styled from 'styled-components';

import { useGantryDeviceList } from 'src/features/gantry-device';

const formatter = new Intl.NumberFormat('en-US');

const TotalDevice = styled.div`
  display: flex;
  flex-direction: column;
  height: 180px;
  padding: 20px;
  background-color: #fff;
  border: 1px #eee solid;

  .title {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: #aaa;
  }

  .result {
    font-size: 24px;
    margin-top: 10px;
    span {
      display: inline-block;
      margin: 0px 5px 0 0;
      vertical-align: 3px;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: #4eca68;
    }
  }

  .subtitle {
    display: flex;
    align-items: center;
    margin: 10px 0;
    height: 50px;
    color: #666;
    font-size: 12px;
  }

  .border {
    border-bottom: 1px #ddd solid;
  }

  .bottom {
    font-size: 12px;
    margin-top: 10px;
    color: #666;
    cursor: pointer;
  }
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 20vw;
  max-width: 325px;
`;

const DeviceOverviewPage = () => {
  const [{ total: onlineTotal }] = useGantryDeviceList({
    state: [GantryDeviceState.ONLINE],
    _limit: 1,
  });
  const [{ result: offlineDevice = [], total: offlineTotal }] = useGantryDeviceList({
    state: [GantryDeviceState.OFFLINE],
    _limit: 1000,
  });
  return (
    <TotalDevice>
      <div className="title">
        <span>在线设备</span>
      </div>
      <div className="result">
        <span />
        {formatter.format(onlineTotal || 0)}
      </div>
      <div className="subtitle">离线设备数&emsp;&emsp; {formatter.format(offlineTotal || 0)}</div>
      <div className="border"></div>
      <Popover
        content={
          offlineDevice?.length ? (
            <ListContainer>
              {offlineDevice.map((item: any) => (
                <div key={item.id}>{item.name}</div>
              ))}
            </ListContainer>
          ) : (
            <Empty description="暂无数据" />
          )
        }
        arrow={false}
        title={null}
      >
        <div className="bottom">离线设备清单</div>
      </Popover>
    </TotalDevice>
  );
};

export default DeviceOverviewPage;
