import { map } from 'lodash';
import { useCallback } from 'react';
import { utils, writeFile } from 'xlsx';

import { useSlice } from 'src/lib/react-slice';

export enum TemplateStatus {
  INIT = 'INIT',
  WRITING = 'WRITING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export type XlsxTemplateState = {
  status: TemplateStatus;
  error?: any;
};

const initialState: XlsxTemplateState = {
  status: TemplateStatus.INIT,
};

const reducers = {
  write: (state: XlsxTemplateState) => {
    state.status = TemplateStatus.WRITING;
  },
  success: (state: XlsxTemplateState) => {
    state.status = TemplateStatus.SUCCESS;
  },
  failure: (state: XlsxTemplateState, error: any) => {
    state.status = TemplateStatus.FAILURE;
    state.error = error;
  },
};

export const useTemplate = (
  columns: any[],
  name: string,
  options?: { onSuccess?: () => void; onFailure?: (error: any) => void }
) => {
  const [state, dispatch] = useSlice(reducers, initialState);

  const download = useCallback(() => {
    try {
      dispatch.write();
      const ws = utils.aoa_to_sheet([map(columns, 'title')]);
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, 'Sheet1');
      writeFile(wb, name, { compression: true });
      dispatch.success();
      options?.onSuccess?.();
    } catch (error) {
      dispatch.failure(error);
      options?.onFailure?.(error);
    }
  }, [columns, name]);

  return [state, download] as const;
};
