import { isNumber, snakeCase, toUpper } from 'lodash';

export const upperSnakeCase = (str: string) => toUpper(snakeCase(str));

export const withSuffix = (suffix: string | number) => (str: any) => `${str}${suffix}`;

export const safeJSONParse = (str: string) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return undefined;
  }
};

export const notEmptyString = (str: string | undefined | null) => str && typeof str === 'string' && str.trim() !== '';

// xlsx读入的日期与标准日期存在差距，会少43秒，需要进行日期转换
// https://github.com/SheetJS/sheetjs/issues/1565
export const XLSXDate = (str?: string) => {
  if (str && isNumber(str)) return new Date((str - 25569) * 86400000 - 28800000);
  else return str && String(str);
};
