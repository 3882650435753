import { RuleType } from '@36node-fcp/core-sdk';
import { Select, SelectProps } from 'antd';

import { useQuery } from 'src/lib/react-api';
import { fcp } from 'src/services';

export const useRules = () => {
  const [state, getRules] = useQuery(fcp.getRule);
  return [state, getRules] as const;
};

export const ruleTypeToName = {
  OVERSPEED: '超速',
  UNDERSPEED: '欠速',
  GRANDTOTAL: '累计',
  DROP: '抛弃',
  ACCURATE_DROP: '精确抛弃',
};

/**
 * 自定义违法类型
 */

/**
 * 违法类型下拉筛选组件
 * @param props [SelectProps] 组件参数
 * @returns 组件
 */

export function CustomIllegalTypeSelect(props: SelectProps) {
  return (
    <Select {...props}>
      {Object.keys(RuleType).map((item) => (
        <Select.Option key={item} value={item} item={item}>
          {ruleTypeToName[item]}
        </Select.Option>
      ))}
    </Select>
  );
}
