import { ApiError, CreateVehicleDto, Vehicle } from '@36node-fcp/core-sdk';
import { Alert, Form, Input, message, Modal } from 'antd';
import styled from 'styled-components';

import { VehiclePropertySelect } from 'src/features/illegal';
import { VehicleStoreApiErrorMap } from 'src/features/vehicle';
import { useApi } from 'src/lib/react-api';
import { fcp, passApiErrors } from 'src/services';

type EditorValues = CreateVehicleDto & {};

interface VehicleStoreEditorProps {
  vehicle?: Vehicle;
  onClose: () => void;
  onFinish?: (vehicle?: Vehicle) => void;
}

export const VehicleStoreEditor: React.FC<VehicleStoreEditorProps> = ({
  vehicle,
  onClose: handleClose,
  onFinish: handleFinish,
}) => {
  const isEdit = !!vehicle?.id;
  const onSuccess = (vehicle: Vehicle) => {
    message.success(isEdit ? '编辑成功' : '创建成功');
    handleClose();
    handleFinish?.(vehicle);
  };
  const onFailure = (err: ApiError) => {
    const ne = passApiErrors(err, VehicleStoreApiErrorMap);
    if (ne.keys) {
      ne.keys.forEach((key) => form.setFields([{ name: key, errors: [ne.message] }]));
    } else {
      message.error(ne.message);
    }
  };

  const [form] = Form.useForm<EditorValues>();
  const [updateState, update] = useApi(fcp.updateVehicle, { onSuccess, onFailure });
  const [createState, create] = useApi(fcp.createVehicle, { onSuccess, onFailure });

  const handleOk = () => form.submit();
  const handleSubmit = async (values: EditorValues) => {
    if (isEdit) {
      update({ vehicleId: vehicle.id, body: { ...values, confidence: 1 } });
    } else {
      create({ body: { ...values, confidence: 1 } });
    }
  };

  const isLoading = updateState.loading || createState.loading;
  const title = isEdit ? '编辑车辆信息' : '录入车辆信息';
  const error = updateState.error || createState.error;

  return (
    <Modal title={title} open onOk={handleOk} onCancel={handleClose} confirmLoading={isLoading} width="650px">
      <Container>
        {error && <Alert message={error.message} type="error" />}
        <Form
          name="vehicle-store-editor"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          initialValues={vehicle}
          autoComplete="off"
          onFinish={() => handleSubmit(form.getFieldsValue(true))}
          form={form}
        >
          <Form.Item label="车牌" name="plate" rules={[{ required: true, message: '请输入车牌' }]}>
            <Input placeholder="请输入车牌" />
          </Form.Item>
          <Form.Item label="使用性质" name="property" rules={[{ required: true, message: '请选择使用性质' }]}>
            <VehiclePropertySelect placeholder="请选择使用性质" />
          </Form.Item>
          <Form.Item label="号牌种类" name="plateType" rules={[{ required: true, message: '请输入号牌种类' }]}>
            <Input placeholder="请输入号牌种类" />
          </Form.Item>
          <Form.Item label="企业" name="company">
            <Input placeholder="请输入" />
          </Form.Item>
          <Form.Item label="车主姓名" name="ownerName">
            <Input placeholder="请输入" />
          </Form.Item>
          <Form.Item label="车主手机" name="ownerPhone">
            <Input placeholder="请输入" />
          </Form.Item>
        </Form>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  margin: 24px;
`;
