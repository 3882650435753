import { Subject } from '@36node-fcp/core-sdk';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Divider, Form, InputNumber, Row, Table, Typography } from 'antd';
import { FormProps } from 'antd/lib';
import { useEffect, useState } from 'react';

import { TimeIntervalCell } from 'src/components/antd/ad-time-interval';
import { DRAFT_EXPIRED_DAYS } from 'src/config';
import { GenDraftSelect, genSelectItems } from 'src/features/draft';
import { GantrySelect, useGantryList } from 'src/features/gantry';
import { toVehiclePropertyText, VehiclePropertySelect } from 'src/features/illegal';
import { IllegalTypeSelect, useIllegalTypeList } from 'src/features/illegal-type';
import { GenEntity, TimeInterval } from 'src/sdk/robot-sdk';

type Item = GenEntity & { key: string; isNew?: boolean };

type RobotGenerateTableProps = {
  value?: GenEntity[];
  subject: Subject[];
  onChange?: (records: GenEntity[]) => void;
} & FormProps;

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputNode: React.ReactNode;
  record: Item;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputNode,
  record,
  index,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `请输入${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

function randomKey() {
  return Math.random().toString(36).slice(2);
}

// 这是一个过车生成违法规则表
// 做成了一个大的受控组件，外部传入 value 和 onChange
// 内部保持相对的独立性，只负责渲染和编辑，以及新增的暂存
export const RobotGenerateTable: React.FC<RobotGenerateTableProps> = (props) => {
  const [form] = Form.useForm();
  const [data = [], setData] = useState<Item[]>([]);
  const [editingKey, setEditingKey] = useState('');
  const [{ result: illegalTypes = [] }] = useIllegalTypeList({ _limit: 1000 });
  const [{ result: gantries = [] }] = useGantryList({ _limit: 1000 });
  const [isDraft, setIsDraft] = useState(false);

  const isEditing = (record: Item) => record.key === editingKey;

  const add = () => {
    const record = {
      key: randomKey(),
      isNew: true,
      gantry: [],
      vehicleProperty: [],
      outputIllegalCode: '',
      timeInterval: [],
      genDraft: false,
      draftExpireDay: 0,
    };
    setIsDraft(false);
    const newData = [...data, record];
    setData(newData);
    setEditingKey(newData[newData.length - 1].key);
    form.setFieldsValue(record);
  };

  const edit = (record: Partial<Item>) => {
    setIsDraft(record.genDraft);
    form.setFieldsValue(record);
    setEditingKey(record.key);
  };

  const cancel = () => {
    const last = data[data.length - 1];
    if (last.key === editingKey && last.isNew) {
      setData(data.slice(0, -1));
    }
    setEditingKey('');
  };

  const save = async (key: React.Key) => {
    try {
      const row = (await form.validateFields()) as Item;

      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        row.isNew = false;
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(newData);
        setEditingKey('');
      } else {
        row.isNew = false;
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }

      props.onChange(
        newData.map((item) => ({
          gantry: item.gantry,
          vehicleProperty: item.vehicleProperty,
          outputIllegalCode: item.outputIllegalCode,
          timeInterval: item.timeInterval,
          genDraft: item.genDraft,
          draftExpireDay: item.draftExpireDay,
        }))
      );
    } catch (errInfo) {
      console.error('Validate Failed:', errInfo);
    }
  };

  const remove = (key: string) => {
    const newData = data.filter((item) => item.key !== key);
    setData(newData);
    props.onChange(
      newData.map((item) => ({
        gantry: item.gantry,
        vehicleProperty: item.vehicleProperty,
        outputIllegalCode: item.outputIllegalCode,
        timeInterval: item.timeInterval,
        genDraft: item.genDraft,
        draftExpireDay: item.draftExpireDay,
      }))
    );
  };

  useEffect(() => {
    setData(props.value?.map((record, index) => ({ ...record, key: randomKey() })));
    setEditingKey('');
  }, [props.value]);

  const handleIsDraftChange = (value: boolean) => {
    setIsDraft(value);
    if (value) {
      form.setFieldsValue({ draftExpireDay: DRAFT_EXPIRED_DAYS });
    } else {
      form.setFieldsValue({ draftExpireDay: 0 });
    }
  };

  const columns = [
    {
      title: '卡口名',
      dataIndex: 'gantry',
      width: '25%',
      editable: true,
      inputNode: <GantrySelect mode="multiple" style={{ width: '100%', maxWidth: 300 }} />,
      render: (gantryIds: string[]) =>
        gantryIds?.map((gantryId, index) => (
          <Row key={index}>{gantries.find((item) => item.id === gantryId)?.name}</Row>
        )),
    },
    {
      title: '车辆性质',
      dataIndex: 'vehicleProperty',
      width: '15%',
      editable: true,
      inputNode: <VehiclePropertySelect mode="multiple" style={{ width: '100%' }} />,
      render: (vehicleProperty: string[]) =>
        vehicleProperty?.map((prop, index) => <Row key={index}>{toVehiclePropertyText(prop)}</Row>),
    },
    {
      title: '生成违法类型',
      dataIndex: 'outputIllegalCode',
      width: '15%',
      editable: true,
      inputNode: <IllegalTypeSelect subject={props.subject} style={{ width: 150 }} />,
      render: (code: string) => illegalTypes.find((item) => item.code === code)?.name,
    },
    {
      title: '禁行时间段',
      dataIndex: 'timeInterval',
      width: '20%',
      editable: true,
      inputNode: <TimeIntervalCell />,
      render: (value: TimeInterval[]) =>
        value &&
        value.map((v, i) => (
          <Row key={i}>
            {v.start} - {v.end}
          </Row>
        )),
    },
    {
      title: '是否校验',
      dataIndex: 'genDraft',
      width: '8%',
      editable: true,
      inputNode: <GenDraftSelect onChange={handleIsDraftChange} />,
      render: (value: boolean) => genSelectItems.find((item) => item.value === value)?.text || '否',
    },
    {
      title: '校验有效天数',
      dataIndex: 'draftExpireDay',
      width: '8%',
      editable: true,
      inputNode: isDraft ? <InputNumber min={1} /> : <span> -- </span>,
      render: (value: number) => (value ? `${value}` : '--'),
    },
    {
      title: '操作',
      render: (_: any, record: Item) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link onClick={() => save(record.key)} style={{ marginRight: 8 }}>
              确定
            </Typography.Link>
            <Button type="text" onClick={cancel}>
              取消
            </Button>
          </span>
        ) : (
          <span>
            <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
              编辑
            </Typography.Link>
            <Divider type="vertical" />
            <Typography.Link disabled={editingKey !== ''} onClick={() => remove(record.key)} type="danger">
              删除
            </Typography.Link>
          </span>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputNode: col.inputNode,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form {...props} form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={false}
      />
      <Button type="dashed" block icon={<PlusCircleOutlined />} onClick={add} disabled={editingKey !== ''}>
        添加
      </Button>
    </Form>
  );
};
