import { Subject } from '@36node-fcp/core-sdk';
import { Checkbox, Col, Divider, Form, Modal, Select } from 'antd';
import { Row } from 'antd/lib';
import React from 'react';

import { useGantryDeviceList } from 'src/features/gantry-device';
import { useIllegalTypeList } from 'src/features/illegal-type';

import { useMonitorSettings } from './monitor.provider';

type GantryModalProps = {
  selectedGantryId: string;
  selectedSubject?: Subject[];
  onClose: () => void;
};
export const GantryModal: React.FC<GantryModalProps> = (props) => {
  const { onClose, selectedGantryId, selectedSubject } = props;
  const [{ result: allDevices = [] }] = useGantryDeviceList({ gantry: [selectedGantryId] });
  const [{ result: illegalTypes = [] }] = useIllegalTypeList({ _limit: 1000, subject: selectedSubject });
  const [{ motor, nonMotor }, settingsDispatch] = useMonitorSettings();
  const codes = selectedSubject.includes(Subject.VEHICLE)
    ? motor.gantryMap[selectedGantryId]?.codes || []
    : nonMotor.gantryMap[selectedGantryId]?.codes || [];
  const gantryDevices = selectedSubject.includes(Subject.VEHICLE)
    ? motor.gantryMap[selectedGantryId]?.gantryDevices || []
    : nonMotor.gantryMap[selectedGantryId]?.gantryDevices || [];
  // checkbox 两种状态
  const [indeterminate, setIndeterminate] = React.useState(false);
  const [checkAll, setCheckAll] = React.useState(false);
  const [form] = Form.useForm();

  const onCheckAllChange = (e) => {
    form.setFieldsValue({
      codes: e.target.checked ? illegalTypes.map((type) => type.code) : [],
    });
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };
  return (
    <Modal
      open={true}
      title={selectedSubject.includes(Subject.VEHICLE) ? '卡口下的机动车布控设置' : '卡口下的非机动车布控设置'}
      forceRender
      maskClosable={false}
      onCancel={onClose}
      onOk={() => {
        form
          .validateFields()
          .then((values: any) => {
            if (selectedSubject.includes(Subject.VEHICLE)) {
              settingsDispatch.setMotorGantryMapById({
                gantryId: selectedGantryId,
                codes: values.codes,
                gantryDevices: values.gantryDevices,
                allDevices: allDevices.map((device) => device.id),
              });
            } else {
              return settingsDispatch.setNonMotorGantryMapById({
                gantryId: selectedGantryId,
                codes: values.codes,
                gantryDevices: values.gantryDevices,
                allDevices: allDevices.map((device) => device.id),
              });
            }
          })
          .then(onClose)
          .catch(console.error);
      }}
    >
      <Divider />
      <Form
        form={form}
        initialValues={{ codes, gantryDevices }}
        onValuesChange={(values) => {
          const { codes: fcodes = [] } = values;
          setIndeterminate(fcodes.length > 0 && fcodes.length < illegalTypes.length);
          setCheckAll(fcodes.length === illegalTypes.length);
        }}
      >
        <Form.Item
          label="布控设备"
          name="gantryDevices"
          rules={[
            {
              required: true,
              message: '请选择',
            },
          ]}
        >
          <Checkbox.Group style={{ width: '100%' }}>
            <Row>
              {allDevices.map((device, i) => (
                <Col span={24} style={{ margin: '5px' }} key={i}>
                  <Checkbox value={device.id}>{device.name}</Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </Form.Item>
        <Form.Item
          label="布控违法"
          name="codes"
          rules={[
            {
              required: true,
              message: '请选择',
            },
          ]}
        >
          <Select
            mode="multiple"
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: '4px 0' }} />
                <Checkbox
                  onChange={onCheckAllChange}
                  indeterminate={indeterminate}
                  style={{ marginLeft: 8 }}
                  checked={checkAll}
                >
                  全选
                </Checkbox>
              </>
            )}
          >
            {illegalTypes.map((type) => (
              <Select.Option key={type.id} value={type.code}>
                {type.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
