import { GantryDeviceState, Subject } from '@36node-fcp/core-sdk';
import { EditOutlined } from '@ant-design/icons';
import { Badge, Button, Col, Divider, Drawer, Row, Space, Spin, Tag, Typography } from 'antd';
import dayjs from 'dayjs';
import { includes, isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { styled } from 'styled-components';

import { GantryAttrText, VEHICLE_ONLY } from 'src/config';
import { useGantry } from 'src/features/gantry';
import { useGantryDeviceList } from 'src/features/gantry-device';
import { NamespaceProvider } from 'src/features/users';
import { useApi } from 'src/lib/react-api';
import { fcp } from 'src/services';

import { useMonitorSettings } from './monitor.provider';

type GantryDetailDrawerProps = {
  gantryId: string;
  onClose: () => void;
  onOpenGantryModal: (subject?: Subject[]) => void;
};
const drawStyles = { body: { padding: '12px 24px 24px 24px' } };
export const GantryDetailDraw: React.FC<GantryDetailDrawerProps> = (props) => {
  const [{ result: gantry }] = useGantry(props.gantryId);
  const [{ result: gantryDevices = [] }] = useGantryDeviceList({ gantry: [props.gantryId] });
  const [{ result: tracks = [] }, countTrackRecords] = useApi(fcp.aggregateTrackRecords, {});
  const [{ result: warnings = [] }, countWarnings] = useApi(fcp.aggregateWarnings, {});
  const [{ motor, nonMotor }, settingsDispatch] = useMonitorSettings();
  const motorCodes = motor.gantryMap[props.gantryId]?.codes || [];
  const isMotorWatched = motorCodes.length > 0;
  const nonMotorCodes = nonMotor.gantryMap[props.gantryId]?.codes || [];
  const isNonMotorWatched = nonMotorCodes.length > 0;

  const reload = (gantryId: string) => {
    countTrackRecords({
      createAt_gt: dayjs().subtract(5, 'minute').toISOString(),
      createAt_lt: dayjs().toISOString(),
      gantry: [gantryId],
    });
    countWarnings({
      createAt_gt: dayjs().subtract(5, 'minute').toISOString(),
      createAt_lt: dayjs().toISOString(),
      gantry: [gantryId],
    });
  };

  useEffect(() => {
    reload(props.gantryId);
    const timer = setInterval(() => {
      reload(props.gantryId);
    }, 60 * 1000);
    return () => clearInterval(timer);
  }, [props.gantryId]);

  if (!gantry) return <Spin />;

  return (
    <Drawer
      open={true}
      placement="left"
      width={460}
      getContainer={false}
      maskClosable={false}
      mask={false}
      styles={drawStyles}
      closable={true}
      onClose={props.onClose}
      title={
        <Typography.Text
          strong
          style={{
            fontSize: 20,
          }}
        >
          {gantry.name}
        </Typography.Text>
      }
    >
      <Space direction="vertical">
        <Space>
          <Badge color="#0094FF" text="机动车布控:" />
          <Space>
            <Button type="text" onClick={() => props.onOpenGantryModal([Subject.VEHICLE])}>
              {!isMotorWatched && includes(motor.gantries, props.gantryId) && <span> 已批量布控 </span>}
              {!isMotorWatched && !includes(motor.gantries, props.gantryId) && <span> 未布控 </span>}
              {isMotorWatched && <span> 已单独布控 </span>}
              <EditOutlined />
            </Button>
            {isMotorWatched && (
              <Button
                type="text"
                danger
                onClick={() => {
                  settingsDispatch.deleteMotorGantryMapById(gantry.id);
                }}
              >
                取消布控
              </Button>
            )}
          </Space>
        </Space>
        {!VEHICLE_ONLY && (
          <Space>
            <Badge color="#0094FF" text="非机动车及行人布控:" />
            <Space>
              <Button type="text" onClick={() => props.onOpenGantryModal([Subject.PERSON, Subject.NONMOTOR])}>
                {!isNonMotorWatched && includes(nonMotor.gantries, props.gantryId) && <span> 已批量布控 </span>}
                {!isNonMotorWatched && !includes(nonMotor.gantries, props.gantryId) && <span> 未布控 </span>}
                {isNonMotorWatched && <span> 已单独布控 </span>}
                <EditOutlined />
              </Button>
              {isNonMotorWatched && (
                <Button
                  type="text"
                  danger
                  onClick={() => {
                    settingsDispatch.deleteNonMotorGantryMapById(gantry.id);
                  }}
                >
                  取消布控
                </Button>
              )}
            </Space>
          </Space>
        )}
      </Space>

      <Divider />

      <StyledRow>
        <Col span={5}>
          <Typography.Text type="secondary">所属大队:</Typography.Text>
        </Col>
        <Col span={19}>
          <NamespaceProvider ns={gantry.ns}>{(namespace) => namespace?.name || '--'}</NamespaceProvider>
        </Col>
      </StyledRow>

      <StyledRow>
        <Col span={5}>
          <Typography.Text type="secondary">所属路段:</Typography.Text>
        </Col>
        <Col span={19}>{gantry.section?.name || '--'}</Col>
      </StyledRow>

      <StyledRow>
        <Col span={5}>
          <Typography.Text type="secondary">卡口属性:</Typography.Text>
        </Col>
        <Col span={19}>{gantry.attr ? GantryAttrText[gantry?.attr] : '--'}</Col>
      </StyledRow>

      <StyledRow>
        <Col span={5}>
          <Typography.Text type="secondary">相机设备:</Typography.Text>
        </Col>
        <Col span={19}>
          {!isEmpty(gantryDevices) &&
            gantryDevices.map((device) => (
              <div style={{ marginBottom: 24 }} key={device.id}>
                {device.state === GantryDeviceState.ONLINE ? (
                  <Tag color="#1890ff">在线</Tag>
                ) : (
                  <Tag color="#000">离线</Tag>
                )}

                {device.name}
              </div>
            ))}
          {isEmpty(gantryDevices) && '--'}
        </Col>
      </StyledRow>
      <StyledRow>
        <Col span={5}>
          <Typography.Text type="secondary">活跃状态:</Typography.Text>
        </Col>
        {tracks && warnings && (
          <Col span={19}>{`最近5分钟内产生了${tracks[0] ? tracks[0].count : 0}条过车，${
            warnings[0] ? warnings[0].count : 0
          }条预警`}</Col>
        )}
      </StyledRow>
    </Drawer>
  );
};

const StyledRow = styled(Row)`
  margin-bottom: 12px;
`;
