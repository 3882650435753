import { isEmpty, pickBy } from 'lodash';
import qs from 'qs';

function filter(value: any) {
  if (typeof value === 'string' && value.trim() === '') return false;
  if (typeof value === 'object' && isEmpty(value)) return false;
  return true;
}

export function paramsSerializer(params: any): string {
  return qs.stringify(pickBy(params, filter), { indices: false, skipNulls: true });
}
