import { Draft, produce } from 'immer';

import { Action, AnyAction, PayloadAction } from './action';

/**
 * A *reducer* (also called a *reducing function*) is a function that accepts
 * an accumulation and a value and returns a new accumulation. They are used
 * to reduce a collection of values down to a single value
 *
 * Reducers are not unique to Redux—they are a fundamental concept in
 * functional programming.  Even most non-functional languages, like
 * JavaScript, have a built-in API for reducing. In JavaScript, it's
 * `Array.prototype.reduce()`.
 *
 * In Redux, the accumulated value is the state object, and the values being
 * accumulated are actions. Reducers calculate a new state given the previous
 * state and an action. They must be *pure functions*—functions that return
 * the exact same output for given inputs. They should also be free of
 * side-effects. This is what enables exciting features like hot reloading and
 * time travel.
 *
 * Reducers are the most important concept in Redux.
 *
 * *Do not put API calls into reducers.*
 *
 * @template S The type of state consumed and produced by this reducer.
 * @template A The type of actions the reducer can potentially respond to.
 */
export type Reducer<S = any, A extends Action = AnyAction> = (state: S | undefined, action: A) => S;

/**
 * Object whose values correspond to different reducer functions.
 *
 * @template A The type of actions the reducers can potentially respond to.
 */
export type ReducersMapObject<S = any, A extends Action = Action> = {
  [K in keyof S]: Reducer<S[K], A>;
};

/**
 * An *case reducer* is a reducer function for a specific action type. Case
 * reducers can be composed to full reducers using `makeReducer()`.
 *
 * Unlike a normal Redux reducer, a case reducer is never called with an
 * `undefined` state to determine the initial state. Instead, the initial
 * state is explicitly specified as an argument to `makeReducer()`.
 *
 * In addition, a case reducer can choose to mutate the passed-in `state`
 * value directly instead of returning a new state. This does not actually
 * cause the store state to be mutated directly; instead, thanks to
 * [immer](https://github.com/mweststrate/immer), the mutations are
 * translated to copy operations that result in a new state.
 */
export type CaseReducer<S = any, P = any> = (state: Draft<S>, payload: P) => S | void | Draft<S>;

/**
 * A mapping of CaseReducer.
 */
export type CaseReducers<S = any> = Record<string, CaseReducer<S>>;

/**
 * 组合 reducer
 *
 * @param f reducer 1
 * @param g reducer 2
 * @returns 一个新的 reducer
 */
export const composed =
  <S>(f: Reducer<S>, g: Reducer<S>) =>
  (state: S, action: Action): S =>
    f(g(state, action), action);

/**
 * A utility function that allows defining a reducer as a mapping from action
 * type to *case reducer* functions that handle these action types. The
 * reducer's initial state is passed as the first argument.
 *
 * The body of every case reducer is implicitly wrapped with a call to
 * `produce()` from the [immer](https://github.com/mweststrate/immer) library.
 * This means that rather than returning a new state object, you can also
 * mutate the passed-in state object directly; these mutations will then be
 * automatically and efficiently translated into copies, giving you both
 * convenience and immutability.
 * 
 * @overloadSummary
 * This overload accepts an object where the keys are string action types, and the values
 * are case reducer functions to handle those action types.
 *
 * @param iitState - `State | (() => State)`: The initial state that should be used when the reducer is called the first time. This may also be a "lazy initializer" function, which should return an initial state value when called. This will be used whenever the reducer is called with `undefined` as its state value, and is primarily useful for cases like reading initial state from `localStorage`.
 * @param caseReducers - An object mapping from action types to _case reducers_, each of which handles one specific action type.
 *
 * @example
```js
const counterReducer = makeReducer(0, {
  increment: (state, action) => state + action.payload,
  decrement: (state, action) => state - action.payload
})
 
 * Action creators that were generated using [`makeAction`](./makeAction) may be used directly as the keys here, using computed property syntax:
```js
const increment = makeAction('increment')
const decrement = makeAction('decrement')
const counterReducer = makeReducer(0, {
  [increment]: (state, action) => state + action.payload,
  [decrement.type]: (state, action) => state - action.payload
})
```
**/

export function makeReducer<S, CRs extends CaseReducers<S> = CaseReducers<S>>(
  initState: S,
  caseReducers: CRs
): Reducer<S> {
  const reducer = produce((draft: Draft<S>, action: PayloadAction<any>) => {
    const caseReducer = caseReducers[action.type];
    if (caseReducer) {
      caseReducer(draft, action.payload);
    }
  }, initState);
  return reducer;
}
