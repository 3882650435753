import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useSound from 'use-sound';

// @ts-ignore
import WarningAudio from 'src/assets/audios/warning.wav';
import { ReactComponent as NotificationMute } from 'src/assets/notification-mute.svg';
import { ReactComponent as NotificationUnmute } from 'src/assets/notification-unmute.svg';
import { ReactComponent as Car } from 'src/assets/qiche.svg';
import { ReactComponent as WalkMan } from 'src/assets/walk-man.svg';
import { VEHICLE_ONLY } from 'src/config';

import { useMonitor } from './monitor.provider';

type WarningDrawerTriggerProps = {
  onClick?: () => void;
};

export const WarningDrawerTrigger: React.FC<WarningDrawerTriggerProps> = (props) => {
  const [{ warnings, settings }] = useMonitor();
  const [mute, setMute] = useState<boolean>(true);
  const [play] = useSound(WarningAudio);

  const playWarningAudio = () => {
    if (!mute) play();
  };

  useEffect(() => {
    playWarningAudio();
  }, [warnings]);

  return (
    <Container>
      <MuteToggelButton onClick={() => setMute(!mute)}>
        {mute ? (
          <NotificationMute style={{ width: 23, height: 23, fill: '#fff' }} />
        ) : (
          <NotificationUnmute style={{ width: 23, height: 23, fill: '#fff' }} />
        )}
      </MuteToggelButton>
      <WatchStatisticsBar onClick={props.onClick}>
        <WatchStatistics>
          布控中卡口：
          <Car style={{ width: 23, height: 23, fill: '#fff', marginRight: '3px' }} />
          {settings.motor.gantries.length + Object.keys(settings.motor.gantryMap).length}
        </WatchStatistics>
        {!VEHICLE_ONLY && (
          <WatchStatistics className="stats-non-motor">
            <WalkMan style={{ width: 23, height: 23, fill: '#fff', marginRight: '3px' }} />
            {settings.nonMotor.gantries.length + Object.keys(settings.nonMotor.gantryMap).length}
          </WatchStatistics>
        )}
      </WatchStatisticsBar>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
  z-index: 900;
  display: inline-flex;
  align-items: center;
`;
const WatchStatisticsBar = styled.button`
  width: 270px;
  height: 48px;
  background-color: #ef7d22;
  color: #fff;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 400;
  padding-left: 16px;
  padding-right: 16px;
  cursor: pointer;

  .stats-non-motor {
    margin-left: 8px;
  }
`;
const WatchStatistics = styled.div`
  display: inline-flex;
  align-items: center;
  opacity: '0.65';
`;
const MuteToggelButton = styled.button`
  width: 48px;
  height: 48px;
  background-color: #1890ff;
  color: #fff;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 400;
  margin-right: 8px;
  cursor: pointer;
`;
