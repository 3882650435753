import { Input, InputRef } from 'antd';
import { get, set } from 'lodash';
import { useEffect, useRef, useState } from 'react';

import './ad-editable-cell.css';

/**
 * 可编辑的单元格，目前是备注
 */
interface EditableCellProps<T = any> {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: string | string[];
  record: T;
  handleSave: (update: any, record: T) => void;
}

export const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
  };

  const save = async () => {
    toggleEdit();
    const update = set({}, dataIndex, inputRef.current?.input?.value);
    handleSave(update, record);
  };

  const blur = async () => {
    toggleEdit();
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Input ref={inputRef} onPressEnter={save} onBlur={blur} defaultValue={get(record, dataIndex)} />
    ) : (
      <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
