import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useSession } from 'src/features/session';
import { checkPermission, findFirstAllowAccessRoute } from 'src/lib/privilege';

type ProtectedRouteType = {
  element: React.ReactNode;
  allowRedirect?: string;
  privileges: string[];
};

/**
 * 路由保护，自动重定向
 */
const ProtectedRoute = ({ element, privileges, allowRedirect }: ProtectedRouteType) => {
  const session = useSession();
  const location = useLocation();
  if (!session) return <Navigate to="/login" />;
  if (!checkPermission(session, privileges)) {
    let canRedirectRoute = null;
    if (allowRedirect) {
      canRedirectRoute = findFirstAllowAccessRoute(session, allowRedirect);
    }
    return <Navigate to={canRedirectRoute ?? '/403'} state={{ from: location }} replace />;
  }
  return <>{element}</>;
};

export default ProtectedRoute;
