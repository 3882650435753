import { RobotInfo, RobotSchemaItem, Subject } from '@36node-fcp/core-sdk';
import { BellOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { Badge, Button, Form, message, Popconfirm, Space, Spin, Tabs, TabsProps } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { useRobotInfoById } from 'src/features/robot';
import { useApi, useQuery } from 'src/lib/react-api';
import { HealthStatus } from 'src/sdk/robot-sdk';
import { fcp, getRobotClient } from 'src/services';

import RobotHealthPage from './robot-health.page';
import { getFormItem } from './robot.schema';

export interface RobotSettingProps {
  robotInfo: RobotInfo;
}

export interface RobotFormProps {
  schema: RobotSchemaItem;
  subject: Subject[];
  initialValues?: any;
  loading?: boolean;
  onFinish?: (values: any) => void;
}

const RobotForm: React.FC<RobotFormProps> = ({ schema, subject, initialValues, onFinish, loading }) => {
  const [form] = useForm();

  // 切换 robot 之后，重新设置表单的值
  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues]);

  return (
    <Form form={form} layout="vertical" autoComplete="off" onFinish={onFinish}>
      {schema.children.map((field) => {
        const valuePropName = field.type === 'switch' ? 'checked' : 'value';
        return (
          <Form.Item
            key={field.name}
            label={field.label}
            name={field.name}
            valuePropName={valuePropName}
            tooltip={field.tip}
          >
            {getFormItem(field, subject)}
          </Form.Item>
        );
      })}
      <Form.Item>
        <Button htmlType="submit" loading={loading} icon={<SaveOutlined />}>
          保存
        </Button>
      </Form.Item>
    </Form>
  );
};

const RobotSetting: React.FC<RobotSettingProps> = ({ robotInfo }) => {
  const robotClient = getRobotClient(robotInfo.baseUrl);
  const [{ loading: updateLoading }, updateSettings] = useApi(robotClient.updateSetting);
  const [{ loading: deleteLoading }, deleteRobotInfo] = useApi(fcp.deleteRobotInfo, {
    onSuccess: () => {
      message.success('删除成功');
      window.location.href = `/system/${robotInfo.type.toLocaleLowerCase()}`;
    },
    onFailure: () => message.error('删除失败'),
  });
  const [{ result: settings = [] }] = useQuery(robotClient.getSetting);
  const [{ result: healthStat }] = useQuery(robotClient.healthStat);
  const [activeKey, setActiveKey] = useState('');

  useEffect(() => {
    setActiveKey('health');
  }, [robotInfo.baseUrl]);

  if (!settings) {
    return <Spin />;
  }

  const items: TabsProps['items'] = robotInfo.schema.map((tabSchema) => {
    const handleUpdate = (values: any) => {
      updateSettings({ body: { [tabSchema.key]: values } }).then(() => message.success('保存成功'));
    };
    return {
      key: tabSchema.key,
      label: tabSchema.label,
      children: (
        <Sub>
          <RobotForm
            schema={tabSchema}
            subject={robotInfo.subject ? robotInfo.subject : [Subject.VEHICLE, Subject.PERSON, Subject.NONMOTOR]}
            initialValues={settings[tabSchema.key]}
            onFinish={handleUpdate}
            loading={updateLoading}
          />
        </Sub>
      ),
    };
  });

  const firstItem = {
    key: 'health',
    label: '健康状态',
    children: (
      <Sub>
        <RobotHealthPage healthStat={healthStat} />
      </Sub>
    ),
  };

  const allItems = [firstItem, ...items];

  const handleHealthBell = () => {
    setActiveKey('health'); // 选中健康状态页面
  };

  const handleTabChange = (key: string) => {
    setActiveKey(key);
  };

  return (
    <Container>
      <Title>
        <Left>
          {robotInfo.name}
          <Popconfirm
            title={`你确定要删除${robotInfo.name}吗？`}
            onConfirm={() => deleteRobotInfo({ robotInfoId: robotInfo.id })}
          >
            <Button type="text" loading={deleteLoading} icon={<DeleteOutlined style={{ color: 'red' }} />} />
          </Popconfirm>
        </Left>
        <Right onClick={handleHealthBell}>
          {healthStat?.status === HealthStatus.OK && (
            <Space className="ok">
              <div>正常</div>
            </Space>
          )}
          {healthStat?.status === HealthStatus.WARNING && (
            <Space className="warning">
              <div>警告</div>
              <Badge count={healthStat.warnings.length} color="#f5b223">
                <BellOutlined style={{ color: '#f5b223', fontSize: '20px' }} />
              </Badge>
            </Space>
          )}
          {healthStat?.status === HealthStatus.ERROR && (
            <Space className="error">
              <div>错误</div>
              <Badge count={healthStat.errors.length} color="#d9001b">
                <BellOutlined style={{ color: '#d9001b', fontSize: '20px' }} />
              </Badge>
            </Space>
          )}
        </Right>
      </Title>
      <Tabs activeKey={activeKey} onChange={handleTabChange} items={allItems} indicatorSize={(origin) => origin - 16} />
    </Container>
  );
};

const RobotPage: React.FC = () => {
  const { robotId } = useParams();
  const [{ result: robotInfo }] = useRobotInfoById(robotId);
  if (!robotInfo) return <Spin />;

  return <RobotSetting robotInfo={robotInfo} />;
};

export default RobotPage;

const Left = styled.div``;

const Right = styled.div`
  display: flex;
  align-items: center;
  :hover {
    cursor: pointer;
  }

  .ok {
    color: #6fbf36;
    font-size: 16px;
  }

  .warning {
    color: #f5b223;
    font-size: 16px;
  }

  .error {
    color: #d9001b;
    font-size: 16px;
  }
`;

const Container = styled.div`
  margin: 16px 24px;
`;
const Title = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: 600;
  color: rgb(0, 0, 0, 0.88);
  line-height: 1.5;
  margin-bottom: 24px;
`;
const Sub = styled.div`
  padding: 1rem 0;
  max-width: 1200px;
  overflow-x: auto;
`;
