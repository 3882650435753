import mqtt from 'mqtt/dist/mqtt';
import { useContext, useEffect } from 'react';

import { MqttContext } from './context';

export type MqttHandler<T> = (payload: T) => void;

export interface UseMqttOptions<T> {
  onMessage: MqttHandler<T>;
}

const sub2regex = (topic: string) => {
  return new RegExp(`^${topic}$`.replaceAll('+', '[^/]*').replace('/#', '(|/.*)'));
};

export const isPattern = (topic: string, pattern: string) => {
  return sub2regex(pattern).test(topic);
};

/**
 * useMqtt 会返回[messages, client]，其中 messages 是一个数组，包含了最近的 maxMessageCount 条消息
 */

export function useMqtt<T>(topicOrPattern: string, options: UseMqttOptions<T>): mqtt.MqttClient | null {
  const client = useContext(MqttContext);

  useEffect(() => {
    if (!client) {
      return;
    }

    if (!topicOrPattern) {
      return;
    }

    let subscribed = false;
    client.subscribe(topicOrPattern, (err) => {
      if (!err) {
        console.log(`订阅 ${topicOrPattern} 成功`);
        subscribed = true;
      }
    });

    const handler = (topic: string, message: Buffer) => {
      if (!isPattern(topic, topicOrPattern)) {
        // 如果不符合 topic 退出
        return;
      }

      const raw = message.toString();
      let payload: { data: T } = raw as any;
      try {
        payload = JSON.parse(raw);
      } catch (err) {
        // ignore error
      }

      options.onMessage(payload.data);
    };

    client.on('message', handler);

    return () => {
      client.removeListener('message', handler);
      if (subscribed) {
        client.unsubscribe(topicOrPattern);
      }
    };
  }, [client, topicOrPattern]);

  return client;
}
