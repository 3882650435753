import { isArray } from 'lodash';

import type { IfVoid, IsAny, IsUnknownOrNonInferrable } from '../lang/tsHelpers';

/**
 * 很多类型的灵感来自 redux
 * 但是我们简化了很多，没必要过度设计
 *
 * {
 *   type: 'LIST_CARS_REQUEST',
 *   payload?: {},
 * }
 */

/**
 * An *action* is a plain object that represents an intention to change the
 * state. Actions are the only way to get data into the store. Any data,
 * whether from UI events, network callbacks, or other sources such as
 * WebSockets needs to eventually be dispatched as actions.
 *
 * Actions must have a `type` field that indicates the type of action being
 * performed. Types can be defined as constants and imported from another
 * module. It's better to use strings for `type` than Symbols because strings
 * are serializable.
 *
 * Other than `type`, the structure of an action object is really up to you.
 * If you're interested, check out Flux Standard Action for recommendations on
 * how actions should be constructed.
 *
 * @template T the type of the action's `type` tag.
 */
export interface Action<T extends keyof any = string> {
  type: T;
}

/**
 * Defines a mapping from action types to corresponding action object shapes.
 */
export type Actions<T extends keyof any = string> = Record<T, Action>;

/**
 * An Action type which accepts any other properties.
 * This is mainly for the use of the `Reducer` type.
 * This is not part of `Action` itself to prevent types that extend `Action` from
 * having an index signature.
 */
export interface AnyAction extends Action {
  // Allows any extra properties to be defined in an action.
  [extraProps: string]: any;
}

/**
 * An action with a string type and an associated payload. This is the
 * type of action returned by `makeAction()` action creators.
 *
 * @template P The type of the action's payload.
 * @template M The type of the action's meta (optional)
 *
 * @public
 */
export type PayloadAction<P = void> = {
  payload: P;
} & Action;

/**
 * Basic type for all action creators.
 *
 * @inheritdoc {redux#ActionCreator}
 */
export interface ActionCreator<P> {
  type: string;
  toString: () => string;
  match: (action: Action) => action is PayloadAction<P>;
}

/**
 * An action creator of type `T` that takes an optional payload of type `P`.
 *
 * @inheritdoc {redux#ActionCreator}
 *
 * @public
 */
export interface ActionCreatorWithOptionalPayload<P> extends ActionCreator<P> {
  /**
   * Calling this {@link redux#ActionCreator} with an argument will
   * return a {@link PayloadAction} of type `T` with a payload of `P`.
   * Calling it without an argument will return a PayloadAction with a payload of `undefined`.
   */
  (payload?: P): PayloadAction<P>;
}

/**
 * An action creator of type `T` that takes no payload.
 *
 * @inheritdoc {redux#ActionCreator}
 *
 * @public
 */
export interface ActionCreatorWithoutPayload extends ActionCreator<undefined> {
  /**
   * Calling this {@link redux#ActionCreator} will
   * return a {@link PayloadAction} of type `T` with a payload of `undefined`
   */
  (): PayloadAction<undefined>;
}

/**
 * An action creator of type `T` that requires a payload of type P.
 *
 * @inheritdoc {redux#ActionCreator}
 *
 * @public
 */
export interface ActionCreatorWithPayload<P> extends ActionCreator<P> {
  /**
   * Calling this {@link redux#ActionCreator} with an argument will
   * return a {@link PayloadAction} of type `T` with a payload of `P`
   */
  (payload: P): PayloadAction<P>;
}

/**
 * An action creator of type `T` whose `payload` type could not be inferred. Accepts everything as `payload`.
 *
 * @inheritdoc {redux#ActionCreator}
 *
 * @public
 */
export interface ActionCreatorWithNonInferrablePayload extends ActionCreator<unknown> {
  /**
   * Calling this {@link redux#ActionCreator} with an argument will
   * return a {@link PayloadAction} of type `T` with a payload
   * of exactly the type of the argument.
   */
  <PT extends unknown>(payload: PT): PayloadAction<PT>;
}

/**
 * An action creator that produces actions with a `payload` attribute.
 *
 * @typeParam P the `payload` type
 * @typeParam T the `type` of the resulting action
 *
 * @public
 */
export type PayloadActionCreator<P = void> = IsAny<
  P,
  ActionCreatorWithPayload<any>,
  IsUnknownOrNonInferrable<
    P,
    ActionCreatorWithNonInferrablePayload,
    // else
    ActionCreatorWithPayload<P>
  >
>;

export type PayloadActionMaybeCreator<P = void> = IfVoid<
  P,
  ActionCreatorWithoutPayload,
  ActionCreatorWithOptionalPayload<P>
>;

/**
 * A utility function to create an action creator for the given action type
 * string. The action creator accepts a single argument, which will be included
 * in the action object as a field called payload. The action creator function
 * will also have its toString() overriden so that it returns the action type,
 * allowing it to be used in reducer logic that is looking for that action type.
 *
 * @param type The action type to use for created actions.
 *
 * @public
 */
export function makeAction<P = void>(type: string): PayloadActionCreator<P> {
  function actionCreator(): PayloadAction<P>;
  function actionCreator(payload: P): PayloadAction<P>;
  function actionCreator(payload?: P) {
    return { type, payload };
  }

  actionCreator.type = type;
  actionCreator.toString = () => String(type);
  actionCreator.match = (action: Action<string>): action is PayloadAction<P> => action.type === type;

  return actionCreator as any;
}

/**
 * 以下是额外的一些方法
 */
type TypeMatcher = (action: Action) => boolean;

/**
 * Is an action
 */
export const isAction = (typePattern: string | RegExp | [string] | TypeMatcher) => (action: Action) => {
  if (typeof action !== 'object') return false;
  if (typeof typePattern === 'function') return typePattern(action);
  if (typePattern instanceof RegExp) return typePattern.test(action.type);
  if (isArray(typePattern)) return typePattern.includes(action.type);
  return action.type === typePattern;
};
